import {
  ButtonGroup,
  ButtonGroupProps,
  forwardRef,
  IconButton,
  IconButtonProps,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { MouseEventHandler, ReactElement } from 'react';

type ButtonProps = {
  icon: ReactElement;
  onClick: MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
} & Omit<IconButtonProps, 'icon'>;

export type ActionButtonProps = {
  buttons: ButtonProps[];
} & ButtonGroupProps;

export const ActionButtons = forwardRef<ActionButtonProps, 'button'>(
  ({ buttons, ...groupProps }, ref) => {
    const borderColor = useColorModeValue('#D0D5DD', 'gray.500');
    const iconColor = useColorModeValue('gray.500', 'gray.300');
    const bgColor = useColorModeValue('white', 'gray.600');

    return (
      <ButtonGroup
        isAttached
        size="sm"
        variant="outline"
        rounded="base"
        boxShadow="base"
        bgColor={bgColor}
        width="fit-content"
        height="fit-content"
        {...groupProps}
        ref={ref}
      >
        {buttons.map((buttonProps, index) => (
          <Tooltip
            key={buttonProps.tooltip ?? index}
            fontSize="xs"
            label={buttonProps.tooltip}
            openDelay={500}
          >
            <IconButton
              px="7px"
              rounded="base"
              color={iconColor}
              borderColor={borderColor}
              size="sm"
              fontSize="20px"
              {...buttonProps}
              icon={buttonProps.icon}
              onClick={(event) => {
                event.stopPropagation();
                buttonProps.onClick(event);
              }}
            />
          </Tooltip>
        ))}
      </ButtonGroup>
    );
  },
);
