import { SVGProps } from 'react';

export const CollapseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75001 2.91665L6.875 2.91665L6.875 17.0833L3.75001 17.0833C3.28977 17.0833 2.91668 16.7102 2.91668 16.25L2.91668 3.74998C2.91668 3.28974 3.28977 2.91665 3.75001 2.91665ZM16.25 18.3333L8.125 18.3333L8.125 18.3333L6.875 18.3333L6.875 18.3333L3.75001 18.3333C2.59942 18.3333 1.66668 17.4006 1.66668 16.25L1.66668 3.74998C1.66668 2.59939 2.59942 1.66665 3.75001 1.66665L16.25 1.66665C17.4006 1.66665 18.3333 2.59939 18.3333 3.74998L18.3333 16.25C18.3333 17.4006 17.4006 18.3333 16.25 18.3333ZM8.125 2.91665L8.125 17.0833L16.25 17.0833C16.7102 17.0833 17.0833 16.7102 17.0833 16.25L17.0833 3.74998C17.0833 3.28974 16.7102 2.91665 16.25 2.91665L8.125 2.91665ZM13.1503 11.4331C13.3944 11.6771 13.3944 12.0729 13.1503 12.3169C12.9062 12.561 12.5105 12.561 12.2664 12.3169L10.5997 10.6503C10.3556 10.4062 10.3556 10.0105 10.5997 9.76639L12.2664 8.09973C12.5105 7.85565 12.9062 7.85565 13.1503 8.09973C13.3944 8.3438 13.3944 8.73953 13.1503 8.98361L11.9255 10.2083L13.1503 11.4331Z"
    />
  </svg>
);
