import { FormControl, FormLabel, HStack, Icon, Menu } from '@chakra-ui/react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { SortDirection, Table } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineSelect, InlineSelectOption } from '../../combobox';
import { TableMenuButton, TableMenuList } from '../shared/table-menu';

const sortDirectionIcons = {
  asc: ArrowUpIcon,
  desc: ArrowDownIcon,
};

export function ColumnSort<TData>({ table, columnId }: { table: Table<TData>; columnId: string }) {
  const column = table.getColumn(columnId);
  if (!column) {
    return null;
  }
  const sortDirection = column.getIsSorted();
  if (!sortDirection) {
    return null;
  }

  const columnName = column.columnDef.meta?.name;

  return (
    <Menu isLazy>
      <TableMenuButton
        maxW="sm"
        leftIcon={<Icon as={sortDirectionIcons[sortDirection]} />}
        onRemoveClick={() => {
          column.clearSorting();
        }}
      >
        {columnName}
      </TableMenuButton>
      <TableMenuList minW={0} w="auto" px={3} py={2}>
        <FormControl>
          <HStack spacing={1}>
            <FormLabel
              m={0}
              fontSize="xs"
              fontWeight="medium"
              color="gray.600"
              _dark={{ color: 'whiteAlpha.800' }}
            >
              {columnName}
            </FormLabel>
            <SortDirectionMenu
              sortDirection={sortDirection}
              onChange={(sortDirection) => {
                column.toggleSorting(sortDirection === 'desc');
              }}
            />
          </HStack>
        </FormControl>
      </TableMenuList>
    </Menu>
  );
}

type SortDirectionMenuProps = {
  sortDirection: SortDirection;
  onChange: (SortDirection: SortDirection) => void;
};

function SortDirectionMenu({ sortDirection, onChange }: SortDirectionMenuProps) {
  const { t } = useTranslation('ui');

  const options = useMemo(
    (): InlineSelectOption<SortDirection>[] => [
      { label: t('table.sort.direction.asc'), value: 'asc' },
      { label: t('table.sort.direction.desc'), value: 'desc' },
    ],
    [t],
  );

  return <InlineSelect options={options} value={sortDirection} onChange={onChange} />;
}
