import { vendorDrawerSchema } from '@main/shared/utils';
import { Navigate, Route } from '@tanstack/react-router';

import { VendorLayout } from '../features/layout/vendor';
import { useVendorSearchParams } from '../features/layout/vendor-menu';
import { rootRoute } from './__root';

export const vendorLayout = new Route({
  getParentRoute: () => rootRoute,
  path: '$vendorToken',
  component: VendorLayout,
  validateSearch: vendorDrawerSchema,
});

export const vendorMain = new Route({
  getParentRoute: () => vendorLayout,
  path: '/',
  component: () => (
    // eslint-disable-next-line react-hooks/rules-of-hooks
    <Navigate to="/$vendorToken/questionnaires" params={useVendorSearchParams()} />
  ),
});
