import { TabId } from './types';
import { TabItem } from './use-tabs';

export type TabItems<T extends TabId> = Record<T, Omit<TabItem, 'id'>>;

export function getVisibleTabs<T extends TabId>(tabs: TabItems<T>): TabItem<T>[] {
  return (Object.keys(tabs) as Array<keyof TabItems<T>>).map(
    (id) => tabs[id] && ({ ...tabs[id], id } as TabItem<T>),
  );
}

export function getSubstring(text: string) {
  if (text.length > 12) {
    return text.substring(0, 9) + '.';
  }

  return text;
}
