import { Icon, Menu } from '@chakra-ui/react';
import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { Column, Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { TableMenuButton, TableMenuColumnSelect, TableMenuList } from '../shared/table-menu';

export function AddSort<TData>({ table }: { table: Table<TData> }) {
  const { t } = useTranslation('ui');

  return (
    <Menu isLazy>
      <TableMenuButton color="gray.500" leftIcon={<Icon as={ArrowsUpDownIcon} />}>
        {t('table.sort.add')}
      </TableMenuButton>
      <TableMenuList overflow="hidden">
        <TableMenuColumnSelect
          table={table}
          placeholder={t('table.sort.addPlaceholder')}
          shouldDisplayColumn={isSortableColumn}
          onSelect={(column) => {
            column.toggleSorting();
          }}
        />
      </TableMenuList>
    </Menu>
  );
}

function isSortableColumn<TData>(column: Column<TData, unknown>): boolean {
  return column.getCanSort() && !column.getIsSorted();
}
