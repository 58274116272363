import { ThemeTypings } from '@chakra-ui/react';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import { ParseKeys } from 'i18next';

type QuestionnaireStatuses = {
  [key in Vendor_Questionnaire_Statuses_Enum]: {
    value: ParseKeys;
    colorScheme: ThemeTypings['colorSchemes'];
  };
};

export const QUESTIONNAIRE_STATUSES: QuestionnaireStatuses = {
  created: { value: 'vendors.questionnaires.status.created', colorScheme: 'yellow' },
  pending: { value: 'vendors.questionnaires.status.pending', colorScheme: 'gray' },
  submitted: { value: 'vendors.questionnaires.status.submitted', colorScheme: 'green' },
  expiring: { value: 'vendors.questionnaires.status.expiring', colorScheme: 'yellow' },
  overdue: { value: 'vendors.questionnaires.status.overdue', colorScheme: 'red' },
  revoked: { value: 'vendors.questionnaires.status.revoked', colorScheme: 'red' },
};
