import {
  DynamicFormComponentRegistry,
  DynamicFormFieldConfig,
  GenericDynamicFormComponentRegistry,
} from '@main/dynamic-form';

import { QuestionnnaireFormFieldAnswer } from './form-types';

declare module '@main/dynamic-form' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface QuestionnaireFormFieldConfigExtra extends FormFieldConfigWithCondition {}
}

export interface FormFieldConfigWithCondition<T extends FormFieldCondition = FormFieldCondition> {
  condition?: T;
}

export type FormFieldCondition = FormFieldConditionGroup | FormFieldConditionParent;

export type PickFormFieldCondition<T extends FormFieldCondition, Kind> = T extends { kind: Kind }
  ? T
  : never;

export interface FormFieldConditionGroup<T extends FormFieldCondition = FormFieldCondition> {
  kind: 'group';
  logic: 'and' | 'or';
  conditions: T[];
}

export interface FormFieldConditionParent {
  kind: 'parent';
  fieldName: string;
  values: unknown[];
  onMatch: 'show' | 'hide';
  byDefault: 'show' | 'hide';
}

export type QuestionConditionChecker = (
  answers: readonly QuestionnnaireFormFieldAnswer[],
  answer?: QuestionnnaireFormFieldAnswer,
) => boolean;

export function getQuestionConditionsFilter(
  config: DynamicFormFieldConfig<
    DynamicFormComponentRegistry<GenericDynamicFormComponentRegistry, FormFieldConfigWithCondition>
  >,
): QuestionConditionChecker {
  return getConditionChecker(config.condition);
}

function getConditionChecker(condition: FormFieldCondition | undefined): QuestionConditionChecker {
  if (!condition) {
    return () => false;
  }

  const checker = conditionCheckers[condition.kind] as ConditionCheckerFactory | undefined;

  if (!checker) {
    console.warn(`Not implemented form question condition kind: ${condition.kind}`);
    return () => false;
  }

  return checker(condition);
}

type ConditionCheckerFactory<T extends FormFieldCondition = FormFieldCondition> = (
  condition: T,
) => QuestionConditionChecker;
type ConditionCheckers = {
  [K in FormFieldCondition['kind']]: ConditionCheckerFactory<
    PickFormFieldCondition<FormFieldCondition, K>
  >;
};

const conditionCheckers: Partial<ConditionCheckers> = {
  group(condition) {
    const checkers = condition.conditions.map((condition) => getConditionChecker(condition));

    if (condition.logic === 'and') {
      return (answers, answer) => checkers.some((checker) => checker(answers, answer));
    } else {
      return (answers, answer) => checkers.every((checker) => checker(answers, answer));
    }
  },
  parent(condition) {
    return (answers) => {
      const parentAnswer = answers.find((a) => a.field_name === condition.fieldName);

      if (!parentAnswer) {
        return condition.byDefault === 'hide';
      }

      const matchChecker = Array.isArray(parentAnswer.value)
        ? (value: unknown) => (parentAnswer.value as unknown[]).includes(value)
        : (value: unknown) => parentAnswer.value === value;
      const isMatch = condition.values.some(matchChecker);

      return condition.onMatch === 'show' ? !isMatch : isMatch;
    };
  },
};
