import { Button, ButtonGroup, Flex, Tag } from '@chakra-ui/react';
import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

export function shouldDisplayPagination<TData>(table: Table<TData>): boolean {
  return table.getRowCount() > table.getState().pagination.pageSize;
}

export function TablePagination<TData>({ table }: { table: Table<TData> }) {
  const { t } = useTranslation('ui');

  const { totalRows, startingRow, endingRow } = getPaginationInfo(table);

  return (
    <Flex px={6} py={3} align="center" justify="space-between">
      <Tag colorScheme="purple">
        {t('table.pagination.info', { total: totalRows, start: startingRow, end: endingRow })}
      </Tag>

      <ButtonGroup variant="outline" isAttached>
        <Button
          fontSize="sm"
          fontWeight="medium"
          isDisabled={!table.getCanPreviousPage()}
          onClick={table.previousPage}
        >
          {t('table.pagination.previous')}
        </Button>

        <Button
          fontSize="sm"
          fontWeight="medium"
          isDisabled={!table.getCanNextPage()}
          onClick={table.nextPage}
        >
          {t('table.pagination.next')}
        </Button>
      </ButtonGroup>
    </Flex>
  );
}

function getPaginationInfo<TData>(table: Table<TData>) {
  const { pageSize, pageIndex } = table.getState().pagination;

  const totalRows = table.getRowCount();
  const startingRow = pageSize * pageIndex + 1;
  const endingRow = Math.min(pageSize * (pageIndex + 1), totalRows);

  return {
    totalRows,
    startingRow,
    endingRow,
  };
}
