import { FilterMode } from '@main/shared/url-helpers';
import { Column, Row, Table } from '@tanstack/react-table';
import { ComponentType } from 'react';
import { z } from 'zod';

export type FilterColumnMeta<TData, TValue> = {
  filterSchema: z.ZodTypeAny;
  DisplayFilter: ComponentType<{ table: Table<TData>; column: Column<TData, TValue> }>;
  EditFilter: ComponentType<{ table: Table<TData>; column: Column<TData, TValue> }>;
};

export type FilterHelper<TValue, TFilterValue> = ReturnType<
  typeof createFilterHelper<TValue, TFilterValue>
>;

/**
 * In `@tanstack/react-table` filter values are untyped.
 * This helper tries to assist in minimizing the usage of `any` when defining column filters.
 */
export function createFilterHelper<TValue, TFilterValue>({
  filterSchema,
  filterFn,
}: {
  filterSchema: z.ZodType<TFilterValue>;
  filterFn: (value: TValue, filterValue: TFilterValue | null, filterMode: FilterMode) => boolean;
}) {
  return {
    filterSchema: FilterValueBox.schema(filterSchema),
    filterFn: <TData>(row: Row<TData>, columnId: string, filterValue: TFilterValue | null) => {
      const { value, mode } = FilterValueBox.from(filterValue);
      return filterFn(row.getValue<TValue>(columnId), value, mode);
    },
    getFilterValue: <TData>(column: Column<TData, TValue>) => {
      const { value } = FilterValueBox.from(column.getFilterValue() as TFilterValue);
      return value;
    },
    setFilterValue: <TData>(column: Column<TData, TValue>, value: TFilterValue | null) => {
      const box = FilterValueBox.from(column.getFilterValue() as TFilterValue);
      column.setFilterValue(new FilterValueBox(value, box.mode));
    },
    setFilterMode: <TData>(column: Column<TData, TValue>, mode: FilterMode) => {
      const box = FilterValueBox.from(column.getFilterValue() as TFilterValue);
      column.setFilterValue(new FilterValueBox(box.value, mode));
    },
    getFilterMode: <TData>(column: Column<TData, TValue>) => {
      const { mode } = FilterValueBox.from(column.getFilterValue() as TFilterValue);
      return mode;
    },
  };
}

export class FilterValueBox<TFilterValue> {
  static schema<TFilterValue>(filterSchema: z.ZodType<TFilterValue>) {
    return z.object({
      value: filterSchema.nullable(),
      mode: z.nativeEnum(FilterMode).nullable(),
    });
  }

  static from<T>(data?: T | FilterValueBox<T> | null): FilterValueBox<T> {
    if (data instanceof FilterValueBox) {
      return data;
    }

    if (this.isSameShape(data)) {
      return new FilterValueBox(data.value, data.mode);
    }

    return new FilterValueBox(data);
  }

  static isSameShape<T>(data?: T | FilterValueBox<T> | null): data is FilterValueBox<T> {
    return typeof data === 'object' && data !== null && 'value' in data && 'mode' in data;
  }

  constructor(
    public value: TFilterValue | null = null,
    public mode = FilterMode.Includes,
  ) {}
}
