import type { ActionMeta, GroupBase, MultiValue } from 'chakra-react-select';

import type { ComboboxProps } from './combobox';

/**
 * _Single-select_ `Combobox` component renders values as plain text.
 * This hook allows rendering _single-select_ values the same way as they would have been rendered
 * in _multi-select_ `Combobox`, at the same preserving the types of _single-select_.
 */
export function withMultiCombobox<
  TOption extends object,
  IsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>({
  isMulti,
  onChange,
  ...props
}: ComboboxProps<TOption, IsMulti, TGroup>): ComboboxProps<TOption, true, TGroup> {
  return {
    blurInputOnSelect: !isMulti,
    closeMenuOnSelect: !isMulti,
    ...props,
    isMulti: true,
    onChange: (value: MultiValue<TOption>, meta: ActionMeta<TOption>) => {
      if (isMulti) {
        // ts can't narrow down the type by itself
        const _onChange = onChange as ComboboxProps<TOption, true, GroupBase<TOption>>['onChange'];
        _onChange?.(value, meta);
      } else {
        // ts can't narrow down the type by itself
        const _onChange = onChange as ComboboxProps<TOption, false, GroupBase<TOption>>['onChange'];
        const lastValue = value[value.length - 1] ?? null;
        _onChange?.(lastValue, meta);
      }
    },
  };
}
