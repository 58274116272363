import { EditableTextareaField, OptionsField } from '../field';
import { DynamicFormComponentRegistry } from '../field-registry';

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
export interface QuestionnaireFormFieldConfigExtra {}

export type QuestionnaireFormComponentRegistry = ReturnType<typeof createComponentRegistry>;

export enum QuestionnaireComponentKind {
  Text = 'editable-textarea',
  Options = 'options',
}

let componentRegistry: QuestionnaireFormComponentRegistry | undefined;

/**
 * Lazily creates and returns the component registry for the questionnaire form.
 */
export function useQuestionnaireComponentRegistry() {
  if (componentRegistry === undefined) {
    componentRegistry = createComponentRegistry();
  }

  return { componentRegistry };
}

export function createComponentRegistry() {
  const fields = {
    [QuestionnaireComponentKind.Text]: EditableTextareaField,
    [QuestionnaireComponentKind.Options]: OptionsField,
  };

  return new DynamicFormComponentRegistry<typeof fields, QuestionnaireFormFieldConfigExtra>(fields);
}
