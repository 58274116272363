import { initDatadogLogger } from '@main/shared/utils';

initDatadogLogger({
  appEnv: import.meta.env.VITE_APP_ENV,
  version: __COMMIT_HASH__,
  clientToken: import.meta.env['VITE_DATADOG_APP_CLIENT_TOKEN'],
  site: import.meta.env['VITE_DATADOG_SITE'],
  isProdBuild: import.meta.env.PROD,
  service: 'vendor-portal',
});
