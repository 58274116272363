import { createStandaloneToast, ToastId, useToast, UseToastOptions } from '@chakra-ui/react';
import { ReactNode, useCallback, useRef } from 'react';

import { theme } from './theme';

export const { toast, ToastContainer } = createStandaloneToast({ theme });

export const errorToast = (title: ReactNode) =>
  toast({
    title,
    status: 'error',
    duration: 5000,
    isClosable: true,
  });

export const successToast = (title: ReactNode, description?: ReactNode) =>
  toast({
    title,
    description,
    status: 'success',
    duration: 5000,
    isClosable: true,
  });

export function useMultiStateToast(globalOptions?: Omit<UseToastOptions, 'id'>) {
  const localToast = useToast();
  const toastIdRef = useRef<ToastId>('');

  const loading = useCallback(
    (options: Omit<UseToastOptions, 'id'>) =>
      (toastIdRef.current = localToast({
        status: 'loading',
        duration: null,
        ...globalOptions,
        ...options,
      })),
    [globalOptions, localToast],
  );

  const success = useCallback(
    (options: Omit<UseToastOptions, 'id'>) =>
      localToast.update(toastIdRef.current, {
        status: 'success',
        duration: 10000,
        ...globalOptions,
        ...options,
      }),
    [globalOptions, localToast],
  );

  const error = useCallback(
    (options: Omit<UseToastOptions, 'id'>) =>
      localToast.update(toastIdRef.current, {
        status: 'error',
        duration: 5000,
        ...globalOptions,
        ...options,
      }),
    [globalOptions, localToast],
  );

  const close = useCallback(() => localToast.close(toastIdRef.current), [localToast]);

  const update = useCallback(
    (options: Omit<UseToastOptions, 'id'>) => localToast.update(toastIdRef.current, options),
    [localToast],
  );

  return { loading, success, error, close, update };
}
