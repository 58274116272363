import { Props, Select } from 'chakra-react-select';

export type InlineSelectProps<T> = Omit<
  Props<InlineSelectOption<T>, false>,
  'options' | 'value' | 'onChange'
> & {
  options: InlineSelectOption<T>[];
  value: T;
  onChange: (value: T) => void;
};

export type InlineSelectOption<T> = {
  value: T;
  label: string;
};

export function InlineSelect<T>({ options, value, onChange, ...props }: InlineSelectProps<T>) {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Select
      isSearchable={false}
      {...props}
      useBasicStyles
      variant="filled"
      size="sm"
      chakraStyles={{
        menu: (base) => ({
          ...base,
          minW: '185px',
          w: 'fit-content',
          zIndex: 10,
        }),
        menuList: (base) => ({
          ...base,
          borderRadius: 'xl',
        }),
        control: (base) => ({
          ...base,
          cursor: 'pointer',
          w: 'fit-content',
          minH: 6,
          borderRadius: 'md',
          color: 'gray.600',
          fontSize: 'xs',
          fontWeight: 'semibold',
          _focusVisible: {
            // bad upstream types
            ...(base as { _focusVisible: object })._focusVisible,
            bg: undefined,
          },
          _dark: {
            color: 'whiteAlpha.800',
          },
        }),
        valueContainer: (base) => ({
          ...base,
          pl: 1,
          pr: 0,
          py: 0,
        }),
        dropdownIndicator: (base, { selectProps }) => ({
          ...base,
          m: 0,
          '> svg': {
            transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
          },
        }),
      }}
      options={options}
      value={selectedOption}
      onChange={(option) => {
        if (option) {
          onChange(option.value);
        }
      }}
    />
  );
}
