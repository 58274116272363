import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { useNhostClient } from '@nhost/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { errorToast } from '../toast';

export function useDownloadStorageFile() {
  const { t } = useTranslation('ui');
  const nhost = useNhostClient();

  return useCallback(
    async (fileId: string) => {
      const { error, presignedUrl } = await nhost.storage.getPresignedUrl({ fileId });

      if (error) {
        errorToast(t('errorMessages.fileDownloadFailed'));
        datadogLogs.logger.error('Failed downloading file', { fileId }, toError(error));
        return;
      }

      window.open(presignedUrl.url, '_blank');
    },
    [t, nhost],
  );
}
