import {
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Switch,
  SwitchProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { NavigateOptions, useRouter } from '@tanstack/react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface HeaderProps {
  logo: React.ReactNode;
  onSidebarToggle: () => void;
  menuContent?: React.ReactNode;
  menuMainItem?: React.ReactNode;
  menuItems?: HeaderMenuItem[];
  notificationCenter?: React.JSXElementConstructor<object>;
  taskSidebar?: React.JSXElementConstructor<object>;
}

export interface HeaderMenuItem extends NavigateOptions, React.ComponentProps<'button'> {
  label: string;
  icon: React.ElementType;
  showSwitch?: boolean;
  isSwitchChecked?: SwitchProps['isChecked'];
  onSwitchChange?: () => void;
}

export const Header = ({
  logo,
  onSidebarToggle,
  menuContent,
  menuMainItem,
  menuItems,
  notificationCenter: NotificationCenter,
  taskSidebar: TaskSidebar,
}: HeaderProps) => {
  const { t } = useTranslation('ui');
  const { navigate } = useRouter();
  const menuTextColor = useColorModeValue('gray.600', 'gray.300');
  const shadowValue = useColorModeValue('lg', 'dark-lg');
  const bgColorMobile = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hasMenuItems = !!menuMainItem || !!menuItems?.length;
  const showMenu = hasMenuItems || !!menuContent;

  return (
    <Flex
      px={{ base: 4, md: 8 }}
      height="16"
      alignItems={{ base: 'center', md: 'flex-end' }}
      position={{ base: 'relative', md: 'absolute' }}
      zIndex={39}
      bg={{ base: bgColorMobile, md: 'transparent' }}
      borderBottomWidth={{ base: '1px', md: '0' }}
      borderBottomColor={borderColor}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      top={0}
      right={0}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onSidebarToggle}
        variant="ghost"
        aria-label={t('layout.openMenu')}
        icon={<Bars3Icon width="28" />}
      />

      <Box textAlign="center" maxW={32} display={{ base: 'flex', md: 'none' }}>
        {logo}
      </Box>

      <Flex gap={1}>
        {TaskSidebar && <TaskSidebar />}
        {NotificationCenter && <NotificationCenter />}
        {showMenu && (
          <Menu computePositionOnMount>
            <MenuButton
              ml={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
              data-testid="header-account-button"
            >
              {menuContent}
            </MenuButton>
            {hasMenuItems && (
              <MenuList alignItems="center" shadow={shadowValue}>
                {menuMainItem}
                {!!menuMainItem && !!menuItems?.length && <MenuDivider />}
                {menuItems?.map((menu) => {
                  return (
                    <MenuItem
                      key={menu.label}
                      onClick={
                        menu.onClick
                          ? menu.onClick
                          : menu.to
                            ? () =>
                                void navigate({
                                  ...menu,
                                  search: (old) => old,
                                  params: (old) => old,
                                })
                            : undefined
                      }
                      height="10"
                      closeOnSelect={!menu.showSwitch}
                      fontSize="sm"
                      color={menuTextColor}
                    >
                      <Flex justifyContent="space-between" grow={1}>
                        <Flex pointerEvents="none" align="center">
                          <Icon
                            mr={2}
                            w="18px"
                            h="18px"
                            display="flex"
                            alignItems="center"
                            color="gray.500"
                            as={menu.icon}
                          />
                          {menu.label}
                        </Flex>
                        {menu.showSwitch && (
                          <Switch
                            onChange={() => {
                              menu.onSwitchChange?.();
                            }}
                            isChecked={menu.isSwitchChecked}
                          />
                        )}
                      </Flex>
                    </MenuItem>
                  );
                })}
              </MenuList>
            )}
          </Menu>
        )}
      </Flex>
    </Flex>
  );
};
