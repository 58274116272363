import { VendorDrawerType } from '@main/shared/utils';
import { DrawerContentMap, useDrawerContent } from '@main/ui';
import { RouteApi } from '@tanstack/react-router';

import { useVendorAppSelector } from '../../hooks/redux-toolkit-hooks';
import { VendorQuestionnaireDrawer } from '../vendor-questionnaires/drawer';
import { getVendorUserState } from '../vendor-questionnaires/slice';

const drawers: DrawerContentMap<NonNullable<VendorDrawerType['drawerEntity']>> = {
  'vendor-questionnaire': ({ drawerEntityId }) => ({
    content: <VendorQuestionnaireDrawer questionnaireId={drawerEntityId} />,
  }),
};

const vendorRouteApi = new RouteApi({ id: '/$vendorToken' });

export function useVendorDrawer() {
  const vendorUserState = useVendorAppSelector(getVendorUserState);
  const { drawerContent } = useDrawerContent({
    useSearch: () =>
      vendorRouteApi.useSearch({
        select: ({ drawerEntity, drawerEntityId }) => ({ drawerEntity, drawerEntityId }),
      }),
    drawers,
    isReady: vendorUserState === 'ready',
  });

  return drawerContent;
}
