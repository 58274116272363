import { Group } from '@visx/group';
import { LegendOrdinal } from '@visx/legend';
import { withParentSize } from '@visx/responsive';
import {
  WithParentSizeProps,
  WithParentSizeProvidedProps,
} from '@visx/responsive/lib/enhancers/withParentSize';
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { LineRadial } from '@visx/shape';
import { CSSProperties, useMemo } from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Array<T> {
    fill<R>(value: R): R[];
  }
}

export interface RadarDatum {
  label: string;
  color: string;
  dimensions: number[];
}

export interface RadarChartProps {
  data: RadarDatum[];
  levels: number;
  dimentions: RadarDimention[];
  yRange?: [number, number];
  margin?: { top?: number; right?: number; bottom?: number; left?: number };
  background?: string;
  radialLineColor?: string;
  labelOffset?: number;
  labelStyles?: CSSProperties;
  legendMargin?: number;
  legendHeight?: number;
  legendStyles?: CSSProperties;
}

export interface RadarDimention {
  label: string;
}

export const RadarChart = withParentSize(RadarChartComponent) as unknown as (
  props: RadarChartProps,
) => JSX.Element;

export function RadarChartComponent(
  props: RadarChartProps & WithParentSizeProvidedProps & WithParentSizeProps,
) {
  const legendMargin = props.legendMargin ?? 40;
  const legendHeight = props.legendHeight ?? 18;
  const width = props.parentWidth ?? 0;
  const height = (props.parentHeight ?? 0) - legendMargin - legendHeight;
  const {
    top: marginTop = 0,
    right: marginRight = 0,
    bottom: marginBottom = 50,
    left: marginLeft = 0,
  } = props.margin ?? {};
  const background = props.background ?? 'transparent';
  const radialLineColor = props.radialLineColor ?? '#F2F4F7';

  const xMax = useMemo(() => width - marginLeft - marginRight, [width, marginLeft, marginRight]);
  const yMax = useMemo(() => height - marginTop - marginBottom, [height, marginTop, marginBottom]);
  const radius = useMemo(() => Math.min(xMax, yMax) / 2, [xMax, yMax]);
  const dimentionsCount = useMemo(() => props.dimentions.length, [props.dimentions]);
  const labelOffset = useMemo(() => props.labelOffset ?? 15, [props.labelOffset]);

  const webs = useMemo(() => genAngles(dimentionsCount), [dimentionsCount]);

  const radialLines = useMemo(
    () =>
      new Array(props.levels)
        .fill(null)
        .map((_, i) => (
          <LineRadial
            key={`radial-line-${i}`}
            data={webs}
            angle={radialScale}
            radius={((i + 1) * radius) / props.levels}
            fill="none"
            stroke={radialLineColor}
            strokeWidth={1}
            strokeLinecap="round"
          />
        )),
    [props.levels, webs, radius, radialLineColor],
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, radius],
        domain: props.yRange ?? [0, Math.max(...props.data.flatMap((d) => d.dimensions))],
      }),
    [radius, props.yRange, props.data],
  );
  const polygonData = useMemo(
    () =>
      props.data.map((datum) => ({
        ...datum,
        pointString: genPolygonPoints(datum.dimensions, yScale),
      })),
    [props.data, yScale],
  );

  const polygons = useMemo(
    () =>
      polygonData.map((polygon) => (
        <polygon
          key={`polygon-${polygon.label}`}
          points={polygon.pointString}
          fill={polygon.color}
          fillOpacity={0.15}
          stroke={polygon.color}
          strokeWidth={2}
          strokeLinejoin="round"
          strokeLinecap="round"
        />
      )),
    [polygonData],
  );

  const labelStyles: CSSProperties = useMemo(
    () => ({
      fill: '#667085',
      fontFamily: 'Inter, arial, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      ...props.labelStyles,
    }),
    [props.labelStyles],
  );
  const labelPoints = useMemo(
    () => genPoints(dimentionsCount, radius + labelOffset),
    [dimentionsCount, labelOffset, radius],
  );

  const labels = useMemo(
    () =>
      labelPoints.map((point, i) => (
        <text
          key={`label-${props.dimentions[i]?.label ?? i}`}
          x={point.x}
          y={point.y}
          fontSize={10}
          textAnchor={
            isAround(point.x, 0) ? 'middle' : point.x > 0 ? 'start' : point.x < 0 ? 'end' : 'middle'
          }
          dominantBaseline="middle"
          style={labelStyles}
        >
          {props.dimentions[i]?.label}
        </text>
      )),
    [labelStyles, labelPoints, props.dimentions],
  );

  const legendColorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: props.data.map((d) => d.label),
        range: props.data.map((d) => d.color),
      }),
    [props.data],
  );

  const legendStyles: CSSProperties = useMemo(
    () => ({
      width: '100%',
      color: '#718096',
      fontFamily: 'Inter, arial, sans-serif',
      fontSize: '12px',
      fontWeight: 400,
      marginBottom: legendMargin,
      height: legendHeight,
      ...props.legendStyles,
    }),
    [legendHeight, legendMargin, props.legendStyles],
  );

  const legend = useMemo(
    () => (
      <div style={legendStyles}>
        <LegendOrdinal
          scale={legendColorScale}
          direction="row"
          shapeWidth="10px"
          shapeHeight="10px"
          shapeMargin="0 4px 0 0"
          labelMargin="0 16px 0 0"
        />
      </div>
    ),
    [legendColorScale, legendStyles],
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {legend}
      <svg width={width} height={height}>
        <rect fill={background} width={width} height={height} rx={14} />
        <Group top={height / 2 - marginTop} left={width / 2}>
          {radialLines}
          {polygons}
          {labels}
        </Group>
      </svg>
    </div>
  );
}

// Rotate the radar chart by 180 degrees facing up
const angleOffset = Math.PI;

const radialScale = scaleLinear<number>({
  range: [0, Math.PI * 2],
  domain: [360, 0],
});

function genAngles(length: number) {
  if (length === 0) {
    return [];
  }

  const step = 360 / length;

  return new Array(length + 1).fill(null).map((_, i) => i * step);
}

function genPolygonPoints(data: number[], scale: (n: number) => number) {
  if (data.length === 0) {
    return '';
  }

  const step = (Math.PI * 2) / data.length;

  const points = data.map(
    (d, i) =>
      `${scale(d) * Math.sin(i * step + angleOffset)},${
        scale(d) * Math.cos(i * step + angleOffset)
      }`,
  );

  return points.join(' ');
}

function genPoints(length: number, radius: number) {
  if (length === 0) {
    return [];
  }

  const step = (Math.PI * 2) / length;

  return new Array(length).fill(null).map((_, i) => ({
    x: radius * Math.sin(i * step + angleOffset),
    y: radius * Math.cos(i * step + angleOffset),
  }));
}

function isAround(value: number, target: number, threshold = 0.01) {
  return Math.abs(value - target) < threshold;
}
