/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetQuestionnaireQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type GetQuestionnaireQuery = {
  __typename?: 'query_root';
  questionnaires_by_pk?: {
    __typename?: 'questionnaires';
    id: string;
    name?: string;
    internal_id?: string;
    description?: string;
    created_at: string;
    organization_id: string;
    form_id?: string;
    owner_id?: string;
    file?: { __typename?: 'files'; id: string; name?: string; size?: number };
    form?: { __typename?: 'forms'; id: string; config?: ClientTypes.FormConfig };
    questionnaire_uploads: Array<{
      __typename?: 'questionnaire_uploads';
      id: string;
      file: { __typename?: 'files'; id: string; name?: string; size?: number };
    }>;
  };
};

export type QuestionnaireUploadFragment = {
  __typename?: 'questionnaire_uploads';
  id: string;
  file: { __typename?: 'files'; id: string; name?: string; size?: number };
};

export const QuestionnaireUploadFragmentDoc = `
    fragment QuestionnaireUpload on questionnaire_uploads {
  id
  file {
    id
    name
    size
  }
}
    `;
export const GetQuestionnaireDocument = `
    query GetQuestionnaire($id: uuid!) {
  questionnaires_by_pk(id: $id) {
    id
    name
    internal_id
    description
    created_at
    organization_id
    file {
      id
      name
      size
    }
    form_id
    form {
      id
      config
    }
    owner_id
    questionnaire_uploads {
      ...QuestionnaireUpload
    }
  }
}
    ${QuestionnaireUploadFragmentDoc}` as string &
  TypedDocumentNode<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetQuestionnaire: build.query<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>({
      query: (variables) => ({ document: GetQuestionnaireDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetQuestionnaireQuery, useLazyGetQuestionnaireQuery } = injectedRtkApi;
