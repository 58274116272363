import { SVGProps } from 'react';

export const TagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
  >
    <path
      d="M6 4.5C6 4.89782 5.84196 5.27936 5.56066 5.56066C5.27936 5.84196 4.89782 6 4.5 6C4.10218 6 3.72064 5.84196 3.43934 5.56066C3.15804 5.27936 3 4.89782 3 4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3C4.89782 3 5.27936 3.15804 5.56066 3.43934C5.84196 3.72064 6 4.10218 6 4.5ZM5 4.5C5 4.36739 4.94732 4.24021 4.85355 4.14645C4.75979 4.05268 4.63261 4 4.5 4C4.36739 4 4.24021 4.05268 4.14645 4.14645C4.05268 4.24021 4 4.36739 4 4.5C4 4.63261 4.05268 4.75979 4.14645 4.85355C4.24021 4.94732 4.36739 5 4.5 5C4.63261 5 4.75979 4.94732 4.85355 4.85355C4.94732 4.75979 5 4.63261 5 4.5Z"
      fillRule="evenodd"
    />
    <path
      d="M2 1H6.586C6.8512 1.00006 7.10551 1.10545 7.293 1.293L14.293 8.293C14.4805 8.48053 14.5858 8.73484 14.5858 9C14.5858 9.26517 14.4805 9.51947 14.293 9.707L9.707 14.293C9.51947 14.4805 9.26517 14.5858 9 14.5858C8.73484 14.5858 8.48053 14.4805 8.293 14.293L1.293 7.293C1.10545 7.10551 1.00006 6.8512 1 6.586V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1ZM2 6.586L9 13.586L13.586 9L6.586 2H2V6.586Z"
      fillRule="evenodd"
    />
  </svg>
);

export const DoubleTagsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
  >
    <path
      d="M3 2V6.586L10 13.586L14.586 9L7.586 2H3ZM2 2C2 1.73478 2.10536 1.48043 2.29289 1.29289C2.48043 1.10536 2.73478 1 3 1H7.586C7.8512 1.00006 8.10551 1.10545 8.293 1.293L15.293 8.293C15.4805 8.48053 15.5858 8.73484 15.5858 9C15.5858 9.26517 15.4805 9.51947 15.293 9.707L10.707 14.293C10.5195 14.4805 10.2652 14.5858 10 14.5858C9.73484 14.5858 9.48053 14.4805 9.293 14.293L2.293 7.293C2.10545 7.10551 2.00006 6.8512 2 6.586V2Z"
      fillRule="evenodd"
    />
    <path
      d="M5.5 5C5.36739 5 5.24021 4.94732 5.14645 4.85355C5.05268 4.75979 5 4.63261 5 4.5C5 4.36739 5.05268 4.24022 5.14645 4.14645C5.24021 4.05268 5.36739 4 5.5 4C5.63261 4 5.75979 4.05268 5.85355 4.14645C5.94732 4.24022 6 4.36739 6 4.5C6 4.63261 5.94732 4.75979 5.85355 4.85355C5.75979 4.94732 5.63261 5 5.5 5ZM5.5 6C5.89782 6 6.27936 5.84197 6.56066 5.56066C6.84196 5.27936 7 4.89783 7 4.5C7 4.10218 6.84196 3.72064 6.56066 3.43934C6.27936 3.15804 5.89782 3 5.5 3C5.10218 3 4.72064 3.15804 4.43934 3.43934C4.15804 3.72064 4 4.10218 4 4.5C4 4.89783 4.15804 5.27936 4.43934 5.56066C4.72064 5.84197 5.10218 6 5.5 6ZM1 7.086C1.00006 7.3512 1.10545 7.60551 1.293 7.793L8.75 15.25L8.707 15.293C8.51947 15.4805 8.26516 15.5858 8 15.5858C7.73484 15.5858 7.48053 15.4805 7.293 15.293L0.293 8.293C0.105451 8.10551 5.66374e-05 7.8512 0 7.586L0 3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2V7.086Z"
      fillRule="evenodd"
    />
  </svg>
);
