import { keyframes } from '@emotion/react';

export const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const breath = keyframes`
  50% { opacity: 0.5; }
`;
