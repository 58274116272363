import { createAction, createReducer } from '@reduxjs/toolkit';

import { VendorAppRootState } from '../../store';

// Actions
export const vendorPageLoaded = createAction<{
  vendorToken: string;
}>('[vendor] page loaded with a vendor token');
export const vendorUserReady = createAction('[vendor] user ready');
export const vendorUserErrored = createAction('[vendor] user errored');

const initialState: { userState: 'ready' | 'errored' | null } = {
  userState: null,
};

// Reducers
export const vendorReducer = createReducer(initialState, (builder) => {
  builder.addCase(vendorPageLoaded, (state) => {
    state.userState = null;
  });

  builder.addCase(vendorUserReady, (state) => {
    state.userState = 'ready';
  });

  builder.addCase(vendorUserErrored, (state) => {
    state.userState = 'errored';
  });
});

// Selectors
export const getVendorUserState = (state: VendorAppRootState) => state.vendor.userState;
