import {
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useFormControlContext,
} from '@chakra-ui/react';
import { useStableCallback } from '@main/shared/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DynamicFormComponentProps, DynamicFormFieldComponent } from '../field-registry';
import { BaseDynamicFormFieldConfig, DynamicFormValidation } from '../types';

export interface OptionsFieldConfig extends BaseDynamicFormFieldConfig {
  value?: string | string[];
  placeholder?: string;
  options?: OptionsFieldOption[];
  isMultiOptions?: boolean;
  validation?: OptionsFieldValidation;
}

export interface OptionsFieldOption {
  value: string;
  label: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OptionsFieldValidation extends DynamicFormValidation {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OptionsFieldProps extends DynamicFormComponentProps<OptionsFieldConfig> {}

type ControllerRenderProps = Parameters<Parameters<typeof Controller>[0]['render']>[0];

export const OptionsField: DynamicFormFieldComponent<OptionsFieldConfig> = ({ config }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const formControl = useFormControlContext();
  const isCompleted = formControl.isDisabled || formControl.isReadOnly;

  const fieldComponent = useStableCallback(
    ({ field: { value, onChange } }: ControllerRenderProps) => {
      if (isCompleted && (!value || (Array.isArray(value) && !value.length))) {
        return (
          <Text fontSize="sm" color="gray.600">
            {t('questionnaires.questions.notAnswered')}
          </Text>
        );
      }

      return config.isMultiOptions ? (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <CheckboxGroup size="sm" value={value} onChange={onChange}>
          <Stack>
            {config.options?.map((option) => (
              <Checkbox key={option.value} value={option.value}>
                {option.label}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <RadioGroup size="sm" value={value} onChange={onChange}>
          <Stack>
            {config.options?.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      );
    },
  );

  return (
    <Controller
      name={config.name}
      control={control}
      rules={config.validation}
      render={fieldComponent}
    />
  );
};

OptionsField.resolveReadableValues = ({ config }) => {
  if (!config.value || !config.options?.length) {
    return [];
  }

  const valueToLabel = mapValueToLabel(config.options);

  if (Array.isArray(config.value)) {
    return config.value.map(valueToLabel);
  }

  return [valueToLabel(config.value)];
};

function mapValueToLabel(options: OptionsFieldOption[]) {
  return (value: string) => options.find((option) => option.value === value)?.label ?? value;
}
