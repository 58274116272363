import { createContext, useContext } from 'react';

const AssetUrlContext = createContext('assets');

export const { Provider: AssetUrlProvider } = AssetUrlContext;

export function useAssetUrl() {
  const baseUrl = useContext(AssetUrlContext);
  return (path: string) => `${baseUrl}/${path}`;
}
