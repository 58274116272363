/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.vendor.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type InsertVendorQuestionnaireAnswersMutationVariables = Types.Exact<{
  file:
    | Array<Types.Vendor_Questionnaire_Uploads_Insert_Input>
    | Types.Vendor_Questionnaire_Uploads_Insert_Input;
}>;

export type InsertVendorQuestionnaireAnswersMutation = {
  __typename?: 'mutation_root';
  insert_vendor_questionnaire_uploads?: {
    __typename?: 'vendor_questionnaire_uploads_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'vendor_questionnaire_uploads'; id: string; file_id: string }>;
  };
};

export const InsertVendorQuestionnaireAnswersDocument = `
    mutation InsertVendorQuestionnaireAnswers($file: [vendor_questionnaire_uploads_insert_input!]!) {
  insert_vendor_questionnaire_uploads(objects: $file) {
    affected_rows
    returning {
      id
      file_id
    }
  }
}
    ` as string &
  TypedDocumentNode<
    InsertVendorQuestionnaireAnswersMutation,
    InsertVendorQuestionnaireAnswersMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    InsertVendorQuestionnaireAnswers: build.mutation<
      InsertVendorQuestionnaireAnswersMutation,
      InsertVendorQuestionnaireAnswersMutationVariables
    >({
      query: (variables) => ({ document: InsertVendorQuestionnaireAnswersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInsertVendorQuestionnaireAnswersMutation } = injectedRtkApi;
