/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.vendor.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type SetVendorQuestionnaireAnswerMutationVariables = Types.Exact<{
  answerId?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  questionnaireId?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
}>;

export type SetVendorQuestionnaireAnswerMutation = {
  __typename?: 'mutation_root';
  update_vendor_questionnaires?: {
    __typename?: 'vendor_questionnaires_mutation_response';
    affected_rows: number;
  };
};

export const SetVendorQuestionnaireAnswerDocument = `
    mutation SetVendorQuestionnaireAnswer($answerId: uuid, $questionnaireId: uuid) {
  update_vendor_questionnaires(
    _set: {answer_upload_id: $answerId}
    where: {id: {_eq: $questionnaireId}}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<
    SetVendorQuestionnaireAnswerMutation,
    SetVendorQuestionnaireAnswerMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    SetVendorQuestionnaireAnswer: build.mutation<
      SetVendorQuestionnaireAnswerMutation,
      SetVendorQuestionnaireAnswerMutationVariables | void
    >({
      query: (variables) => ({ document: SetVendorQuestionnaireAnswerDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSetVendorQuestionnaireAnswerMutation } = injectedRtkApi;
