import { Icon, Menu } from '@chakra-ui/react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { Column, Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { TableMenuButton, TableMenuColumnSelect, TableMenuList } from '../shared/table-menu';

export function AddFilter<TData>({ table }: { table: Table<TData> }) {
  const { t } = useTranslation('ui');

  return (
    <Menu isLazy>
      <TableMenuButton color="gray.500" leftIcon={<Icon as={AdjustmentsHorizontalIcon} />}>
        {t('table.filter.add')}
      </TableMenuButton>
      <TableMenuList overflow="hidden">
        <TableMenuColumnSelect
          table={table}
          placeholder={t('table.filter.addPlaceholder')}
          shouldDisplayColumn={isFilterableColumn}
          onSelect={(column) => {
            column.setFilterValue(null);
          }}
        />
      </TableMenuList>
    </Menu>
  );
}

function isFilterableColumn<TData>(column: Column<TData, unknown>): boolean {
  return column.getCanFilter() && column.getFilterValue() === undefined;
}
