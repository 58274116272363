import { Flex, HStack, Icon, Skeleton, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { EmptyPlaceholder } from '../placeholder';
import { TableItem } from './table';

export const TableLoadingState = () => {
  return (
    <VStack px={6} py={5} spacing={4}>
      {new Array(6).fill(0).map((_, index) => {
        return (
          <HStack w="full" spacing={4} key={index}>
            <Skeleton height="38px" minW="120px" />
            <Skeleton height="38px" w="full" />
          </HStack>
        );
      })}
    </VStack>
  );
};

export const TableEmptyState = ({
  itemName,
  subHeading,
}: {
  itemName: TableItem;
  subHeading?: string;
}) => {
  const { t } = useTranslation('ui');

  return (
    <EmptyPlaceholder py={8}>
      <EmptyPlaceholder.Icon as={XMarkIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>
          {t('table.empty.heading', { item: itemName.plural })}
        </EmptyPlaceholder.Heading>
        {subHeading && <EmptyPlaceholder.Subheading>{subHeading}</EmptyPlaceholder.Subheading>}
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
};

export const TableEmptyFilterResult = ({ itemName }: { itemName: TableItem }) => {
  const { t } = useTranslation('ui');

  const outerBgColor = useColorModeValue('blue.50', 'blue.100');
  const innerBgColor = useColorModeValue('blue.100', 'blue.200');

  return (
    <Flex direction="column" alignItems="center" py={8}>
      <Flex
        w={12}
        h={12}
        justify="center"
        alignItems="center"
        bgColor={outerBgColor}
        rounded="full"
      >
        <Flex
          w={9}
          h={9}
          justify="center"
          alignItems="center"
          bgColor={innerBgColor}
          rounded="full"
        >
          <Icon w={6} h={6} as={MagnifyingGlassIcon} color="blue.600" />
        </Flex>
      </Flex>
      <Text fontWeight="semibold" mt={4}>
        {`${t('table.filter.notFoundHeading', { item: itemName.plural })}`}
      </Text>
      <Text mt={1} color="gray.500" fontSize="sm" width="320px" textAlign="center" as="span">
        {`${t('table.filter.notFoundSubheadingFirstLine', { item: itemName.singular })}`}
      </Text>
      <Text color="gray.500" fontSize="sm" width="320px" textAlign="center" as="span">
        {`${t('table.filter.notFoundSubheadingSecondLine', { item: itemName.singular })}`}
      </Text>
    </Flex>
  );
};
