// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function whenNotSelecting<FArgs extends any[], FRet>(fn: (...args: FArgs) => FRet) {
  return (...args: FArgs): FRet | void => {
    if (isSelecting()) {
      return;
    }

    return fn(...args);
  };
}

function isSelecting(): boolean {
  return Boolean(window.getSelection()?.toString());
}
