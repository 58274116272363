import { datadogLogs } from '@datadog/browser-logs';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import {
  QuestionnnaireForm,
  QuestionnnaireFormFieldAnswer,
  QuestionnnaireFormMode,
  QuestionnnaireFormStats,
} from '@main/questionnaires-form';
import { dedupeArrays, toError, useStableCallback } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import useDebounceFn from 'ahooks/es/useDebounceFn';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetVendorQuestionnaireDrawerQuery } from './drawer.generated';
import { useGetVendorQuestionnaireDrawerSubscription } from './drawer.subs';
import {
  useCreateVendorQuestionnaireAnswerFilesMutation,
  useCreateVendorQuestionnaireAnswerMutation,
  useDeleteVendorQuestionnaireAnswerFileMutation,
  useUpdateVendorQuestionnaireAnswerMutation,
} from './questions-tab.vendor.generated';

export interface VendorQuestionnaireQuestionsTabProps {
  questionnaireId: string;
  onStatsChange?(stats: QuestionnnaireFormStats): void;
}

export function VendorQuestionnaireQuestionsTab({
  questionnaireId,
  onStatsChange,
}: VendorQuestionnaireQuestionsTabProps) {
  const { data } = useGetVendorQuestionnaireDrawerSubscription({ questionnaireId });

  const questionnaire = data?.vendor_questionnaires_by_pk;

  const { answers, onAnswerChange } = useQuestionnaireAnswers({ questionnaireId, questionnaire });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fields = useMemo(() => questionnaire?.form?.config_snapshot ?? [], [questionnaire?.id]);

  if (!questionnaire || !questionnaire.form?.config_snapshot) {
    return null;
  }

  const isSubmitted = questionnaire.status === Vendor_Questionnaire_Statuses_Enum.Submitted;

  return (
    <QuestionnnaireForm
      mode={QuestionnnaireFormMode.Answer}
      fields={fields}
      answers={answers}
      uploads={questionnaire.questionnaire.questionnaire_uploads}
      isSubmitted={isSubmitted}
      onAnswerChange={onAnswerChange}
      onStatsChange={onStatsChange}
    />
  );
}

interface LocalQuestionnnaireFormFieldAnswer extends QuestionnnaireFormFieldAnswer {
  isCreating?: boolean;
}

interface UseQuestionnaireAnswersProps {
  questionnaireId: string;
  questionnaire: GetVendorQuestionnaireDrawerQuery['vendor_questionnaires_by_pk'];
}

function useQuestionnaireAnswers({ questionnaireId, questionnaire }: UseQuestionnaireAnswersProps) {
  const { t } = useTranslation();
  const { refetch } = useGetVendorQuestionnaireDrawerSubscription({ questionnaireId });
  const [createAnswer] = useCreateVendorQuestionnaireAnswerMutation();
  const [updateAnswer] = useUpdateVendorQuestionnaireAnswerMutation();
  const [createAnswerFiles] = useCreateVendorQuestionnaireAnswerFilesMutation();
  const [deleteAnswerFiles] = useDeleteVendorQuestionnaireAnswerFileMutation();

  const updateAnswerFn = useDebounceFn(updateAnswer, { wait: 500 });

  const [answers, setAnswers] = useState<LocalQuestionnnaireFormFieldAnswer[]>([]);

  const onCreateNewAnswer = useStableCallback(
    async (
      answer: LocalQuestionnnaireFormFieldAnswer,
      change: Partial<QuestionnnaireFormFieldAnswer>,
    ) => {
      if (!questionnaire || answer.isCreating) {
        return;
      }

      const newAnswer: LocalQuestionnnaireFormFieldAnswer = {
        ...answer,
        ...change,
        isCreating: true,
      };

      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);

      await createAnswer({
        answer: {
          field_name: newAnswer.field_name,
          status: newAnswer.status,
          value: newAnswer.value,
          comment: newAnswer.comment,
          vendor_questionnaire_form_id: questionnaire.form?.id,
          form_uploads: newAnswer.form_uploads
            ? {
                data: newAnswer.form_uploads.map((upload) => ({
                  file_id: upload.file_id,
                  vendor_questionnaire_form_answer_id: newAnswer.id,
                })),
              }
            : undefined,
        },
      }).unwrap();
    },
  );

  const onUpdateAnswer = useStableCallback(
    async (
      answer: LocalQuestionnnaireFormFieldAnswer,
      change: Partial<QuestionnnaireFormFieldAnswer>,
    ) => {
      const currentAnswer = answers.find(
        (prevAnswer) => prevAnswer.field_name === answer.field_name,
      );
      const newAnswer = { ...currentAnswer, ...change } as LocalQuestionnnaireFormFieldAnswer;

      if (!answer.id || !currentAnswer) {
        return setAnswers((prevAnswers) =>
          prevAnswers.map((prevAnswer) =>
            prevAnswer.field_name === answer.field_name ? newAnswer : prevAnswer,
          ),
        );
      }

      const shouldUpdateAnswer =
        ('status' in change && change.status !== currentAnswer.status) ||
        ('value' in change && change.value !== currentAnswer.value) ||
        ('comment' in change && change.comment !== currentAnswer.comment);

      const addedFiles = change.form_uploads?.filter(
        (upload) => !currentAnswer.form_uploads?.find((prevUpload) => prevUpload.id === upload.id),
      );
      const deletedFiles =
        change.form_uploads &&
        currentAnswer.form_uploads?.filter(
          (prevUpload) => !change.form_uploads?.find((upload) => upload.id === prevUpload.id),
        );

      setAnswers((prevAnswers) =>
        prevAnswers.map((prevAnswer) =>
          prevAnswer.field_name === answer.field_name ? newAnswer : prevAnswer,
        ),
      );

      if (addedFiles?.length) {
        await createAnswerFiles({
          files: addedFiles.map((upload) => ({
            file_id: upload.file_id,
            vendor_questionnaire_form_answer_id: newAnswer.id,
          })),
        }).unwrap();
      }

      if (deletedFiles?.length) {
        await deleteAnswerFiles({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          uploadIds: deletedFiles.map((upload) => upload.id!),
        }).unwrap();
      }

      if (shouldUpdateAnswer) {
        await updateAnswerFn
          .run({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            answerId: newAnswer.id!,
            answer: {
              status: newAnswer.status,
              value: newAnswer.value,
              comment: newAnswer.comment,
            },
          })
          ?.unwrap();
      }

      if (addedFiles?.length || deletedFiles?.length) {
        await refetch().unwrap();
      }
    },
  );

  const onAnswerChange = useDebounceFn(
    useStableCallback(
      async (
        answer: LocalQuestionnnaireFormFieldAnswer,
        change: Partial<QuestionnnaireFormFieldAnswer>,
      ) => {
        try {
          if (answer.id || answer.isCreating) {
            await onUpdateAnswer(answer, change);
          } else {
            await onCreateNewAnswer(answer, change);
          }
        } catch (error) {
          errorToast(
            t('errorMessages.updateFailed', {
              entity: t('entities.vendorQuestionnaireFormAnswer'),
            }),
          );
          datadogLogs.logger.error(
            'Error while saving vendor questionnaire answer',
            { questionnaireId },
            toError(error),
          );
        }
      },
    ),
    { wait: 0 },
  );

  useEffect(
    () =>
      setAnswers((localAnswers) => {
        const answers = questionnaire?.form?.answers ?? [];

        return dedupeArrays<LocalQuestionnnaireFormFieldAnswer>(
          [
            answers.map((answer) => {
              const localAnswer = localAnswers.find((a) => a.field_name === answer.field_name);

              if (!localAnswer) {
                return answer;
              }

              return {
                ...answer,
                status: localAnswer.status ?? answer.status,
                value: localAnswer.value ?? answer.value,
                comment: localAnswer.comment ?? answer.comment,
              };
            }),
            localAnswers,
          ],
          (answer) => answer.field_name,
        );
      }),
    [questionnaire?.form?.answers, questionnaire?.form?.config_snapshot],
  );

  return { answers, onAnswerChange: onAnswerChange.run };
}
