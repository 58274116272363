import { FileItemRef, useFileUploadItem } from '@nhost/react';
import { useMemo } from 'react';

import { UploadFile } from './upload-files';

type NhostUploadFileComponentProps = {
  fileRef: FileItemRef;
  onDelete?: (fileId: string, file: File) => void;
};

export const NhostFileUploader: React.FC<NhostUploadFileComponentProps> = ({
  fileRef,
  onDelete,
}) => {
  const { isUploaded, isUploading, error } = useFileUploadItem(fileRef);

  const file = useMemo(() => fileRef.getSnapshot()?.context.file, [fileRef]);

  if (!file) {
    return null;
  }

  return (
    <UploadFile
      file={file}
      error={error?.message}
      isUploaded={isUploaded}
      isLoading={isUploading}
      onDelete={() => onDelete?.(fileRef.id, file)}
    />
  );
};
