import { Cell, Row } from '@tanstack/react-table';
import { DOMAttributes, EventHandler, SyntheticEvent } from 'react';

export type TableAction = TableActionHighlightRow;

export type ColumnTableAction<T> = TableAction & ColumnTableActionExtras<T>;

export type TableActionFactory<T> = (
  row: Row<T>,
  cell: Cell<T, unknown>,
  event: SyntheticEvent,
) => TableAction | undefined;

export interface ColumnTableActionExtras<T = unknown> {
  /** When to trigger an action. By default on click. */
  triggerOn?: DOMEventProps;
  actionFactory?: TableActionFactory<T>;
}

export type DOMEventProps<TEventDict = DOMAttributes<HTMLElement>> = {
  [K in keyof TEventDict]-?: EventHandler<never> extends TEventDict[K] ? K : never;
}[keyof TEventDict];

export enum TableActionType {
  HighlightRow = 'highlight-row',
}

export interface TableActionHighlightRow {
  type: TableActionType.HighlightRow;
}

export type TableActionHandler<T> = (
  action: TableAction,
  row: Row<T>,
  cell: Cell<T, unknown>,
) => void;

export function withHighlightRowTableAction<FArgs extends unknown[], FRet>(
  fn: (...args: FArgs) => FRet,
) {
  return withTableAction(fn, { type: TableActionType.HighlightRow });
}

export function withTableAction<FArgs extends unknown[], FRet>(
  fn: (...args: FArgs) => FRet,
  action: TableAction | ((args: FArgs, result: FRet) => TableAction),
): (...args: FArgs) => TableAction {
  if (typeof action === 'function') {
    return (...args) => {
      const result = fn(...args);
      return action(args, result);
    };
  }

  return (...args) => {
    fn(...args);
    return action;
  };
}
