import { Flex, Skeleton, Stack } from '@chakra-ui/react';

export const DrawerSkeleton = () => {
  return (
    <Stack spacing={4} h="full" flexGrow={1} py={6} px={9}>
      <Stack direction="row" spacing={2}>
        <Skeleton w={24} h={4} />
        <Skeleton w={14} h={4} />
      </Stack>
      <Skeleton w="70%" h={10} />
      <Stack spacing={8} pt={6}>
        <Flex gap={8}>
          <Skeleton w={36} h={7} />
          <Skeleton w="75%" h={7} />
        </Flex>
        <Flex gap={8}>
          <Skeleton w={36} h={7} />
          <Skeleton w="30%" h={7} />
        </Flex>
        <Flex gap={8}>
          <Skeleton w={36} h={7} />
          <Skeleton w="45%" h={7} />
        </Flex>
        <Flex gap={8}>
          <Skeleton w={36} h={7} />
          <Skeleton w="35%" h={7} />
        </Flex>
        <Flex gap={8}>
          <Skeleton w={36} h={7} />
          <Skeleton w="25%" h={7} />
        </Flex>
        <Flex gap={8}>
          <Skeleton w={36} h={7} />
          <Skeleton w="25%" h={7} />
        </Flex>
        <Flex gap={8}>
          <Skeleton w={36} h={7} />
          <Skeleton w="55%" h={7} />
        </Flex>
        <Stack spacing={3}>
          <Skeleton w={36} h={4} />
          <Skeleton w="full" h={24} />
        </Stack>
      </Stack>
    </Stack>
  );
};
