import { DefaultTabNameSpace, TabId } from '../tabs';
import { LoadingDrawer } from './loading-drawer';
import { DrawerCallbackData, DrawerEntity, DrawerObserver, DrawerSearchFn } from './types';

export interface DrawerServiceConfig {
  search: DrawerSearchFn;
  searchReplace: DrawerSearchFn;
  getDrawerObserver(observer: DrawerObserver, config: DrawerServiceConfig): void;
  searchNamespace?: string;
}

export type DrawerCallback = (drawer?: DrawerCallbackData) => void;

export class DrawerService {
  protected search = this.options.search;
  protected searchReplace = this.options.searchReplace;
  protected namespaceBase = this.options.searchNamespace ?? 'drawer';
  protected namespace = {
    entity: `${this.namespaceBase}Entity`,
    entityId: `${this.namespaceBase}EntityId`,
  };
  protected currentData?: DrawerCallbackData;
  protected callbacks = new Set<DrawerCallback>();

  constructor(protected options: DrawerServiceConfig) {
    this.options.getDrawerObserver((data) => this.drawerObserver(data), this.options);
  }

  async open(options: { entity: DrawerEntity; entityId: string; activeTab?: TabId }) {
    await this.search({
      [this.namespace.entity]: options.entity,
      [this.namespace.entityId]: options.entityId,
      ...(options.activeTab
        ? { [DefaultTabNameSpace]: options.activeTab }
        : { [DefaultTabNameSpace]: undefined }),
    });
  }

  async openLoading(options: { entity: DrawerEntity }) {
    await this.search({
      [this.namespace.entity]: options.entity,
      [this.namespace.entityId]: undefined,
    });

    return new LoadingDrawer({
      open: (entityId) =>
        this.searchReplace({
          [this.namespace.entity]: options.entity,
          [this.namespace.entityId]: entityId,
        }),
    });
  }

  async close() {
    await this.search({
      [this.namespace.entity]: undefined,
      [this.namespace.entityId]: undefined,
      [DefaultTabNameSpace]: undefined,
    });
  }

  onDrawer(cb: DrawerCallback) {
    this.callbacks.add(cb);
    cb(this.currentData);
    return () => void this.callbacks.delete(cb);
  }

  getNamespace() {
    return this.namespace;
  }

  getCurrentData() {
    return this.currentData;
  }

  protected drawerObserver(data?: DrawerCallbackData) {
    this.currentData = data;
    this.callbacks.forEach((cb) => cb(data));
  }
}
