import { forwardRef, Textarea, TextareaProps } from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';

export type AutoResizeTextareaProps = TextareaProps & {
  blurOnEnter?: boolean;
};

export const AutoResizeTextarea = forwardRef<AutoResizeTextareaProps, typeof ResizeTextarea>(
  (props, ref) => {
    const { blurOnEnter, ...textareaProps } = props;

    return (
      <Textarea
        minH="unset"
        overflow="hidden"
        w="100%"
        resize="none"
        ref={ref}
        minRows={1}
        {...textareaProps}
        onKeyDown={getOnKeyDown(props)}
        as={ResizeTextarea}
      />
    );
  },
);

function getOnKeyDown({
  blurOnEnter,
  onKeyDown,
}: AutoResizeTextareaProps): AutoResizeTextareaProps['onKeyDown'] {
  if (!blurOnEnter) {
    return onKeyDown;
  }

  return (event) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
    onKeyDown?.(event);
  };
}
