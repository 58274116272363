import { useMemo } from 'react';

export type WithState<T, TState = void> = T | ((state: TState) => T);

export function useWithState<T>(thing: WithState<T, void>): () => T;
export function useWithState<T, TState>(thing: WithState<T, TState>): (state: TState) => T;
export function useWithState<T, TState>(thing: WithState<T, TState>): (state?: TState) => T {
  return useMemo(() => {
    if (typeof thing === 'function') {
      return (state?: TState) => (thing as (state?: TState) => T)(state);
    }

    return () => thing;
  }, [thing]);
}

export function renderWithState<T>(thing: WithState<T, void>): T;
export function renderWithState<T, TState>(thing: WithState<T, TState>, state: TState): T;
export function renderWithState<T, TState>(thing: WithState<T, TState>, state?: TState): T {
  if (typeof thing === 'function') {
    return (thing as (state?: TState) => T)(state);
  }

  return thing;
}
