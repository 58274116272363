import { css } from '@emotion/react';

export default css`
  .editor-container {
    font-weight: 400;
    text-align: left;
    width: 100%;
    display: flex;
    position: relative;
    align-items: end;
    min-height: 32px;
  }

  .editor-input {
    resize: none;
    font-size: 15px;
    tab-size: 1;
    outline: 0;
    width: 100%;
  }

  .editor-placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    user-select: none;
    display: inline-block;
    pointer-events: none;

    position: absolute;
  }

  .dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .mentions-menu {
    min-width: 250px;
    z-index: 10000;
  }

  .mention:focus {
    box-shadow: rgb(180 213 255) 0px 0px 0px 2px;
    outline: none;
  }

  .typeahead-popover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;

    position: absolute;
    top: 25px;
    left: 0;
  }

  .chakra-ui-light .typeahead-popover {
    background: #fff;
  }

  .chakra-ui-dark .typeahead-popover {
    background: var(--chakra-colors-gray-300);
  }

  .typeahead-popover ul {
    padding: 0;
    list-style: none;
    margin: 0;
    max-height: 200px;
    overflow-y: scroll;
  }

  .typeahead-popover ul::-webkit-scrollbar {
    display: none;
  }

  .typeahead-popover ul {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .typeahead-popover ul li {
    margin: 0;
    min-width: 180px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
  }

  .typeahead-popover li {
    margin: 0 8px 0 8px;
    padding: 8px;

    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    border: 0;
  }

  .chakra-ui-light .typeahead-popover li {
    color: #000;
    background-color: #fff;
  }

  .chakra-ui-dark .typeahead-popover li {
    color: #fff;
    background-color: var(--chakra-colors-gray-600);
  }

  .typeahead-popover li.active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
  }

  .typeahead-popover li:hover {
    background-color: var(--chakra-colors-gray-500);
  }

  .typeahead-popover li.selected {
    background-color: var(--chakra-colors-gray-500);
  }

  .chakra-ui-light .typeahead-popover li:hover {
    background-color: #eee;
  }

  .chakra-ui-dark .typeahead-popover li:hover {
    background-color: var(--chakra-colors-gray-500);
  }

  .typeahead-popover li .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    min-width: 150px;
  }
`;
