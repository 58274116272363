import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import LocalizedFormat from 'dayjs/plugin/localizedFormat.js';
import RelativeTime from 'dayjs/plugin/relativeTime.js';
import utc from 'dayjs/plugin/utc.js';

dayjs.extend(RelativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

export const DEFAULT_DATE_FORMAT = 'LL';

export const formatDate = (date?: string | Date | null, format?: string) =>
  date ? dayjs(date).format(format || DEFAULT_DATE_FORMAT) : '';

export const formatCommentedTime = (value: string) => {
  const time = dayjs(value).fromNow();

  if (parseInt(time.split(' ')[0] || '0') > 9 && time.split(' ')[1] === 'days') {
    return formatDate(value);
  }

  return time;
};

export function getToday() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export function getTodayDateString() {
  return new Date().toISOString().split('T')[0];
}
