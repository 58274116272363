import { useCallbackRef, useDisclosure } from '@chakra-ui/react';
import useDebounce from 'ahooks/es/useDebounce';
import {
  CreatableProps as CreatableSelectProps,
  CreatableSelect,
  GroupBase,
  InputActionMeta,
  OptionsOrGroups,
  Props as SelectProps,
  Select,
} from 'chakra-react-select';
import { useEffect, useState } from 'react';

export type AsyncSelectAdditionalProps<TOption, TGroup extends GroupBase<TOption>> = {
  debounceMs?: number;
  loadOptions?: (input: string) => Promise<OptionsOrGroups<TOption, TGroup>>;
};

export type AsyncSelectProps<
  TOption,
  IsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
> = SelectProps<TOption, IsMulti, TGroup> & AsyncSelectAdditionalProps<TOption, TGroup>;

export function AsyncSelect<
  TOption,
  IsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(props: AsyncSelectProps<TOption, IsMulti, TGroup>) {
  return <Select {...props} {...useAsyncSelect(props)} />;
}

export type AsyncCreatableSelectProps<
  TOption,
  IsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
> = CreatableSelectProps<TOption, IsMulti, TGroup> & AsyncSelectAdditionalProps<TOption, TGroup>;

export function AsyncCreatableSelect<
  TOption,
  IsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(props: AsyncCreatableSelectProps<TOption, IsMulti, TGroup>) {
  return <CreatableSelect {...props} {...useAsyncSelect(props)} />;
}

function useAsyncSelect<TOption, IsMulti extends boolean, TGroup extends GroupBase<TOption>>({
  menuIsOpen,
  defaultMenuIsOpen,
  debounceMs = 0,
  loadOptions,
  onMenuOpen,
  onMenuClose,
  onInputChange: onInputChangeProp,
}: AsyncSelectProps<TOption, IsMulti, TGroup>): SelectProps<TOption, IsMulti, TGroup> {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: defaultMenuIsOpen,
    isOpen: menuIsOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<OptionsOrGroups<TOption, TGroup>>([]);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, { wait: debounceMs });
  const stableLoadOptions = useCallbackRef(loadOptions);

  useEffect(() => {
    if (!isOpen || !inputValue) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
  }, [isOpen, inputValue]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    void stableLoadOptions(debouncedInputValue)
      .then(setOptions)
      .finally(() => setIsLoading(false));
  }, [isOpen, stableLoadOptions, debouncedInputValue]);

  const onInputChange = (input: string, meta: InputActionMeta) => {
    setInputValue(input);
    onInputChangeProp?.(input, meta);
  };

  return {
    defaultMenuIsOpen,
    menuIsOpen: isOpen,
    onMenuOpen: onOpen,
    onMenuClose: onClose,
    isLoading,
    options,
    onInputChange,
  };
}
