import { forwardRef, Ref, RefAttributes } from 'react';

/* The problem with forwardRef is that TypeScript cannot infer the genericly typed components
  if we use forwardRef:
  https://github.com/chakra-ui/chakra-ui/issues/4528

  There is a solution, we can redeclare the forwardRef on the module level:
  https://fettblog.eu/typescript-react-generic-forward-refs/

  *SOLUTION*
  declare module 'react' {
    function forwardRef<T, P = {}>(
      render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;

  However there is another problem with this solution, since we are redeclaring the function
  on module level we are losing its other props like:
  - displayName
  - propTypes
  ex: Select.displayName -> error

  After this solution anywhere in the app we cannot access these props in case we need to.


  *Another solution is making this change to forwardRef LOCAL rather than GLOBAL*

  We create the type of the forwardRef function redeclared in the module above and use
  TypeScript assertion to make it work the way we want (only in places we need to).
}
*/

type FixedForwardRef = <T, P = object>(
  render: (props: P, ref: Ref<T>) => React.ReactNode | null,
) => (props: P & RefAttributes<T>) => React.ReactNode | null;

export const fixedForwardRef = forwardRef as FixedForwardRef;
