import { MenuList, MenuListProps, useMenuState } from '@chakra-ui/react';

// chakra menu animation conflicts with input focus - overriding default motion props to disable it:
// https://github.com/chakra-ui/chakra-ui/blob/0e0d371a9118df28858f3fdff11bf5ed89441b7e/packages/components/menu/src/menu-list.tsx#L19-L40
export const tableMenuMotionProps = {
  variants: {
    enter: {
      transition: {
        duration: 0,
      },
    },
    exit: {
      transition: {
        duration: 0,
      },
    },
  },
};

export function TableMenuList(props: MenuListProps) {
  const { onClose } = useMenuState();

  return (
    <MenuList
      p={0}
      w="sm"
      motionProps={tableMenuMotionProps}
      onKeyUp={(event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      }}
      {...props}
    />
  );
}
