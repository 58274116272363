import { createGqlSubscriptionApi } from '@main/graphql/create-subscription-api';

import { api, GetVendorQuestionnaireDrawerDocument } from './drawer.generated';

export const { useGetVendorQuestionnaireDrawerQuery: useGetVendorQuestionnaireDrawerSubscription } =
  createGqlSubscriptionApi(
    api,
    'GetVendorQuestionnaireDrawer',
    GetVendorQuestionnaireDrawerDocument,
  );
