import { Box, TagCloseButton, ThemeTypings } from '@chakra-ui/react';

import { Combobox, ComboboxProps, withMultiCombobox } from '../combobox';
import { StatusTag } from '../status-tag';

export type StatusOption = {
  value: string;
  label?: string;
  colorScheme?: ThemeTypings['colorSchemes'];
};

export const defaultProps = {
  getOptionValue: (option) => option.value,
  getOptionLabel: (option) => option.label || option.value,

  components: {
    Option: (props) => (
      <StatusTag
        size="sm"
        minW="auto"
        maxW="none"
        colorScheme={props.colorScheme}
        rightSlot={props.removeProps && <Box as={TagCloseButton} {...props.removeProps} />}
      >
        {defaultProps.getOptionLabel(props)}
      </StatusTag>
    ),
  },
} satisfies ComboboxProps<StatusOption>;

export function EditableStatus<TOption extends StatusOption, IsMulti extends boolean = false>(
  props: Omit<ComboboxProps<TOption, IsMulti>, 'components'>,
) {
  return <Combobox {...defaultProps} {...withMultiCombobox(props)} />;
}

EditableStatus.Option = defaultProps.components.Option;
