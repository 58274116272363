import { Button, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useInsertVendorQuestionnaireAnswersMutation } from '@main/graphql/mutations/InsertVendorQuestionnaireAnswers.vendor.generated';
import { useMarkVendorQuestionnaireAsSubmittedMutation } from '@main/graphql/mutations/MarkVendorQuestionnaireAsSubmitted.vendor.generated';
import { useSetVendorQuestionnaireAnswerMutation } from '@main/graphql/mutations/SetVendorQuestionnaireAnswer.vendor.generated';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import { Vendor_Questionnaire_Uploads_Insert_Input } from '@main/graphql/types.vendor.generated';
import { toError } from '@main/shared/utils';
import {
  errorToast,
  FileUpload,
  successToast,
  UploadedFile,
  useAlertDialog,
  useDownloadStorageFile,
  useLazyFileUpload,
  useLazyMultipleFilesUpload,
} from '@main/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetVendorQuestionnaireDrawerQuery,
  useGetVendorQuestionnaireDrawerQuery,
} from './drawer.generated';

type VendorQuestionnaire = GetVendorQuestionnaireDrawerQuery['vendor_questionnaires_by_pk'];

export interface VendorQuestionnaireSubmissionTabProps {
  questionnaireId: string;
}

export function VendorQuestionnaireSubmissionTab({
  questionnaireId,
}: VendorQuestionnaireSubmissionTabProps) {
  const { t } = useTranslation();
  const downloadStorageFile = useDownloadStorageFile();
  const { openDialog } = useAlertDialog();
  const { data } = useGetVendorQuestionnaireDrawerQuery({ questionnaireId });
  const [submitVendorQuestionnaire] = useMarkVendorQuestionnaireAsSubmittedMutation();

  const questionnaire = data?.vendor_questionnaires_by_pk;
  const isAnswerUploaded = !!questionnaire?.answer_upload?.file;
  const isSubmitted = questionnaire?.status === Vendor_Questionnaire_Statuses_Enum.Submitted;

  const [answerFile, setAnswerFile] = useState<File | UploadedFile | undefined>(
    questionnaire?.answer_upload?.file,
  );
  const [supportingFiles, setSupportingFiles] = useState<UploadedFile[]>(
    getSupportingFiles(questionnaire),
  );
  const answerFileUpload = useLazyFileUpload({
    file: isAnswerUploaded ? (answerFile as UploadedFile) : undefined,
    onFileDownload: isAnswerUploaded ? downloadStorageFile : undefined,
    onFileAdd: isAnswerUploaded
      ? undefined
      : (file) => {
          (file as unknown as UploadedFile).id = 'not-uploaded';
          setAnswerFile(file);
        },
    onFileDelete: isAnswerUploaded ? undefined : () => setAnswerFile(undefined),
  });
  const supportingFilesUpload = useLazyMultipleFilesUpload({
    files: isAnswerUploaded ? supportingFiles : undefined,
    onFileDownload: isAnswerUploaded ? downloadStorageFile : undefined,
  });
  const { associateQuestionnaireFiles, isLoading } =
    useAssociateQuestionnaireFiles(questionnaireId);

  useEffect(() => {
    if (questionnaire?.answer_upload?.file) {
      answerFileUpload.clear();
      supportingFilesUpload.clear();
      setAnswerFile(questionnaire.answer_upload.file);
      setSupportingFiles(getSupportingFiles(questionnaire));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire?.answer_upload?.file, questionnaire]);

  if (!questionnaire) {
    return null;
  }

  const uploadFiles = async () => {
    if (!answerFile || isSubmitted) {
      return;
    }

    try {
      const [answerFileUploaded, supportingFilesUploaded] = await Promise.all([
        answerFileUpload.upload(),
        supportingFilesUpload.upload(),
      ]);

      if (answerFileUploaded.error || supportingFilesUploaded.errors.length > 0) {
        throw toError(answerFileUploaded.error || supportingFilesUploaded.errors);
      }

      await associateQuestionnaireFiles(answerFileUploaded.file, supportingFilesUploaded.files);
    } catch (error) {
      errorToast(t('errorMessages.uploadFailed', { entity: t('entities.questionnaire') }));
      datadogLogs.logger.error(
        'Failed uploading questionnaire files',
        { questionnaireId },
        toError(error),
      );
      return;
    }

    try {
      await submitVendorQuestionnaire({ vendorQuestionnaireId: questionnaireId }).unwrap();
      successToast(t('vendors.questionnaires.toasts.submitSuccess'));
    } catch (error) {
      errorToast(t('vendors.questionnaires.toasts.submitFailed'));
      datadogLogs.logger.error(
        'Error while submitting vendor questionnaire file upload',
        { questionnaireId },
        toError(error),
      );
    }
  };

  return (
    <Stack spacing={6} h="full" justifyContent="space-between">
      <Stack spacing={6}>
        <FormControl w="full" isReadOnly={isAnswerUploaded}>
          <FormLabel mb={2}>
            {t('vendors.questionnaires.answerFilesModal.answerFileLabel')}
          </FormLabel>
          <FileUpload {...answerFileUpload.props}>
            <FileUpload.Dropzone />
          </FileUpload>
        </FormControl>

        <FormControl w="full" isReadOnly={isAnswerUploaded}>
          <FormLabel mb={2}>
            {t('vendors.questionnaires.answerFilesModal.supportingFilesLabel')}
          </FormLabel>
          <FileUpload {...supportingFilesUpload.props}>
            <FileUpload.Dropzone />
          </FileUpload>
        </FormControl>
      </Stack>

      <Stack alignItems="end">
        {!isAnswerUploaded && (
          <Button
            w="fit-content"
            isLoading={isLoading}
            isDisabled={!answerFile}
            colorScheme="blue"
            onClick={() =>
              openDialog({
                dialogHeader: t('vendors.questionnaires.submitAlert.header'),
                dialogContent: t('vendors.questionnaires.submitAlert.content'),
                confirmAction: {
                  colorScheme: 'blue',
                  children: t('buttons.submit'),
                  onClick: uploadFiles,
                },
              })
            }
          >
            {t('buttons.submit')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

function getSupportingFiles(questionnaire: VendorQuestionnaire) {
  return (
    questionnaire?.uploads
      .filter((upload) => upload.id !== questionnaire.answer_upload?.id && upload.file)
      .map((upload) => upload.file) ?? []
  );
}

function useAssociateQuestionnaireFiles(questionnaireId: string) {
  const [associate, { isLoading: isQuestionnairesAssociating }] =
    useInsertVendorQuestionnaireAnswersMutation();
  const [setAnswer, { isLoading: isAnswerSetting }] = useSetVendorQuestionnaireAnswerMutation();
  const isLoading = isQuestionnairesAssociating || isAnswerSetting;

  async function associateQuestionnaireFiles(
    answerFile: UploadedFile,
    supportingFiles?: UploadedFile[],
  ) {
    const files: Vendor_Questionnaire_Uploads_Insert_Input[] = [
      answerFile,
      ...(supportingFiles ?? []),
    ].map((file) => ({
      vendor_questionnaire_id: questionnaireId,
      file_id: file.id,
    }));

    const { insert_vendor_questionnaire_uploads } = await associate({ file: files }).unwrap();

    const answerId = insert_vendor_questionnaire_uploads?.returning.find(
      (upload) => upload.file_id === answerFile.id,
    )?.id;

    if (!answerId) {
      throw new Error('Failed to set questionnaire answer');
    }

    await setAnswer({
      answerId,
      questionnaireId,
    }).unwrap();
  }

  return {
    associateQuestionnaireFiles,
    isLoading,
  };
}
