import { NhostClient } from '@nhost/nhost-js';

export const nhost = new NhostClient({
  authUrl: import.meta.env['VITE_NHOST_AUTH_URL'] || '',
  functionsUrl: ' ',
  graphqlUrl: import.meta.env['VITE_NHOST_GRAPHQL_URL'] || '',
  storageUrl: import.meta.env['VITE_NHOST_STORAGE_URL'] || '',
  /**
   * access token is valid for 15 mins. and default time to rotate token is 30 secs before expiry and the interval is every min. for verification
   * changing it to 12 mins and it may solve the random expired token error because of inactive browser tab and browser throttling timers during this time
   *  */
  refreshIntervalTime: 60 * 12,
  clientStorageType: 'custom',
  clientStorage: {
    getItem: sessionStorage.getItem.bind(sessionStorage),
    setItem: sessionStorage.setItem.bind(sessionStorage),
    removeItem: sessionStorage.removeItem.bind(sessionStorage),
  },
  // * Nhost client uses BroadcastChannel to send the token to other tabs when `autoSignIn` is activated
  autoSignIn: false,
});
