import { SVGProps } from 'react';

export const DragHandleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M3.60039 2.15C3.07572 2.15 2.65039 1.72467 2.65039 1.2C2.65039 0.675329 3.07572 0.25 3.60039 0.25C4.12506 0.25 4.55039 0.675329 4.55039 1.2C4.55039 1.72467 4.12506 2.15 3.60039 2.15ZM3.60039 6.95C3.07572 6.95 2.65039 6.52467 2.65039 6C2.65039 5.47533 3.07572 5.05 3.60039 5.05C4.12506 5.05 4.55039 5.47533 4.55039 6C4.55039 6.52467 4.12506 6.95 3.60039 6.95ZM3.60039 11.75C3.07572 11.75 2.65039 11.3247 2.65039 10.8C2.65039 10.2753 3.07572 9.85 3.60039 9.85C4.12506 9.85 4.55039 10.2753 4.55039 10.8C4.55039 11.3247 4.12506 11.75 3.60039 11.75ZM8.40039 2.15C7.87572 2.15 7.45039 1.72467 7.45039 1.2C7.45039 0.675329 7.87572 0.25 8.40039 0.25C8.92506 0.25 9.35039 0.675329 9.35039 1.2C9.35039 1.72467 8.92506 2.15 8.40039 2.15ZM8.40039 6.95C7.87572 6.95 7.45039 6.52467 7.45039 6C7.45039 5.47533 7.87572 5.05 8.40039 5.05C8.92506 5.05 9.35039 5.47533 9.35039 6C9.35039 6.52467 8.92506 6.95 8.40039 6.95ZM8.40039 11.75C7.87572 11.75 7.45039 11.3247 7.45039 10.8C7.45039 10.2753 7.87572 9.85 8.40039 9.85C8.92506 9.85 9.35039 10.2753 9.35039 10.8C9.35039 11.3247 8.92506 11.75 8.40039 11.75Z"
      strokeWidth="0.5"
    />
  </svg>
);
