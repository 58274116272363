import { defineStyleConfig } from '@chakra-ui/react';

import { createColorModePicker } from '../theme/utils';

export const StatusTag = defineStyleConfig({
  baseStyle: (props) => {
    const { colorScheme: c } = props;
    const mode = createColorModePicker(props.colorMode);

    return {
      borderRadius: 'full',
      backgroundColor: mode(c === 'gray' ? `${c}.500` : `${c}.800`, `${c}.200`),
      opacity: 0.5,
    };
  },

  sizes: {
    xs: {
      minWidth: 1.5,
      minHeight: 1.5,
      marginRight: 1.5,
    },
    sm: {
      minWidth: 2,
      minHeight: 2,
      marginRight: 1.5,
    },
    md: {
      minWidth: 2,
      minHeight: 2,
      marginRight: 1.5,
    },
    lg: {
      minWidth: 3,
      minHeight: 3,
      marginRight: 2,
    },
  },

  defaultProps: {
    colorScheme: 'gray',
    size: 'md',
  },
});
