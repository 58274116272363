import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Text,
  useColorModeValue,
  useFormControl,
} from '@chakra-ui/react';
import {
  ArrowDownTrayIcon,
  ArrowUpOnSquareIcon,
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { format as bytesFormat } from 'bytes';

type UploadFileComponentProps = {
  file: {
    name?: string;
    size?: number;
  };
  isLoading?: boolean;
  isUploaded?: boolean;
  error?: string;
  onDelete?: () => void;
  onDownload?: () => void;
};

export const UploadFile: React.FC<UploadFileComponentProps> = ({
  file,
  isLoading,
  isUploaded,
  error,
  onDelete,
  onDownload,
}) => {
  const { readOnly } = useFormControl({});
  const attachmentColor = useColorModeValue('gray.100', 'gray.600');
  const attachmentNameColor = useColorModeValue('gray.800', 'gray.300');
  const bgColor = useColorModeValue('white', 'gray.700');

  return (
    <Flex
      height="72px"
      borderRadius="8px"
      overflow="hidden"
      data-testid="file-stack"
      aria-busy={isLoading}
      border="1px"
      borderColor={attachmentColor}
      w="346px"
      mt={2}
      mr={2}
    >
      <Center minW="72px" background={attachmentColor}>
        {isLoading ? (
          <Icon boxSize={6} as={Spinner} />
        ) : isUploaded ? (
          <Icon boxSize={6} as={CheckCircleIcon} />
        ) : error ? (
          <Icon boxSize={6} as={XCircleIcon} />
        ) : (
          <Icon boxSize={6} as={ArrowUpOnSquareIcon} />
        )}
      </Center>
      <Flex
        w="full"
        px="16px"
        borderRightRadius={8}
        justifyContent="space-between"
        alignItems="center"
        bg={bgColor}
        overflow="hidden"
      >
        <Box overflow="hidden">
          <Text noOfLines={1} color={attachmentNameColor} fontSize="sm">
            {file.name}
          </Text>
          <Box fontSize="xs">
            {error ? (
              <Text color="red.500">{error}</Text>
            ) : (
              <Text color="gray.500">{bytesFormat(file.size || 0, { unitSeparator: ' ' })}</Text>
            )}
          </Box>
        </Box>

        <Center>
          {onDownload && (
            <IconButton
              variant="ghost"
              size="sm"
              aria-label="Download file"
              onClick={onDownload}
              icon={<Icon boxSize={5} as={ArrowDownTrayIcon} />}
              disabled={isLoading}
            />
          )}
          {onDelete && !readOnly && (
            <IconButton
              variant="ghost"
              size="sm"
              onClick={onDelete}
              aria-label="Delete file"
              icon={<Icon boxSize={5} as={XMarkIcon} />}
              disabled={isLoading}
            />
          )}
        </Center>
      </Flex>
    </Flex>
  );
};
