import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { EmptyPlaceholder } from './empty-placeholder';

export const NoPermissionPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon as={LockClosedIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>
          {t('errorMessages.noPermissionHeading')}
        </EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>
          {t('errorMessages.noPermissionSubheading')}
        </EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
};
