import { datadogLogs } from '@datadog/browser-logs';
import posthog from 'posthog-js';

import { APP_ENV } from './app-env';

export function initDatadogLogger({
  clientToken,
  site,
  appEnv,
  version,
  isProdBuild,
  service,
}: {
  clientToken: string;
  site: string;
  appEnv: APP_ENV;
  version: string;
  isProdBuild: boolean;
  service: 'app' | 'backoffice' | 'vendor-portal';
}) {
  datadogLogs.logger.setHandler(isProdBuild ? 'http' : 'console');

  datadogLogs.init({
    clientToken,
    site,
    env: appEnv,
    service,
    version,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend: (event) => {
      event.view.url = event.view.url.replace(/token=[^&]*/, 'token=REDACTED');

      if (appEnv.includes('prod')) {
        event.view['posthog_session'] = posthog
          .get_session_replay_url({ withTimestamp: true })
          .replace('e.complyance.com', 'eu.posthog.com');
      }

      if (event.http && event.http.status_code === 404) {
        return false;
      }

      return true;
    },
  });
}
