import { Box, BoxProps, forwardRef, Portal } from '@chakra-ui/react';
import {
  autoUpdate,
  ElementProps,
  FloatingContext,
  FloatingFocusManager,
  FloatingOverlay,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import {
  Children,
  cloneElement,
  createContext,
  HTMLProps,
  ReactElement,
  ReactNode,
  Ref,
  useContext,
  useMemo,
  useState,
} from 'react';

export function EditablePopover({
  children,
  onClose,
  defaultIsEditing = false,
}: {
  children: ReactNode;
  onClose?: () => void;
  defaultIsEditing?: boolean;
}) {
  const popover = useEditablePopover(onClose, defaultIsEditing);
  return (
    <EditablePopoverContext.Provider value={popover}>{children}</EditablePopoverContext.Provider>
  );
}

function EditablePopoverTrigger({ children }: { children: ReactElement }) {
  const context = useEditablePopoverContext();
  const child = Children.only(children);
  const childRef = (child as ReactElement & { ref: Ref<unknown> }).ref;
  const ref = useMergeRefs([context.refs.setReference, childRef]);
  return cloneElement(
    child,
    context.getReferenceProps({
      ...(child.props as object),
      ref,
      /* This stops triggering click event on cell itself when clicking on the overflow edit button */
      onClick(event) {
        event.stopPropagation();
      },
    }),
  );
}
EditablePopover.Trigger = EditablePopoverTrigger;

function EditablePopoverReference({ children }: { children: ReactElement }) {
  const context = useEditablePopoverContext();
  const child = Children.only(children);
  const childRef = (child as ReactElement & { ref: Ref<unknown> }).ref;
  const ref = useMergeRefs([context.refs.setPositionReference, childRef]);
  return cloneElement(child, { ...(child.props as object), ref });
}
EditablePopover.Reference = EditablePopoverReference;

type EditablePopoverContentProps = Omit<BoxProps, 'children'> & {
  children: ReactNode | ((context: ContextType) => ReactNode);
};

const EditablePopoverContent = forwardRef<EditablePopoverContentProps, 'div'>(
  ({ children, ...props }, propRef) => {
    const context = useEditablePopoverContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.isOpen) {
      return null;
    }

    return (
      <Portal>
        <FloatingOverlay lockScroll>
          <FloatingFocusManager context={context.context} initialFocus={-1} visuallyHiddenDismiss>
            <Box
              ref={ref}
              position={context.strategy}
              top={context.y ? context.y + 1 : 0}
              left={context.x ?? 0}
              width="max-content"
              minWidth="sm"
              {...context.getFloatingProps(props as HTMLProps<HTMLElement>)}
            >
              {typeof children === 'function' ? children(context) : children}
            </Box>
          </FloatingFocusManager>
        </FloatingOverlay>
      </Portal>
    );
  },
);
EditablePopover.Content = EditablePopoverContent;

function useEditablePopover(onClose?: () => void, defaultIsEditing?: boolean) {
  const [isOpen, setIsOpen] = useState(defaultIsEditing ?? false);

  const floating = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: (isOpen) => {
      if (!isOpen && onClose) {
        onClose();
      }

      setIsOpen(isOpen);
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
      offset(({ rects }) => -rects.reference.height),
      shift({
        mainAxis: true,
        crossAxis: true,
        padding: 16,
      }),
    ],
  });

  const { context } = floating;

  const click = useClick(context, {
    enabled: !isOpen,
  });
  const dismiss = useDismiss(context);
  const dismissOnBlur = useDismissOnBlur(context);
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, dismissOnBlur, role]);

  return useMemo(
    () => ({
      isOpen,
      ...floating,
      ...interactions,
    }),
    [isOpen, floating, interactions],
  );
}

function useDismissOnBlur({ onOpenChange }: FloatingContext) {
  return useMemo(
    (): ElementProps => ({
      floating: {
        onBlur: () => {
          onOpenChange(false);
        },
      },
    }),
    [onOpenChange],
  );
}

type ContextType = ReturnType<typeof useEditablePopover>;

const EditablePopoverContext = createContext<ContextType | null>(null);

export function useEditablePopoverContext() {
  const context = useContext(EditablePopoverContext);

  if (!context) {
    throw new Error('EditablePopover components must be wrapped in <EditablePopover />');
  }

  return context;
}
