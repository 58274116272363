import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect, useMemo, useRef } from 'react';

import { DrawerService } from './drawer-service';
import { DrawerCallbackData, DrawerObserver } from './types';

export function useDrawer() {
  const navigate = useNavigate();
  const search = useSearch({ from: '' as never });
  const observer = useRef<DrawerObserver>();

  const service = useMemo(
    () =>
      new DrawerService({
        search: (params) => navigate({ search: (oldParams) => ({ ...oldParams, ...params }) }),
        searchReplace: (params) =>
          navigate({ search: (oldParams) => ({ ...oldParams, ...params }), replace: true }),
        getDrawerObserver: (_observer) => (observer.current = _observer),
      }),
    [navigate],
  );

  useEffect(() => {
    const ns = service.getNamespace();
    const data: DrawerCallbackData = {
      entity: search[ns.entity],
      entityId: search[ns.entityId],
    };
    observer.current?.(data.entity ? data : undefined);
  }, [search, service]);

  return service;
}
