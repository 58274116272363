import { Button, ButtonProps, forwardRef, IconButton, MenuButton, Text } from '@chakra-ui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { MouseEvent } from 'react';

export type TableMenuButtonProps = ButtonProps & {
  onRemoveClick?(): void;
};

export function TableMenuButton({ rightIcon, onRemoveClick, ...props }: TableMenuButtonProps) {
  return (
    <MenuButton
      rightIcon={
        onRemoveClick && (
          <RemoveIcon
            onClick={(event) => {
              event.preventDefault();
              onRemoveClick();
            }}
          />
        )
      }
      {...props}
      as={TableButton}
    />
  );
}

const TableButton = forwardRef<ButtonProps, 'button'>(({ children, ...props }, ref) => {
  return (
    <Button ref={ref} {...props} variant="outline" colorScheme="gray">
      <Text fontSize="sm" fontWeight="medium" isTruncated>
        {children}
      </Text>
    </Button>
  );
});

function RemoveIcon({ onClick }: { onClick: (event: MouseEvent<HTMLButtonElement>) => void }) {
  return (
    <IconButton
      minW={4}
      h={4}
      role="button"
      aria-label="Remove filter"
      icon={<XMarkIcon />}
      borderRadius="sm"
      variant="unstyled"
      _hover={{ bgColor: 'gray.300' }}
      _dark={{ _hover: { bgColor: 'whiteAlpha.300' } }}
      onClick={onClick}
      as="span"
    />
  );
}
