import { z } from 'zod';

export const VendorQuestionnaireTabKeys = ['details', 'files', 'form'] as const;

export type VendorQuestionnaireTabKeys = (typeof VendorQuestionnaireTabKeys)[number];

export const vendorDrawerSchema = z.object({
  drawerEntity: z
    .union([
      z.literal('vendor-questionnaire'),
      // Need `never` because union requires at least two values
      // Remove it when we add more values
      z.never(),
    ])
    .optional()
    .catch('vendor-questionnaire'),
  activeTab: z
    .union([
      z.enum(VendorQuestionnaireTabKeys),
      // Need `never` because union requires at least two values
      // Remove it when we add more values
      z.never(),
    ])
    .optional()
    .catch('details'),
  drawerEntityId: z.string().optional(),
});

export type VendorDrawerType = z.infer<typeof vendorDrawerSchema>;
