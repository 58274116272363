import { Input, InputProps, useFormControl, Wrap } from '@chakra-ui/react';
import { FileItemRef } from '@nhost/react';
import { createContext, ForwardedRef, forwardRef, ReactNode, useContext, useState } from 'react';

import { Dropzone } from './dropzone';
import { NhostFileUploader } from './nhost-upload-files';
import { UploadedFile } from './shared';
import { UploadFile } from './upload-files';

export type FileInputProps<T extends UploadedFile = UploadedFile> = FileInputContext<T> & {
  children: ReactNode;
};

export type FileInputContext<T extends UploadedFile = UploadedFile> = {
  isMulti?: boolean;
  uploadedFiles?: T[];
  onUploadedFileDownload?: (fileId: string) => void;
  onUploadedFileDelete?: (file: T) => Promise<void>;
  stagedFiles?: FileItemRef[];
  onStagedFilesAdd?: (files: File[]) => void;
  onStagedFileDelete?: (fileId: string, file: File) => void;
};

/**
 * File list + upload dropzone.
 *
 * Props should not be provided directly, and instead one of the following
 * supportive hooks should be used:
 * - useEagerFileUpload
 * - useEagerMultipleFilesUpload
 * - useLazyFileUpload
 * - useLazyMultipleFilesUpload
 *
 * @example
 * const fileUpload = useEagerFileUpload({ ... });
 *
 * <FileUpload {...fileUpload}>
 *   <FileUpload.Dropzone {...} />
 * </FileUpload>
 */
export const FileUpload = <T extends UploadedFile>({ children, ...context }: FileInputProps<T>) => {
  const {
    uploadedFiles = [],
    onUploadedFileDownload,
    onUploadedFileDelete,
    stagedFiles = [],
    onStagedFileDelete,
  } = context;

  const [loadingFileIds, setLoadingFileIds] = useState<string[]>([]);

  return (
    <Context.Provider value={context as FileInputContext}>
      <Wrap h="full" gap={3}>
        {uploadedFiles.map((file) => {
          return (
            <UploadFile
              key={file.id}
              file={file}
              isUploaded
              isLoading={loadingFileIds.includes(file.id)}
              onDownload={
                onUploadedFileDownload &&
                (() => {
                  onUploadedFileDownload?.(file.id);
                })
              }
              onDelete={
                onUploadedFileDelete &&
                (async () => {
                  setLoadingFileIds((prevDeletingFileIds) => [...prevDeletingFileIds, file.id]);
                  try {
                    await onUploadedFileDelete?.(file);
                  } finally {
                    setLoadingFileIds((prevDeletingFileIds) =>
                      prevDeletingFileIds.filter((fileId) => fileId !== file.id),
                    );
                  }
                })
              }
            />
          );
        })}

        {stagedFiles.map((file) => {
          return <NhostFileUploader key={file.id} fileRef={file} onDelete={onStagedFileDelete} />;
        })}
        {children}
      </Wrap>
    </Context.Provider>
  );
};

type FileUploadInputProps = Omit<InputProps, 'multiple' | 'onChange' | 'type'>;
const FileUploadInput = forwardRef(
  (props: FileUploadInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { uploadedFiles, stagedFiles, isMulti, onStagedFilesAdd } = useFileInputContext();
    const { readOnly } = useFormControl({});

    if (readOnly || (!isMulti && (uploadedFiles?.length || stagedFiles?.length))) {
      return null;
    }

    return (
      <Input
        {...props}
        ref={ref}
        type="file"
        multiple={isMulti}
        onChange={(event) => {
          if (!event.target.files) {
            return;
          }
          onStagedFilesAdd?.(Array.from(event.target.files));
          event.target.value = '';
        }}
      />
    );
  },
);
FileUpload.Input = FileUploadInput;

type FileInputDropzoneProps = {
  topLabelText?: string;
  bottomLabelText?: string;
  onDragLeave?: () => void;
};
const FileInputDropzone = ({
  topLabelText,
  bottomLabelText,
  onDragLeave,
}: FileInputDropzoneProps) => {
  const { uploadedFiles, stagedFiles, isMulti, onStagedFilesAdd } = useFileInputContext();
  const { readOnly } = useFormControl({});

  if (readOnly || (!isMulti && (uploadedFiles?.length || stagedFiles?.length))) {
    return null;
  }

  return (
    <Dropzone
      isMulti={isMulti}
      topLabelText={topLabelText}
      bottomLabelText={bottomLabelText}
      onChange={(files) => {
        onStagedFilesAdd?.(files);
      }}
      onDragLeave={onDragLeave}
    />
  );
};
FileUpload.Dropzone = FileInputDropzone;

const Context = createContext<FileInputContext | null>(null);

function useFileInputContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('FileUpload components must be wrapped in <FileUpload />');
  }

  return context;
}
