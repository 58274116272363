import { Box, StackDivider, TableContainer, useColorModeValue, VStack } from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useStableCallback } from '@main/shared/utils';
import { ColumnSizingState, Header, Row, Table } from '@tanstack/react-table';
import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';

import { shouldDisplayPagination, TablePagination } from './pagination';
import { TableItem, TableRowReorderHandler } from './table';
import { TableActionHandler } from './table-action';
import { TableColumnHeader } from './table-column-header';
import { TableRow } from './table-row';

export interface TableFilterResultProps<T> {
  itemName: TableItem;
  table: Table<T>;
  minColumnSize: number;
  actionHandler: TableActionHandler<T>;
  onRowClick?(row: Row<T>): void;
  setColumnSizing: Dispatch<SetStateAction<ColumnSizingState>>;
  onRowReorder?: TableRowReorderHandler<T>;
}

export function TableFilterResult<T>({
  itemName,
  table,
  minColumnSize,
  actionHandler,
  onRowClick,
  setColumnSizing,
  onRowReorder,
}: TableFilterResultProps<T>) {
  const theadColor = useColorModeValue('gray.500', 'gray.400');
  const bgColor = useColorModeValue(`gray.25`, `gray.700`);
  const borderColor = useColorModeValue(`gray.200`, `gray.600`);
  const resizeHandleColor = useColorModeValue('blue.500', 'blue.300');
  const headers = table.getFlatHeaders();
  const centerHeaders = table.getCenterFlatHeaders();
  const leftHeaders = table.getLeftFlatHeaders();
  const rightHeaders = table.getRightFlatHeaders();
  const rows = table.getRowModel().rows;
  const rowSelection = table.getState().rowSelection;
  const columnSizingInfo = table.getState().columnSizingInfo;
  const columnSizing = table.getState().columnSizing;
  const isSizingApplied = useMemo(() => Object.keys(columnSizing).length > 0, [columnSizing]);
  const isReorderable = !!onRowReorder;
  const hasLeftColumns = leftHeaders.length > 0;
  const hasRightColumns = rightHeaders.length > 0;
  const totalColumns = headers.length + 2 + (hasLeftColumns ? 1 : 0);

  const rowStyles = useMemo(
    () => ({
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'subgrid',
      gridColumn: `1/${totalColumns}`,
      '+ .why-table--row .why-table--cell': {
        borderTop: '1px',
        borderTopColor: `${borderColor} !important`,
      },
      ':after': {
        content: '""',
        position: 'absolute',
        display: 'none',
        pointerEvents: 'none',
        inset: '0',
        border: '2px',
        borderColor: 'blue.200',
      },
      '.without-pagination &:last-of-type:after': {
        borderBottomLeftRadius: 'var(--card-radius)',
        borderBottomRightRadius: 'var(--card-radius)',
      },
      '&.why-table--row-selected:after': {
        display: 'block',
      },
      '&:nth-of-type(even)': {
        '.why-table--cell': {
          backgroundColor: bgColor,
        },
      },
    }),
    [bgColor, borderColor, totalColumns],
  );

  const theadStyles = useMemo(
    () => ({
      position: 'relative',
      transition: 'background-color 0.2s ease-out',
      color: theadColor,
      backgroundColor: bgColor,
      borderColor: `${borderColor} !important`,
      borderBottom: '1px',
      fontWeight: 'medium',
      fontSize: 'xs',
      lineHeight: '4',
      textTransform: 'none',
      letterSpacing: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      px: 4,
      py: 3,
      '&:hover .why-table--resize-handle': {
        backgroundColor: resizeHandleColor,
      },
    }),
    [bgColor, borderColor, resizeHandleColor, theadColor],
  );

  const headerToColumn = useCallback(
    (header: Header<T, unknown>) => {
      if (header.column.getIsResizing() || header.column.id in columnSizing) {
        return intToGridUnit(header.column.getSize());
      }

      const unit = header.column.columnDef.meta?.sizeUnit ?? 'px';

      if (header.column.columnDef.size !== undefined) {
        return intToGridUnit(header.column.columnDef.size, unit);
      }

      if (
        header.column.columnDef.minSize !== undefined ||
        header.column.columnDef.maxSize !== undefined
      ) {
        return `minmax(${intToGridUnit(header.column.columnDef.minSize, unit)}, ${intToGridUnit(
          header.column.columnDef.maxSize,
          unit,
        )})`;
      }

      return `minmax(${intToGridUnit(minColumnSize, unit)}, auto)`;
    },
    [columnSizing, minColumnSize],
  );

  const leftColumns = useMemo(
    () => leftHeaders.map(headerToColumn).join(' '),
    [leftHeaders, headerToColumn],
  );
  const centerColumns = useMemo(
    () => centerHeaders.map(headerToColumn).join(' '),
    [centerHeaders, headerToColumn],
  );
  const rightColumns = useMemo(
    () => rightHeaders.map(headerToColumn).join(' '),
    [rightHeaders, headerToColumn],
  );
  const autoColumn = isSizingApplied ? 'auto' : '0fr';

  const gridTemplateColumns = useMemo(() => {
    let template = '';

    if (leftColumns) {
      template += `${leftColumns} ${autoColumn} `;
    }

    template += `${centerColumns} ${autoColumn}`;

    if (rightColumns) {
      template += ` ${rightColumns}`;
    }

    return template;
  }, [centerColumns, leftColumns, rightColumns, autoColumn]);

  const headerRefs = useRef<Record<string, HTMLElement>>({});
  const getHeaderRef = useStableCallback((id: string) => {
    return (element: HTMLElement | null) => {
      if (element) {
        headerRefs.current[id] = element;
      } else {
        delete headerRefs.current[id];
      }
    };
  });

  const updateColumnSizing = useStableCallback(async () => {
    setColumnSizing(
      Object.fromEntries(
        Object.entries(headerRefs.current).map(([id, el]) => [
          id,
          el.getBoundingClientRect().width,
        ]),
      ),
    );
  });

  const handleDragEnd = useStableCallback((event: DragEndEvent) => {
    if (!onRowReorder || !event.over || event.active.id === event.over.id) {
      return;
    }

    const fromRow = table.getRow(event.active.id as string);
    const toRow = table.getRow(event.over.id as string);

    onRowReorder(fromRow, toRow, table);
  });

  const autoHeader = useMemo(
    () => <Box className="why-table--header why-table--autocol" __css={theadStyles} p="0" />,
    [theadStyles],
  );
  const renderHeader = useCallback(
    (header: Header<T, unknown>) => {
      return (
        <TableColumnHeader
          key={header.id}
          ref={getHeaderRef(header.id)}
          header={header}
          resizeHandleColor={resizeHandleColor}
          styles={theadStyles}
          updateColumnSizing={updateColumnSizing}
        />
      );
    },
    [getHeaderRef, resizeHandleColor, theadStyles, updateColumnSizing],
  );
  const tHead = useMemo(
    () => (
      <Box
        role="row"
        className="why-table--row"
        userSelect={columnSizingInfo.isResizingColumn ? 'none' : undefined}
        __css={rowStyles}
      >
        {leftHeaders.map(renderHeader)}
        {hasLeftColumns && autoHeader}
        {centerHeaders.map(renderHeader)}
        {rightHeaders.map(renderHeader)}
        {!hasRightColumns && autoHeader}
      </Box>
    ),
    [
      columnSizingInfo.isResizingColumn,
      rowStyles,
      hasLeftColumns,
      hasRightColumns,
      autoHeader,
      leftHeaders,
      centerHeaders,
      rightHeaders,
      renderHeader,
    ],
  );

  const onRowClickCb = useStableCallback(onRowClick);
  const actionHandlerCb = useStableCallback(actionHandler);
  const tBody = useMemo(
    () =>
      rows.map((row) => (
        <TableRow
          key={row.id}
          row={row}
          itemName={itemName}
          borderColor={borderColor}
          rowStyles={rowStyles}
          isReorderable={isReorderable}
          actionHandler={actionHandlerCb}
          onRowClick={onRowClickCb}
        />
      )),
    // HACK: Need `headers` and `rowSelection` to re-render when columns or row selection change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rows,
      itemName,
      borderColor,
      rowStyles,
      isReorderable,
      onRowClickCb,
      actionHandlerCb,
      headers,
      rowSelection,
    ],
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {}),
      )}
    >
      <VStack align="normal" spacing={0} divider={<StackDivider />}>
        <TableContainer>
          <Box
            role="table"
            aria-label={itemName.plural}
            display="grid"
            gridTemplateColumns={gridTemplateColumns}
            placeItems="stretch"
            w="full"
          >
            <Box display="contents" role="rowgroup">
              {tHead}
            </Box>
            <Box display="contents" role="rowgroup">
              <SortableContext items={rows} strategy={verticalListSortingStrategy}>
                {tBody}
              </SortableContext>
            </Box>
          </Box>
        </TableContainer>
        {shouldDisplayPagination(table) && <TablePagination table={table} />}
      </VStack>
    </DndContext>
  );
}

function intToGridUnit(value?: number, unit = 'px') {
  return value !== undefined ? `${value}${unit}` : 'auto';
}
