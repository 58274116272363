// Chakra-UI color schema
export const COLORS = ['gray', 'red', 'orange', 'green', 'teal', 'cyan', 'purple', 'pink'];

const hashCode = (str: string) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getHashedColor = (name?: string) => {
  if (!name) return;

  const hash = hashCode(name);
  const colorIndex = Math.abs(hash % COLORS.length);
  return COLORS[colorIndex];
};

export const getHashedAvatarColor = (name?: string) => {
  if (!name) return;

  const hash = hashCode(name);
  const colorIndex = Math.abs(hash % COLORS.length);
  return {
    bg: `${COLORS[colorIndex]}.100`,
    color: `${COLORS[colorIndex]}.900`,
  };
};
