import { type Colors, extendTheme, StyleFunctionProps, ThemeOverride } from '@chakra-ui/react';

import { StatusTag } from '../status-tag/theme';
import { Table } from '../table/theme';
import { CardTheme } from './card';
import { TagTheme } from './tag';
import { Text } from './text';

const colors: Colors = {
  blue: {
    '25': '#F6F4FE',
    '50': '#ede9fd',
    '100': '#dbd3fb',
    '200': '#b7a6f7',
    '300': '#937af4',
    '400': '#6f4df0',
    '500': '#4b21ec',
    '600': '#3c1abd',
    '700': '#2d148e',
    '800': '#1e0d5e',
    '900': '#0f072f',
  },
  gray: {
    '25': '#FCFCFD',
    '50': '#FAFCFD',
    '100': '#EDF2F7',
    '200': '#E2E8F0',
    '300': '#CBD5E0',
    '400': '#A0AEC0',
    '500': '#718096',
    '600': '#4A5568',
    '700': '#2D3748',
    '800': '#1A202C',
    '900': '#171923',
  },
  purple: {
    '100': '#EDE9FD',
    '800': '#6F4DF0',
  },
};

const config: ThemeOverride = {
  components: {
    Button: {
      variants: {
        outline: (props: StyleFunctionProps) => ({
          _active: {
            bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.50',
          },
        }),
      },
    },
    StatusTag,
    Table,
    Text,
    Tag: TagTheme,
    Card: CardTheme,
    Input: {
      defaultProps: {
        errorBorderColor: 'red.300',
      },
    },
    Textarea: {
      defaultProps: {
        errorBorderColor: 'red.300',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'xs',
        fontWeight: 'semibold',
      },
      variants: {
        default: {
          fontSize: 'md',
          fontWeight: 'medium',
        },
      },
    },
    Divider: {
      defaultProps: {
        size: 'sm',
        borderColor: 'gray.200',
      },
      sizes: {
        lg: { borderWidth: '4px' },
        md: { borderWidth: '2px' },
        sm: { borderWidth: '1px' },
      },
    },
  },
  colors,
  fonts: {
    heading: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  initialColorMode: 'dark',
  useSystemColorMode: true,
  styles: {
    global: {
      // default width and height for svg icons to 1em, to fix issue in safari
      svg: {
        minWidth: '1em',
        minHeight: '1em',
      },
    },
  },
};

export const theme = extendTheme(config);
