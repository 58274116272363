import { PieArcDatum } from '@visx/shape/lib/shapes/Pie';

import { PieDatum } from './pie';

export function getPieLabel(d: PieDatum) {
  return d.label;
}

export function getPieValue(d: PieDatum) {
  return d.value;
}

export function getPieColor(d: PieDatum) {
  return d.color;
}

export function getPieArcColor(d: PieArcDatum<PieDatum>) {
  return `var(--chakra-colors-${getPieColor(d.data)}-100)`;
}

export function itemWithIndex<T>(item: T, idx: number) {
  return { ...item, idx };
}

export function indexComparator<T extends { idx: number }>(a: T, b: T) {
  return b.idx - a.idx;
}

export function getPadAngle(data: PieDatum[]) {
  const total = data.reduce((acc, d) => acc + d.value, 0);
  const allDataBelongsToOnePieSection = data.find((d) => d.value === total);

  return allDataBelongsToOnePieSection ? 0 : 0.02;
}
