import {
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Image,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useWithState, WithState } from '@main/shared/utils';
import { Link } from '@tanstack/react-router';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapseIcon } from '../../icons/collapse';
import { ExpandIcon } from '../../icons/exand';
import { useDrawer, useDrawerAnimation } from '../drawer';

export interface PrimaryLayoutProps {
  sidebar?: WithState<React.ReactNode, PrimaryLayoutState & { isInDrawer?: boolean }>;
  header?: WithState<React.ReactNode, PrimaryLayoutState & { logo: React.ReactNode }>;
  logo?: WithState<React.ReactNode, PrimaryLayoutState>;
  logoLink?: string;
  drawer?: WithState<{ actions?: React.ReactNode; content: React.ReactNode }, PrimaryLayoutState>;
  children?: WithState<React.ReactNode, PrimaryLayoutState>;
  onSidebarToggle?(isCollapsed: boolean): void;
}

export interface PrimaryLayoutState {
  isSidebarOpen: boolean;
  onSidebarOpen: () => void;
  onSidebarClose: () => void;
  isSidebarCollapsed: boolean;
  onSidebarCollapse: () => void;
}

export const PrimaryLayout = ({
  sidebar,
  header,
  logo,
  logoLink,
  drawer,
  children,
  onSidebarToggle,
}: PrimaryLayoutProps) => {
  const { isOpen: isSidebarOpen, onOpen: onSidebarOpen, onClose: onSidebarClose } = useDisclosure();
  const { isOpen: isSidebarCollapsed, onToggle: onSidebarCollapse } = useDisclosure({
    defaultIsOpen: localStorage.getItem('sidebar-collapsed') === 'true',
    onOpen: () => localStorage.setItem('sidebar-collapsed', 'true'),
    onClose: () => localStorage.setItem('sidebar-collapsed', 'false'),
  });
  useEffect(() => onSidebarToggle?.(isSidebarCollapsed), [isSidebarCollapsed, onSidebarToggle]);
  const drawerService = useDrawer();
  const { motionProps } = useDrawerAnimation();
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const logoSrc = useColorModeValue('/logo.svg', '/logo_dark.svg');
  const sidebarWithState = useWithState(sidebar);
  const headerWithState = useWithState(header);
  const logoWithState = useWithState(logo);
  const childrenWithState = useWithState(children);

  const state: PrimaryLayoutState = useMemo(
    () => ({
      isSidebarOpen,
      onSidebarOpen,
      onSidebarClose,
      isSidebarCollapsed,
      onSidebarCollapse,
    }),
    [isSidebarCollapsed, isSidebarOpen, onSidebarClose, onSidebarCollapse, onSidebarOpen],
  );

  const resolvedDrawer = useWithState(drawer)(state);

  const renderedLogo = (
    <Link to={(logoLink as '/') ?? '/'}>
      {logoWithState(state) ?? <Image src={logoSrc} alt="Logo" object-fit="contain" />}
    </Link>
  );

  const renderSidebar = (props?: BoxProps, isInDrawer = false) => (
    <SidebarContent
      logo={renderedLogo}
      onClose={onSidebarClose}
      onSidebarCollapse={onSidebarCollapse}
      isSidebarCollapsed={isSidebarCollapsed && !isInDrawer}
      {...props}
      zIndex="1"
    >
      {sidebarWithState({
        ...state,
        isSidebarCollapsed: isSidebarCollapsed && !isInDrawer,
        isInDrawer,
      })}
    </SidebarContent>
  );

  return (
    <Box minH="100vh" bg={bgColor}>
      {renderSidebar({ display: { base: 'none', md: 'block' } })}
      <Drawer
        autoFocus={false}
        isOpen={isSidebarOpen}
        placement="left"
        onClose={onSidebarClose}
        returnFocusOnClose={false}
        onOverlayClick={onSidebarClose}
        size="full"
      >
        <DrawerContent h="100dvh">{renderSidebar({}, true)}</DrawerContent>
      </Drawer>
      {headerWithState({ ...state, logo: renderedLogo })}
      <Box
        transition="0.7s cubic-bezier(0.22, 0.61, 0.36, 1)"
        ml={{ base: 0, md: isSidebarCollapsed ? 14 : 64 }}
        px={{ base: 4, md: 8 }}
        py={{ base: 4, md: 6 }}
      >
        {childrenWithState(state)}
      </Box>
      <Drawer
        onClose={() => drawerService.close()}
        isOpen={!!drawer}
        size={{ base: 'full', md: 'xl' }}
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent motionProps={motionProps}>{resolvedDrawer?.content}</DrawerContent>
      </Drawer>
    </Box>
  );
};

interface SidebarContentProps extends BoxProps {
  onClose: () => void;
  onSidebarCollapse: () => void;
  logo: React.ReactNode;
  isSidebarCollapsed: boolean;
}

const SidebarContent = ({
  onClose,
  onSidebarCollapse,
  logo,
  children,
  isSidebarCollapsed,
  ...rest
}: SidebarContentProps) => {
  const { t } = useTranslation('ui');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const bgColor = useColorModeValue('white', 'gray.800');
  const logoCollapsedSrc = '/logo-collapsed.svg';
  const width = isSidebarCollapsed ? '59px' : 64;
  const collapseLabel = isSidebarCollapsed ? t('sidebar.expand') : t('sidebar.collapse');

  return (
    <Box
      transition="0.7s cubic-bezier(0.22, 0.61, 0.36, 1)"
      w={{ base: 'full', md: width }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex flexDir="column" h="full" bg={bgColor} borderRight="1px" borderRightColor={borderColor}>
        <Flex
          flexShrink="1"
          height="64px"
          px="8"
          justifyContent={{ base: 'space-between' }}
          alignItems="center"
          borderBottomWidth="1px"
          borderBottomColor={borderColor}
          display={{ base: 'flex', md: 'none' }}
        >
          <Box textAlign="center" maxW={32} mt="4px">
            {logo}
          </Box>
          <CloseButton onClick={onClose} />
        </Flex>

        <Box flexGrow="1" overflowY={isSidebarCollapsed ? 'hidden' : 'auto'}>
          {children}
        </Box>

        <Flex
          flexShrink="1"
          p="3"
          justifyContent={{ base: 'space-between' }}
          alignItems="center"
          borderTopWidth="1px"
          borderBottomColor={borderColor}
          display={{ base: 'none', md: 'flex' }}
        >
          <Box
            textAlign="center"
            maxW="80px"
            ml={isSidebarCollapsed ? 0 : '3'}
            w={isSidebarCollapsed ? 0 : 'full'}
            transition="0.7s cubic-bezier(0.22, 0.61, 0.36, 1)"
          >
            {logo}
          </Box>
          <Box data-group h="32px">
            <Box
              display={isSidebarCollapsed ? 'initial' : 'none'}
              _groupHover={{ display: 'none' }}
            >
              <img src={logoCollapsedSrc} alt="Logo" width="32px" height="32px" />
            </Box>
            <Tooltip label={collapseLabel} placement="right">
              <IconButton
                display={isSidebarCollapsed ? 'none' : 'initial'}
                _groupHover={{ display: 'initial' }}
                variant="ghost"
                aria-label={collapseLabel}
                size="sm"
                icon={<Icon as={isSidebarCollapsed ? ExpandIcon : CollapseIcon} />}
                onClick={onSidebarCollapse}
              />
            </Tooltip>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
