import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  useDisclosure,
} from '@chakra-ui/react';
import {
  createContext,
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface AlertDialogConfig {
  dialogHeader: string;
  dialogContent: ReactNode;
  cancelBtnLabel?: string;
  confirmAction?: Omit<ButtonProps, 'onClick'> & {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => Promise<void> | void;
  };
}

export type AlertDialogContextType = {
  openDialog: (state: AlertDialogConfig) => void;
  closeDialog: () => void;
};

export const defaultAlertDialogContext: AlertDialogContextType = {
  openDialog: () => null,
  closeDialog: () => null,
};

interface AlertDialogState extends AlertDialogConfig {
  isLoading: boolean;
}

const defaultState: AlertDialogState = {
  isLoading: false,
  dialogHeader: '',
  dialogContent: '',
};

const AlertDialogContext = createContext<AlertDialogContextType>(defaultAlertDialogContext);

export const AlertDialogContainer = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation('ui');
  const { isOpen, onOpen, onClose } = useDisclosure();
  //chakra-ui.com/docs/components/alert-dialog/usage#usage
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [state, setState] = useState<AlertDialogState>(defaultState);

  const openDialog = useCallback(
    (newState: AlertDialogConfig) => {
      setState({ ...defaultState, ...newState });
      onOpen();
    },
    [onOpen],
  );

  const contextValue = useMemo(
    () => ({
      openDialog,
      closeDialog: onClose,
    }),
    [openDialog, onClose],
  );

  return (
    <AlertDialogContext.Provider value={contextValue}>
      {children}
      <AlertDialog
        size="xl"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered={true}
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg">{state.dialogHeader}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody whiteSpace="pre-line">{state.dialogContent}</AlertDialogBody>
            <AlertDialogFooter gap={3}>
              <Button colorScheme="gray" ref={cancelRef} onClick={onClose}>
                {state.cancelBtnLabel || t('alert.cancel')}
              </Button>
              <Button
                colorScheme="red"
                {...state.confirmAction}
                {...(state.confirmAction?.onClick
                  ? {
                      onClick: async (event) => {
                        try {
                          setState((prev) => ({ ...prev, isLoading: true }));
                          await state.confirmAction?.onClick?.(event);
                          onClose();
                        } finally {
                          setState((prev) => ({ ...prev, isLoading: false }));
                        }
                      },
                    }
                  : {})}
                isLoading={state.isLoading}
              >
                {state.confirmAction?.children || t('alert.confirm')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </AlertDialogContext.Provider>
  );
};

export const useAlertDialog = () => useContext(AlertDialogContext);
