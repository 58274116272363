import { ReactNode, useMemo } from 'react';

import { Drawer } from './drawer-layout';

export interface UseDrawerProps<TEntities extends string> {
  useSearch(): Partial<DrawerSearchParams<TEntities>>;
  drawers: DrawerContentMap<TEntities>;
  isReady?: boolean;
}

export type DrawerContentMap<TEntities extends string> = Record<
  TEntities,
  DrawerContentFn<TEntities>
>;
export type DrawerContentFn<TEntities extends string> = (
  props: DrawerSearchParams<TEntities>,
) => Readonly<{
  content: ReactNode;
}>;

export interface DrawerSearchParams<TEntities extends string> {
  drawerEntity: TEntities;
  drawerEntityId: string;
}

export function useDrawerContent<TEntities extends string>({
  drawers,
  useSearch: search,
  isReady = true,
}: UseDrawerProps<TEntities>) {
  const { drawerEntity, drawerEntityId } = search();

  const drawerContent = useMemo(() => {
    if (!drawerEntity || !isReady) {
      return;
    }

    if (!drawerEntityId) {
      return {
        content: (
          <Drawer.Layout isLoading canView isNotFound={false}>
            <Drawer.Toolbar />
            <Drawer.Tabs tabs={[]} />
          </Drawer.Layout>
        ),
      };
    }

    return drawers[drawerEntity]?.({ drawerEntity, drawerEntityId });
  }, [drawerEntity, drawerEntityId, drawers, isReady]);

  return { drawerContent, drawerEntity, drawerEntityId };
}
