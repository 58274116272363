import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { QueryCacheLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { api, GraphqlStateSlice } from './shared-api';
import { SubscriptionManager } from './ws';

/*
 * Creates a subscription api for a query.
 * As RTK is independent of underlying usage of API style be graphql or REST or anything.
 * So, it doesn't give out of the box solution for graphql subscription.
 *
 * With this implementation, the idea is following
 * - Fetch data for a graphql API using query(codegen generates types)
 * - The data is now stored in the centralized cache i.e redux store
 *
 * Lifecycle of the websocket connection
 * - This will open a websocket connection AS SOON AS the data from the query is stored in the cache and not until then. i.e when API is invoked
 * - Whenever the data for this subscription in database changes we keep getting updates and it updates the same cache.
 * - As soon as the date is removed from the cache the websocket connection closes.
 * ex: When there are no subscribers to the cache depending on refetchOnMountOrArgChange the data is removed from cache and websocket connection is closed.
 */

export const createGqlSubscriptionApi = <T extends typeof api>(
  injectedApi: T,
  endpointName: keyof T['endpoints'],
  query: string,
) => {
  return injectedApi.enhanceEndpoints({
    endpoints: {
      [endpointName]: {
        async onCacheEntryAdded(
          args: Record<string, unknown>,
          {
            cacheDataLoaded,
            getState,
            updateCachedData,
            cacheEntryRemoved,
          }: QueryCacheLifecycleApi<unknown, BaseQueryFn, Record<string, unknown>, string>,
        ) {
          await cacheDataLoaded;

          const appState = getState() as unknown as GraphqlStateSlice;

          const subscriptionManager = SubscriptionManager.getInstance();
          const subscriptionId = `subscription-${String(endpointName)}-${JSON.stringify(args)}`;

          subscriptionManager.subscribe(
            subscriptionId,
            {
              ...appState.gql,
              query: query.replace('query ', 'subscription '),
              variables: args,
            },
            {
              next: (result) => {
                updateCachedData((draft) => {
                  if (result.data) {
                    Object.keys(result.data).forEach((key) => {
                      draft[key] = result.data?.[key];
                    });
                  }
                });
              },
              error: (err) => console.error(err),
              complete: () => void 0,
            },
          );

          await cacheEntryRemoved;

          subscriptionManager.unsubscribe(subscriptionId);
        },
      },
    },
  }) as T;
};
