import { Box, keyframes, ThemeTypings } from '@chakra-ui/react';

export type PulsatingCircleProps = {
  colorScheme?: ThemeTypings['colorSchemes'];
  disabled?: boolean;
};

const pulseRing = keyframes({
  '0%': {
    transform: 'scale(0.33)',
  },
  '80%, 100%': {
    opacity: 0,
  },
});

const pulseDot = keyframes({
  '0%': {
    transform: 'scale(0.8)',
  },
  '50%': {
    transform: 'scale(1)',
  },
  '100%': {
    transform: 'scale(0.8)',
  },
});

export const PulsatingCircle = ({
  disabled = false,
  colorScheme = disabled ? 'gray' : 'green',
}: PulsatingCircleProps) => {
  return (
    <Box
      w="10px"
      h="10px"
      position="relative"
      _before={{
        content: '""',
        position: 'absolute',
        display: 'block',
        width: '200%',
        height: '200%',
        boxSizing: 'border-box',
        marginLeft: '-50%',
        marginTop: '-50%',
        borderRadius: '45px',
        backgroundColor: `${colorScheme}.300`,
        animation: !disabled && `${pulseRing} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite`,
      }}
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: `${colorScheme}.500`,
        borderRadius: '15px',
        boxShadow: '0 0 8px rgba(0,0,0,0.3)',
        animation:
          !disabled && `${pulseDot} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`,
      }}
    />
  );
};
