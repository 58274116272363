import {
  Box,
  ChakraProps,
  Flex,
  HStack,
  Icon,
  Link as ChakraLink,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Link, LinkProps, useLinkProps } from '@tanstack/react-router';

export interface SidebarNavItemProps extends LinkProps {
  icon?: React.ElementType;
  hasSubitems?: boolean;
  isOpen?: boolean;
  isSubitem?: boolean;
  isSidebarCollapsed?: boolean;
  showAsTooltip?: boolean;
  chakraProps?: ChakraProps;
  children: React.ReactNode;
  shortcut?: React.ReactNode;
}

export const SidebarNavItem = ({
  icon,
  hasSubitems = false,
  isOpen = false,
  isSubitem = false,
  isSidebarCollapsed = false,
  showAsTooltip = false,
  children,
  chakraProps,
  shortcut,
  onClick,
  ...link
}: SidebarNavItemProps) => {
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const subitemTextColor = useColorModeValue('gray.500', 'gray.200');
  const bgActiveColor = useColorModeValue('blue.50', 'rgba(214, 188, 250, 0.16)');
  const bgHoverColor = useColorModeValue('blue.25', 'rgba(214, 188, 250, 0.10)');
  const textHoverColor = useColorModeValue('purple.800', 'purple.200');
  const borderLeftHoverColor = useColorModeValue('blue.500', 'purple.300');
  let isActiveLink = false;
  const linkProps = useLinkProps(link);

  if (link.to && linkProps.className === 'active') {
    isActiveLink = true;
  }

  const tooltipContent = shortcut ? (
    <HStack spacing={2}>
      <Box>{children}</Box>
      <Box>{shortcut}</Box>
    </HStack>
  ) : (
    children
  );

  const linkContent = (
    <Tooltip label={tooltipContent} placement="right" isDisabled={!showAsTooltip}>
      <Flex
        onClick={onClick as React.MouseEventHandler<unknown>}
        align="center"
        px={isSidebarCollapsed ? 2 : 3}
        h="9"
        borderRadius="md"
        role="group"
        color={isSubitem ? subitemTextColor : textColor}
        fontSize={isSubitem ? 'xs' : 'sm'}
        borderLeft={!isSidebarCollapsed ? '2px' : undefined}
        transition="0.3s ease-out"
        borderLeftColor="transparent"
        {...((onClick || link.href) && {
          cursor: 'pointer',
          _hover: {
            bg: bgHoverColor,
            color: textHoverColor,
            transition: '0.3s ease-out',
          },
        })}
        {...(isActiveLink && {
          bg: bgActiveColor,
          color: textHoverColor,
          borderLeftColor: borderLeftHoverColor,
          transition: '0.3s ease-out',
          fontWeight: '600',
        })}
      >
        <Flex justifyContent="space-between" w="100%" alignItems="center">
          <Flex alignItems="center" w="full" textOverflow="ellipsis" whiteSpace="nowrap">
            {icon && (
              <Icon
                mr="4"
                fontSize="18"
                color={textColor}
                {...((onClick || link.href) && {
                  _groupHover: {
                    color: textHoverColor,
                  },
                })}
                {...(isActiveLink && {
                  color: textHoverColor,
                })}
                as={icon}
              />
            )}
            <Text isTruncated w="full" as="div">
              {children}
            </Text>
          </Flex>
          {hasSubitems && !showAsTooltip && (
            <Icon
              as={ChevronDownIcon}
              transition="all .25s ease-in-out"
              transform={isOpen ? 'rotate(180deg)' : ''}
              color={textColor}
              {...((onClick || link.href) && {
                _groupHover: {
                  color: textHoverColor,
                },
              })}
              {...(isActiveLink && {
                color: textHoverColor,
              })}
              w={3}
              h={3}
            />
          )}
        </Flex>
      </Flex>
    </Tooltip>
  );

  if (!link.href && !link.to) {
    return linkContent;
  }

  if (link.href) {
    return (
      <ChakraLink
        style={{ textDecoration: 'none' }}
        href={link.href}
        isExternal={link.target === '_blank'}
        _focus={{ boxShadow: 'none' }}
        {...chakraProps}
      >
        {linkContent}
      </ChakraLink>
    );
  }

  return (
    <Box _focus={{ boxShadow: 'none' }} as="span" {...chakraProps}>
      <Link {...link}>{linkContent}</Link>
    </Box>
  );
};
