import { useCallback, useMemo, useRef } from 'react';

import { useUnmountOnce } from './use-effect-once';

export interface UseSchedulerProps {
  fn?(): void;
  delayMs?: number;
  scheduler?: Scheduler;
}

export function useScheduler({ fn, delayMs = 0, scheduler: outerScheduler }: UseSchedulerProps) {
  const scheduler = useMemo(
    () => outerScheduler ?? new TimeoutScheduler(delayMs),
    [outerScheduler, delayMs],
  );
  const timerRef = useRef<SchedulerTimer>();

  const schedule = useCallback(
    (_fn = fn) => {
      if (!_fn) {
        throw new Error('No function provided to schedule');
      }

      timerRef.current?.cancel();
      timerRef.current = scheduler.schedule(_fn);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delayMs, fn],
  );

  const cancel = useCallback(() => timerRef.current?.cancel(), []);

  useUnmountOnce(cancel);

  return { schedule, cancel };
}

export interface Scheduler {
  schedule(fn: () => void): SchedulerTimer;
}

export interface SchedulerTimer {
  cancel(): void;
}

export class TimeoutScheduler implements Scheduler {
  constructor(protected readonly delayMs = 0) {}

  schedule(fn: () => void, delayMs = this.delayMs): SchedulerTimer {
    return new TimeoutSchedulerTimer(setTimeout(fn, delayMs));
  }
}

export class TimeoutSchedulerTimer implements SchedulerTimer, Disposable {
  constructor(protected readonly timeoutId: ReturnType<typeof setTimeout>) {}

  cancel() {
    clearTimeout(this.timeoutId);
  }

  [Symbol.dispose]() {
    this.cancel();
  }
}
