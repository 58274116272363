import { Box, Flex, useColorModeValue, Wrap } from '@chakra-ui/react';

import { selectValueToArray } from '../../combobox';
import { defaultProps, EditableTag, TagOption } from '../../editable/editable-tag';
import { OverflowContainer } from '../../overflow-tooltip';
import { createSelectHelper } from '../shared/select';

export const tag = createSelectHelper<TagOption>({
  getOptionValue: defaultProps.getOptionValue,
  getOptionLabel: defaultProps.getOptionLabel,

  components: {
    Preview: ({ value }) => {
      const tooltipLabel = (
        <Wrap py={2} px={1}>
          {selectValueToArray(value).map((data) => (
            <EditableTag.Option key={data.value} {...data} />
          ))}
        </Wrap>
      );
      const tooltipBgColor = useColorModeValue('gray.700', 'gray.900');
      return (
        <Box flexGrow="1" height="full" data-group>
          <OverflowContainer>
            <OverflowContainer.Tooltip
              label={tooltipLabel}
              placement="bottom-start"
              fontSize="sm"
              openDelay={500}
              bgColor={tooltipBgColor}
            >
              <Flex gap={2} maxW="100%" p={4}>
                {selectValueToArray(value).map((data) => (
                  <EditableTag.Option key={data.value} {...data} />
                ))}
              </Flex>
            </OverflowContainer.Tooltip>
          </OverflowContainer>
        </Box>
      );
    },

    Edit: EditableTag,
  },
});
