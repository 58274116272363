import { Text, useFormControl } from '@chakra-ui/react';
import {
  chakraComponents,
  GroupBase,
  PropsValue,
  SelectComponentsConfig,
} from 'chakra-react-select';
import { JSXElementConstructor, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ComboboxProps } from './combobox';
import { isNewOption } from './new-option';
import { isCreatableSelectProps } from './utils';

type Value<TOption> = JSXElementConstructor<
  TOption & {
    menuIsOpen?: boolean;
    removeProps?: JSX.IntrinsicElements['div'];
  }
>;

export type ComboboxComponents<TOption> = {
  Option?: Value<TOption>;
  SelectedOption?: Value<TOption>;
  OptionContainer?: JSXElementConstructor<{
    children: ReactNode;
    menuIsOpen: boolean;
    values: PropsValue<TOption>;
    groupSize?: number;
  }>;
};

export function useComboboxComponents<
  TOption extends object,
  IsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>({ isClearable = true, groupSize, ...props }: ComboboxProps<TOption, IsMulti, TGroup>) {
  const { t } = useTranslation('ui');
  const { id } = useFormControl({});
  const isCreatable = isCreatableSelectProps(props);
  const Option = props.components?.Option;
  const SelectedOption = props.components?.SelectedOption;
  const OptionContainer = props.components?.OptionContainer;

  return useMemo<SelectComponentsConfig<TOption, IsMulti, TGroup>>(
    () => ({
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
      MenuList: ({ children, ...props }) => (
        <chakraComponents.MenuList
          {...props}
          innerProps={{
            ...props.innerProps,
            role: 'listbox',
            'aria-labelledby': `${id}-label`,
          }}
        >
          {isCreatable && <MenuListHint>{t('combobox.selectOrCreateOption')}</MenuListHint>}
          {children}
        </chakraComponents.MenuList>
      ),
      MultiValue: (props) => {
        const CustomOption = SelectedOption ?? Option;

        if (CustomOption) {
          const { data, selectProps, removeProps } = props;
          return (
            <CustomOption
              {...data}
              menuIsOpen={selectProps.menuIsOpen}
              removeProps={isClearable && selectProps.menuIsOpen ? removeProps : undefined}
            />
          );
        }

        return <chakraComponents.MultiValue {...props} />;
      },
      Option: ({ children, ...props }) => (
        <chakraComponents.Option {...props}>
          {isNewOption(props.data) ? <NewOptionPrefix /> : null}
          {Option ? <Option {...props.data} /> : children}
        </chakraComponents.Option>
      ),
      ValueContainer: (props) => {
        if (OptionContainer) {
          return (
            <chakraComponents.ValueContainer {...props}>
              <OptionContainer
                menuIsOpen={props.selectProps.menuIsOpen}
                values={props.selectProps.value}
                groupSize={groupSize}
              >
                {props.children}
              </OptionContainer>
            </chakraComponents.ValueContainer>
          );
        }

        return <chakraComponents.ValueContainer {...props} />;
      },
    }),
    [id, isCreatable, t, SelectedOption, Option, isClearable, OptionContainer, groupSize],
  );
}

function MenuListHint({ children }: { children: ReactNode }) {
  return (
    <Text px={3} pt={3} fontSize="xs" color="gray.500">
      {children}
    </Text>
  );
}

function NewOptionPrefix() {
  const { t } = useTranslation('ui');
  return (
    <Text fontSize="sm" mr={2}>
      {t('combobox.create')}
    </Text>
  );
}
