import { isApple } from '../../utils';

export enum ShortcutModifier {
  Ctrl = 'Ctrl',
  Alt = 'Alt',
  Shift = 'Shift',
}

const AppleModifierMap: Record<ShortcutModifier, string> = {
  [ShortcutModifier.Ctrl]: '⌘',
  [ShortcutModifier.Alt]: '⌥',
  [ShortcutModifier.Shift]: '⇧',
};

export function getModifierKey(modifier: ShortcutModifier): string {
  return isApple() ? AppleModifierMap[modifier] : modifier;
}

/**
 * Render a shortcut hint with the correct modifier key based on the platform
 * in the format `[modifier+key]`.
 *
 * @example
 * ```tsx
 * shortcutHint(ShortcutModifier.Ctrl, 'C'); // [Ctrl+C]
 * // On Apple devices
 * shortcutHint(ShortcutModifier.Ctrl, 'C'); // [⌘+C]
 * ```
 */
export function shortcutHint(
  modifier: ShortcutModifier,
  key: string,
  brackets: [string, string] = ['[', ']'],
): string {
  return `${brackets[0]}${getModifierKey(modifier)}+${key}${brackets[1]}`;
}
