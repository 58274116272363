import { Box, Button, HStack, Icon, IconButton, Text } from '@chakra-ui/react';
import {
  ChatBubbleLeftRightIcon,
  CheckIcon,
  PaperClipIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { Vendor_Questionnaire_Form_Answer_Statuses_Enum } from '@main/graphql/types.generated';
import { formatDate } from '@main/shared/utils';
import { StatusTag } from '@main/ui';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  QuestionnaireFormFieldConfig,
  QuestionnnaireFieldWrapperProps,
  QuestionnnaireFormFieldAnswer,
  QuestionnnaireFormMode,
} from './form-types';

const QUESTION_STATUSES = {
  [Vendor_Questionnaire_Form_Answer_Statuses_Enum.Pending]: {
    value: 'questionnaires.form.questionStatus.pending',
    colorScheme: 'gray',
  },
  [Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed]: {
    value: 'questionnaires.form.questionStatus.completed',
    colorScheme: 'green',
  },
} as const;

export interface QuestionnaireFieldHeaderProps extends QuestionnnaireFieldWrapperProps {
  config: QuestionnaireFormFieldConfig;
  answer: QuestionnnaireFormFieldAnswer;
  setShowComment: Dispatch<SetStateAction<boolean>>;
  setShowFiles: Dispatch<SetStateAction<boolean>>;
}

export function QuestionnaireFieldHeader(props: QuestionnaireFieldHeaderProps) {
  const { t } = useTranslation();

  const isAnswerMode = props.form.mode === QuestionnnaireFormMode.Answer && !props.form.isSubmitted;
  const isCompleted =
    props.answer.status === Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed;
  const totalQuestions = props.visibleFields.length;
  const questionIdx = useMemo(
    () => props.visibleFields.findIndex((field) => field.name === props.config.name) + 1,
    [props.config.name, props.visibleFields],
  );

  const setCompletedAnswer = async (isCompleted: boolean) => {
    await props.onAnswerChange?.(props.answer, {
      status: isCompleted
        ? Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed
        : Vendor_Questionnaire_Form_Answer_Statuses_Enum.Pending,
    });
  };

  return (
    <HStack alignItems="start" justifyContent="space-between" spacing={4}>
      <HStack alignItems="start">
        <Box>
          <Text fontWeight="semibold" fontSize="xs">
            {t('questionnaires.form.questionNo', { number: questionIdx, total: totalQuestions })}
          </Text>
        </Box>
        <StatusTag
          size="sm"
          minW="auto"
          maxW="none"
          colorScheme={QUESTION_STATUSES[props.answer.status].colorScheme}
        >
          {t(QUESTION_STATUSES[props.answer.status].value)}
        </StatusTag>
      </HStack>
      {isAnswerMode &&
        (isCompleted ? (
          <IconButton
            variant="outline"
            size="sm"
            px={3}
            icon={<Icon as={PencilIcon} />}
            title={t('questionnaires.form.edit')}
            aria-label={t('questionnaires.form.edit')}
            onClick={() => setCompletedAnswer(false)}
          />
        ) : (
          <HStack spacing={3}>
            {props.answer.updated_at && (
              <Box>
                <Text fontSize="xs" color="gray.400">
                  {t('questionnaires.form.savedOn', { date: formatDate(props.answer.updated_at) })}
                </Text>
              </Box>
            )}
            <IconButton
              variant="outline"
              size="sm"
              px={3}
              icon={<Icon as={PaperClipIcon} />}
              title={t('questionnaires.form.addSupportingFiles')}
              aria-label={t('questionnaires.form.addSupportingFiles')}
              onClick={() => props.setShowFiles((isShow) => !isShow)}
            />
            <IconButton
              variant="outline"
              size="sm"
              px={3}
              icon={<Icon as={ChatBubbleLeftRightIcon} />}
              title={t('questionnaires.form.addComment')}
              aria-label={t('questionnaires.form.addComment')}
              onClick={() => props.setShowComment((isShow) => !isShow)}
            />
            <Button
              size="sm"
              colorScheme="blue"
              leftIcon={<Icon as={CheckIcon} />}
              onClick={() => setCompletedAnswer(true)}
            >
              {t('questionnaires.form.complete')}
            </Button>
          </HStack>
        ))}
    </HStack>
  );
}
