import { useCallback, useRef } from 'react';

/**
 * Returns a stable version of the callback that never changes but calls latest provided callback.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useStableCallback<TFn extends (...args: any) => any>(callback?: TFn) {
  const cbRef = useRef(callback);
  cbRef.current = callback;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return useCallback((...args: Parameters<TFn>) => cbRef.current?.(...args) as ReturnType<TFn>, []);
}
