import { ColumnDef, ColumnFilter, Table } from '@tanstack/react-table';

export function shouldDisplayFilters<TData>(table: Table<TData>): boolean {
  return hasColumnFilters(table) || hasGlobalFilter(table);
}

export function hasColumnFilters<TData>(table: Table<TData>): boolean {
  return table.getAllFlatColumns().some((column) => column.getCanFilter());
}

export function hasGlobalFilter<TData>(table: Table<TData>): boolean {
  return table.getAllFlatColumns().some((column) => column.getCanGlobalFilter());
}

export function isColumnFilter(input: unknown): input is ColumnFilter {
  return (
    typeof input === 'object' &&
    input != null &&
    'id' in input &&
    typeof input.id === 'string' &&
    'value' in input
  );
}

/**
 * Given a list of column definitions and a column filter, returns true if:
 * - filter corresponds to a column definition in the list by id
 * - filter's value matches column's filter schema (if it was defined in the column defintions's meta)
 */
export function isValidColumnFilter<TData>(
  columns: ColumnDef<TData>[],
  columnFilter: ColumnFilter,
): boolean {
  const column = columns.find(({ id }) => columnFilter.id === id);
  if (!column) {
    return false;
  }

  const filterSchema = column.meta?.filter?.filterSchema;
  if (!filterSchema) {
    return true;
  }

  return columnFilter.value === null || filterSchema.safeParse(columnFilter.value).success;
}
