import {
  CreatableSelect,
  GroupBase,
  Select as ChakraSelect,
  SelectInstance,
} from 'chakra-react-select';
import { ForwardedRef } from 'react';

import { fixedForwardRef } from '../../utils/fixed-forwardRef';
import {
  AsyncCreatableSelect,
  AsyncCreatableSelectProps as SelectProps,
  AsyncSelect,
} from './async-select';
import { isAsyncSelectProps, isCreatableSelectProps } from './utils';

export type { SelectProps };

function SelectWrapper<
  TOption,
  IsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
  props: SelectProps<TOption, IsMulti, TGroup>,
  ref: ForwardedRef<SelectInstance<TOption, IsMulti, TGroup>>,
) {
  if (isAsyncSelectProps(props)) {
    if (isCreatableSelectProps(props)) {
      return <AsyncCreatableSelect {...props} />;
    }

    return <AsyncSelect {...props} />;
  }

  if (isCreatableSelectProps(props)) {
    return <CreatableSelect {...props} ref={ref} />;
  }

  return <ChakraSelect {...props} ref={ref} />;
}

export const Select = fixedForwardRef(SelectWrapper);
