export function isSafari() {
  return (
    /safari/i.test(navigator.userAgent) &&
    !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(navigator.userAgent)
  );
}

export function isApple() {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
}
