import {
  DynamicFieldWrapper,
  DynamicFormFieldConfig,
  QuestionnaireFormComponentRegistry,
} from '@main/dynamic-form';
import { QuestionnaireUploadFragment } from '@main/graphql/queries/GetQuestionnaire.generated';
import {
  Vendor_Questionnaire_Form_Answers,
  Vendor_Questionnaire_Form_Uploads,
} from '@main/graphql/types.generated';

declare module '@main/dynamic-form' {
  interface QuestionnaireFormFieldConfigExtra {
    aiMessage?: string;
    aiCriteria?: string;
    hasAiReview?: boolean;
    supportingUploadId?: string;
  }
}

export type QuestionnaireFormFieldConfig =
  DynamicFormFieldConfig<QuestionnaireFormComponentRegistry>;
export type QuestionnnaireFormValues = Record<string, unknown>;

export interface QuestionnnaireFormFieldAnswer {
  id?: Vendor_Questionnaire_Form_Answers['id'];
  field_name: Vendor_Questionnaire_Form_Answers['field_name'];
  status: Vendor_Questionnaire_Form_Answers['status'];
  value?: Vendor_Questionnaire_Form_Answers['value'];
  comment?: Vendor_Questionnaire_Form_Answers['comment'];
  form_uploads?: QuestionnnaireFormFieldAnswerUpload[];
  created_at?: Vendor_Questionnaire_Form_Answers['created_at'];
  updated_at?: Vendor_Questionnaire_Form_Answers['updated_at'];
}

export interface QuestionnnaireFormFieldAnswerUpload {
  id?: Vendor_Questionnaire_Form_Uploads['id'];
  file_id?: Vendor_Questionnaire_Form_Uploads['file_id'];
  file?: Partial<Vendor_Questionnaire_Form_Uploads['file']>;
}

export type QuestionnnaireFormStats = {
  total: number;
  completed: number;
};

export interface QuestionnnaireFormProps {
  mode: QuestionnnaireFormMode;
  fields: readonly QuestionnaireFormFieldConfig[];
  answers?: readonly QuestionnnaireFormFieldAnswer[];
  uploads?: readonly QuestionnaireUploadFragment[];
  fieldWrapper?: DynamicFieldWrapper<
    QuestionnaireFormComponentRegistry,
    QuestionnnaireFieldWrapperProps
  >;
  isSubmitted?: boolean;
  onAnswerChange?: QuestionnnaireAnswerChange;
  onStatsChange?(stats: QuestionnnaireFormStats): void;
}

export interface QuestionnnaireFieldWrapperProps {
  form: QuestionnnaireFormProps;
  filters: QuestionnnaireFormFilters;
  visibleFields: readonly QuestionnaireFormFieldConfig[];
  onAnswerChange?: QuestionnnaireAnswerChange;
}

export type QuestionnnaireAnswerChange = (
  answer: QuestionnnaireFormFieldAnswer,
  change: Partial<QuestionnnaireFormFieldAnswer>,
) => void | Promise<void>;

export enum QuestionnnaireFormMode {
  View = 'view',
  Answer = 'answer',
}

export enum QuestionnnaireFormFilterMode {
  All = 'all',
  Unanswered = 'unanswered',
}

export interface QuestionnnaireFormFilters {
  mode: QuestionnnaireFormFilterMode;
  search: string;
}
