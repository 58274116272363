import { QuestionnaireUploadFragment } from '@main/graphql/queries/GetQuestionnaire.generated';
import { FileUpload, useDownloadStorageFile, useEagerFileUpload } from '@main/ui';

export interface QuestionnaireFieldSupportingFileProps {
  supportingFile: QuestionnaireUploadFragment;
}

export function QuestionnaireFieldSupportingFile({
  supportingFile,
}: QuestionnaireFieldSupportingFileProps) {
  const supportingFileUpload = useEagerFileUpload({
    onFileDownload: useDownloadStorageFile(),
    file: supportingFile.file,
  });

  return (
    <FileUpload {...supportingFileUpload}>
      <FileUpload.Dropzone />
    </FileUpload>
  );
}
