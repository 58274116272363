import { defineStyleConfig } from '@chakra-ui/react';

export const Text = defineStyleConfig({
  variants: {
    subheading: {
      color: 'gray.500',
      fontSize: 'sm',

      _dark: {
        color: 'gray.200',
      },
    },
  },
});
