import { MultiValue, OnChangeValue } from 'chakra-react-select';

export function isAsyncSelectProps(props: { loadOptions?: unknown }) {
  return !!props.loadOptions;
}

export function isCreatableSelectProps(props: {
  getNewOptionData?: unknown;
  onCreateOption?: unknown;
}) {
  return !!(props.getNewOptionData || props.onCreateOption);
}

export const isMultiSelectValue: <TOption>(
  value: OnChangeValue<TOption, boolean>,
) => value is MultiValue<TOption> = Array.isArray;

export function selectValueToArray<TOption>(
  value?: OnChangeValue<TOption, boolean>,
): readonly TOption[] {
  if (value == null) {
    return [];
  }
  return isMultiSelectValue(value) ? value : [value];
}
