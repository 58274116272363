/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.vendor.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type MarkVendorQuestionnaireAsSubmittedMutationVariables = Types.Exact<{
  vendorQuestionnaireId: Types.Scalars['uuid']['input'];
}>;

export type MarkVendorQuestionnaireAsSubmittedMutation = {
  __typename?: 'mutation_root';
  update_vendor_questionnaires_by_pk?: { __typename?: 'vendor_questionnaires'; id: string };
};

export const MarkVendorQuestionnaireAsSubmittedDocument = `
    mutation MarkVendorQuestionnaireAsSubmitted($vendorQuestionnaireId: uuid!) {
  update_vendor_questionnaires_by_pk(
    _set: {status: submitted}
    pk_columns: {id: $vendorQuestionnaireId}
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    MarkVendorQuestionnaireAsSubmittedMutation,
    MarkVendorQuestionnaireAsSubmittedMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    MarkVendorQuestionnaireAsSubmitted: build.mutation<
      MarkVendorQuestionnaireAsSubmittedMutation,
      MarkVendorQuestionnaireAsSubmittedMutationVariables
    >({
      query: (variables) => ({ document: MarkVendorQuestionnaireAsSubmittedDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMarkVendorQuestionnaireAsSubmittedMutation } = injectedRtkApi;
