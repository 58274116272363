import {
  ButtonProps,
  Flex,
  FlexProps,
  forwardRef,
  Tooltip as ChakraTooltip,
  TooltipProps as ChakraTooltipProps,
  useMergeRefs,
} from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/24/outline';
import useSize from 'ahooks/es/useSize';
import { Children, cloneElement, ReactElement, useEffect, useRef, useState } from 'react';

import { FloatingActionButtons, FloatingButtonsContainer } from '../floating-action-buttons';

export const OverflowContainer = (props: FlexProps) => {
  return (
    <FloatingButtonsContainer as={Flex} gap={3} align="center" maxW="100%" w="100%" {...props} />
  );
};

interface TooltipProps extends Omit<ChakraTooltipProps, 'children'> {
  children: ReactElement;
}
const Tooltip = forwardRef<TooltipProps, 'div'>((props, propRef) => {
  const [isOverflown, setIsOverflown] = useState(false);
  const textElementRef = useRef<HTMLParagraphElement>(null);
  const textContainerSize = useSize(textElementRef);
  const ref = useMergeRefs(textElementRef, propRef);

  /* Copy existing props of the children and add ref prop to it */
  const child = Children.only(props.children);
  const Component = cloneElement(child, { ref });

  /** Check whether the element is overflow or not */
  const compareSize = () => {
    const element = textElementRef.current;

    setIsOverflown(
      element
        ? element.offsetWidth < element.scrollWidth ||
            // HACK: Substract 1px to account for height errors in macos browsers
            element.offsetHeight < element.scrollHeight - 1
        : false,
    );
  };

  useEffect(compareSize, [props.label, textContainerSize]);

  return (
    <ChakraTooltip isDisabled={!isOverflown} hasArrow {...props}>
      {Component}
    </ChakraTooltip>
  );
});

const EditTrigger = forwardRef<ButtonProps, 'button'>((props, propRef) => {
  return (
    <FloatingActionButtons
      mr={4}
      ref={propRef}
      buttons={[
        {
          'aria-label': 'Edit',
          icon: <PencilIcon />,
          onClick: (e) => props.onClick?.(e),
          h: 7,
          px: '5px',
          size: 'xs',
          fontSize: '18px',
        },
      ]}
    />
  );
});

OverflowContainer.Tooltip = Tooltip;
OverflowContainer.EditTrigger = EditTrigger;
