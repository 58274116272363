import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { toast } from '@main/ui';
import { User } from '@nhost/nhost-js';
import { createListenerMiddleware, TaskAbortError, TypedStartListening } from '@reduxjs/toolkit';
import pRetry from 'p-retry';
import posthog from 'posthog-js';

import { VendorAppDispatch, VendorAppRootState } from '../../store';
import { nhost } from '../../utils/nhostClient';
import { vendorPageLoaded, vendorUserErrored, vendorUserReady } from './slice';

export const vendorListenerMiddleware = createListenerMiddleware();

const startListening = vendorListenerMiddleware.startListening as TypedStartListening<
  VendorAppRootState,
  VendorAppDispatch
>;

startListening({
  actionCreator: vendorPageLoaded,
  effect: async (action, api) => {
    api.unsubscribe();
    const { vendorToken } = action.payload;

    try {
      const user = await loginUser(vendorToken);

      await prepareUser(user);

      api.dispatch(vendorUserReady());
    } catch (e) {
      if (e instanceof TaskAbortError) {
        return;
      }

      if (
        typeof e === 'object' &&
        e !== null &&
        'error' in e &&
        e.error === 'invalid-refresh-token'
      ) {
        toast({
          status: 'error',
          isClosable: true,
          duration: null,
          title:
            'The link has expired or been revoked. If you have a questionnaire to submit, please contact the sender of the email.',
        });

        api.dispatch(vendorUserErrored());
        return;
      }

      toast({
        status: 'error',
        title: 'Something went wrong during login, please try again.',
      });
      api.dispatch(vendorUserErrored());

      datadogLogs.logger.error(`Unable to login vendor user ${vendorToken}:`, {}, toError(e));
    } finally {
      api.subscribe();
    }
  },
});

async function loginUser(vendorToken: string) {
  const { session, error } = await pRetry(() => nhost.auth.refreshSession(vendorToken), {
    retries: 5,
  });

  const user = session?.user;

  if (!user || error) {
    throw error ? toError(error) : new Error('No user found in session');
  }

  return user;
}

async function prepareUser(user: User) {
  datadogLogs.setUser({ id: user.id });
  const dContext = datadogLogs.getInternalContext();

  posthog.identify(user.id, {
    email: user.email,
    userId: user.id,
    datadogSessionId: dContext?.session_id,
    appEnv: import.meta.env.VITE_APP_ENV,
  });
}
