import { Text, Textarea, useFormControlContext } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DynamicFormComponentProps } from '../field-registry';
import { TextareaFieldConfig } from './textarea';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EditbaleTextareaFieldConfig extends TextareaFieldConfig {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EditableTextareaFieldProps
  extends DynamicFormComponentProps<EditbaleTextareaFieldConfig> {}

export function EditableTextareaField({ config }: EditableTextareaFieldProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { isDisabled, isReadOnly } = useFormControlContext();

  const shouldRenderText = isDisabled || isReadOnly;

  return (
    <Controller
      name={config.name}
      control={control}
      rules={config.validation}
      defaultValue={config.value}
      render={({ field }) => {
        return shouldRenderText ? (
          <Text>
            {typeof field.value === 'string' && field.value?.trim()
              ? field.value
              : t('questionnaires.questions.notAnswered')}
          </Text>
        ) : (
          <Textarea
            {...field}
            placeholder={config.placeholder}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            isInvalid={!!errors[config.name]}
          />
        );
      }}
    />
  );
}
