import { Menu } from '@chakra-ui/react';
import { Table } from '@tanstack/react-table';

import { TableMenuButton, TableMenuList } from '../shared/table-menu';

export function ColumnFilter<TData>({
  table,
  columnId,
}: {
  table: Table<TData>;
  columnId: string;
}) {
  const column = table.getColumn(columnId);
  if (!column) {
    return null;
  }

  const filterName = column.columnDef.meta?.name;
  const filterValue = column.getFilterValue();
  const { DisplayFilter, EditFilter } = column.columnDef.meta?.filter || {};

  return (
    <Menu isLazy defaultIsOpen={filterValue === null}>
      <TableMenuButton
        maxW="sm"
        onRemoveClick={() => {
          column.setFilterValue(undefined);
        }}
      >
        {filterName}: {DisplayFilter && <DisplayFilter table={table} column={column} />}
      </TableMenuButton>
      <TableMenuList>{EditFilter && <EditFilter table={table} column={column} />}</TableMenuList>
    </Menu>
  );
}
