import { Box, SystemStyleObject } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Cell, Row } from '@tanstack/react-table';
import { useMemo } from 'react';

import { TableItem } from './table';
import { TableActionHandler } from './table-action';
import { TableCell } from './table-cell';

export interface TableRowProps<T> {
  itemName: TableItem;
  row: Row<T>;
  borderColor: string;
  rowStyles: SystemStyleObject;
  isReorderable?: boolean;
  actionHandler: TableActionHandler<T>;
  onRowClick?(row: Row<T>): void;
}

export function TableRow<T>({
  itemName,
  row,
  borderColor,
  rowStyles,
  isReorderable,
  actionHandler,
  onRowClick,
}: TableRowProps<T>) {
  const { transform, transition, isDragging, setNodeRef } = useSortable({ id: row.id });

  const cellStyles = useMemo(
    () => ({
      position: 'relative',
      display: 'flex',
      p: 0,
      fontSize: 'sm',
      lineHeight: '4',
      backgroundClip: 'padding-box',
      ':nth-of-type(1)': {
        paddingLeft: isReorderable ? 2 : undefined,
      },
      '+ .why-table--cell': {
        borderLeft: '1px',
        borderColor: borderColor,
      },
    }),
    [borderColor, isReorderable],
  );

  const cellBoxProps = useMemo(() => ({ __css: cellStyles }), [cellStyles]);
  const leftCells = row.getLeftVisibleCells();
  const centerCells = row.getCenterVisibleCells();
  const rightCells = row.getRightVisibleCells();
  const autoCell = useMemo(
    () => <Box className="why-table--cell why-table--autocol" __css={cellStyles} p="0" />,
    [cellStyles],
  );

  function renderCell(cell: Cell<T, unknown>) {
    return (
      <TableCell
        key={cell.id}
        cell={cell}
        row={row}
        boxProps={cellBoxProps}
        isReorderable={isReorderable}
        actionHandler={actionHandler}
      />
    );
  }

  return (
    <Box
      data-testid={`table-${itemName.singular}-row-${row.id}`}
      cursor={onRowClick && 'pointer'}
      onClick={onRowClick && (() => onRowClick(row))}
      role="row"
      className={`why-table--row ${row.getIsSelected() ? 'why-table--row-selected' : ''}`}
      __css={rowStyles}
      transform={CSS.Translate.toString(transform)}
      transition={transition}
      zIndex={isDragging ? 1 : undefined}
      ref={setNodeRef}
    >
      {leftCells.map(renderCell)}
      {leftCells.length > 0 && autoCell}
      {centerCells.map(renderCell)}
      {rightCells.map(renderCell)}
      {rightCells.length === 0 && autoCell}
    </Box>
  );
}
