import {
  Avatar,
  AvatarProps,
  Box,
  Center,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { MultiValue } from 'chakra-react-select';
import { Children, ReactNode } from 'react';

import { getHashedAvatarColor } from '../../utils/colors';
import { Combobox, ComboboxProps, selectValueToArray, withMultiCombobox } from '../combobox';
import { AvatarTooltip } from './avatar-tooltip';
import { AvatarOptionVariant } from './editable-avatar';

export type AvatarOption = {
  id: string;
  displayName: string;
  avatarUrl?: string;
};

export type AvatarOptionProps = AvatarOption & {
  variant?: AvatarOptionVariant;
  size?: AvatarProps['size'];
};

export const defaultProps = {
  getOptionValue: (option) => option.id,
  getOptionLabel: (option) => option.displayName,

  components: {
    Option: ({ displayName, avatarUrl, size = 'xs' }) => {
      return (
        <Flex gap={2} alignItems="center">
          <Avatar
            size={size}
            name={displayName}
            src={avatarUrl}
            {...getHashedAvatarColor(displayName)}
          />
          <Text fontSize="xs" fontWeight="medium" color="gray.500">
            {displayName}
          </Text>
        </Flex>
      );
    },
    SelectedOption: ({ displayName, avatarUrl, size = 'xs', removeProps, menuIsOpen }) => {
      return (
        <Tag bg="none" minW="auto" maxW="none" px={0}>
          <Avatar
            size={size}
            name={displayName}
            src={avatarUrl}
            {...getHashedAvatarColor(displayName)}
          />
          {menuIsOpen && (
            <TagLabel ml={2} fontSize="xs" color="gray.500">
              {displayName}
            </TagLabel>
          )}
          {removeProps && <Box as={TagCloseButton} {...removeProps} />}
        </Tag>
      );
    },

    OptionContainer: ({ children, menuIsOpen, values, groupSize }) => {
      if (menuIsOpen) {
        return children;
      }

      const childrenArray = Children.toArray(children);
      const inputElement = childrenArray.pop();
      return (
        <Flex w="full">
          <GroupedAvatars groupSize={groupSize} values={selectValueToArray(values)}>
            {childrenArray}
          </GroupedAvatars>
          {inputElement}
        </Flex>
      );
    },
  },
} satisfies ComboboxProps<AvatarOptionProps>;

export const GroupedAvatars = ({
  children,
  values,
  groupSize = 4,
  size = 'xs',
}: {
  children: ReactNode;
  values: MultiValue<AvatarOptionProps>;
  groupSize?: number;
  size?: 'xs' | 'sm';
}) => {
  const borderColor = useColorModeValue('gray.200', 'gray.500');

  const avatars = Children.toArray(children);
  const stackedAvatars = avatars.slice(0, groupSize).reverse();
  const excess = avatars.length - stackedAvatars.length;
  const avatarSize = size === 'xs' ? 6 : 8;
  const fontSize = size === 'xs' ? '2xs' : 'xs';
  const spacing = size === 'xs' ? 1.5 : 2;

  return (
    <Flex>
      <Tooltip
        label={<AvatarTooltip values={values} />}
        gutter={12}
        hasArrow
        isDisabled={values.length === 0}
        py={2}
        openDelay={500}
      >
        <Flex px={spacing} flexDirection="row-reverse" justifyContent="flex-end">
          {excess > 0 && (
            <Center
              ml={-spacing}
              w={avatarSize}
              h={avatarSize}
              border="2px"
              borderColor={borderColor}
              rounded="full"
            >
              <Text fontSize={fontSize} fontWeight="medium">
                +{excess}
              </Text>
            </Center>
          )}

          {stackedAvatars.map((avatar, index) => (
            <Center key={index} ml={-spacing}>
              {avatar}
            </Center>
          ))}
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export function EditableMultiSelectAvatar<TOption extends AvatarOption>(
  props: Omit<ComboboxProps<TOption, true>, 'components' | 'isMulti'>,
) {
  return <Combobox {...defaultProps} {...withMultiCombobox({ ...props, isMulti: true })} />;
}

EditableMultiSelectAvatar.Option = defaultProps.components.SelectedOption;
