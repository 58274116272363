import { isNonNullable } from '@main/shared/utils';
import { FileItemRef, MultipleFilesHandlerResult, StorageErrorPayload } from '@nhost/nhost-js';

export type UploadedFile = {
  id: string;
  name?: string;
  size?: number;
};

export type MultipleFilesUploadResult = {
  errors: StorageErrorPayload[];
  files: UploadedFile[];
};

export type FileUploadResult =
  | {
      error: Error | StorageErrorPayload;
      file?: undefined;
    }
  | {
      error?: undefined;
      file: UploadedFile;
    };

export function getFileUploadResult({
  errors,
  files,
}: MultipleFilesUploadResult): FileUploadResult {
  if (errors[0]) {
    return { error: errors[0] };
  }
  if (!files[0]) {
    return { error: new Error('File upload was called without any files added') };
  }
  return { file: files[0] };
}

export function getMultipleFilesUploadResult({
  errors,
  files,
}: MultipleFilesHandlerResult): MultipleFilesUploadResult {
  return {
    errors: errors.map(getErrorFromFileRef).filter(isNonNullable),
    files: files.map(getUploadedFileFromFileRef).filter(isNonNullable),
  };
}

function getErrorFromFileRef(fileRef: FileItemRef): StorageErrorPayload | null {
  return fileRef.getSnapshot()?.context.error || null;
}

function getUploadedFileFromFileRef(fileRef: FileItemRef): UploadedFile | null {
  const context = fileRef.getSnapshot()?.context;
  if (!context?.id) {
    return null;
  }
  return {
    id: context.id,
    name: context.file?.name,
    size: context.file?.size,
  };
}
