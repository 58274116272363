import { createGqlSubscriptionApi } from '@main/graphql/create-subscription-api';

import { api, GetVendorQuestionnairesTableDocument } from './questionnaires-table.generated';

export const { useGetVendorQuestionnairesTableQuery: useGetVendorQuestionnairesTableSubscription } =
  createGqlSubscriptionApi(
    api,
    'GetVendorQuestionnairesTable',
    GetVendorQuestionnairesTableDocument,
  );
