export interface Sortable<T = number | string> {
  order?: T;
}

export function createSorter(direction: 'asc' | 'desc' = 'asc') {
  const sortDirAsc = direction === 'asc' ? 1 : -1;
  const sortDirDesc = sortDirAsc * -1;

  return (a: Sortable, b: Sortable) => {
    if (a.order === b.order) {
      return 0;
    }

    if (a.order && !b.order) {
      return -1;
    }

    if (!a.order && b.order) {
      return 1;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return a.order! > b.order! ? sortDirAsc : sortDirDesc;
  };
}
