import { SVGProps } from 'react';

export const ExpandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25 17.0834H13.125V2.91671H16.25C16.7103 2.91671 17.0834 3.2898 17.0834 3.75004V16.25C17.0834 16.7103 16.7103 17.0834 16.25 17.0834ZM3.75002 1.66671H11.875V1.66669H13.125V1.66671H16.25C17.4006 1.66671 18.3334 2.59945 18.3334 3.75004V16.25C18.3334 17.4006 17.4006 18.3334 16.25 18.3334H3.75002C2.59943 18.3334 1.66669 17.4006 1.66669 16.25V3.75004C1.66669 2.59945 2.59943 1.66671 3.75002 1.66671ZM11.875 17.0834V2.91671H3.75002C3.28978 2.91671 2.91669 3.2898 2.91669 3.75004V16.25C2.91669 16.7103 3.28978 17.0834 3.75002 17.0834H11.875ZM6.84972 8.56696C6.60565 8.32288 6.60565 7.92716 6.84972 7.68308C7.0938 7.439 7.48953 7.439 7.73361 7.68308L9.40027 9.34975C9.64435 9.59382 9.64435 9.98955 9.40027 10.2336L7.73361 11.9003C7.48953 12.1444 7.0938 12.1444 6.84972 11.9003C6.60565 11.6562 6.60565 11.2605 6.84972 11.0164L8.07445 9.79169L6.84972 8.56696Z"
    />
  </svg>
);
