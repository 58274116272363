/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { UserNameEmailFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/UserNameEmailFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetVendorQuestionnairesTableQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetVendorQuestionnairesTableQuery = {
  __typename?: 'query_root';
  vendor_questionnaires: Array<{
    __typename?: 'vendor_questionnaires';
    id: string;
    status: Types.Vendor_Questionnaire_Statuses_Enum;
    due_date?: string;
    sent_by_user: { __typename?: 'users'; id: string; email?: string; displayName: string };
    questionnaire: {
      __typename?: 'questionnaires';
      id: string;
      name?: string;
      file_id?: string;
      form_id?: string;
    };
  }>;
};

export const GetVendorQuestionnairesTableDocument = `
    query GetVendorQuestionnairesTable {
  vendor_questionnaires {
    id
    status
    due_date
    sent_by_user {
      ...UserNameEmailFragment
    }
    questionnaire {
      id
      name
      file_id
      form_id
    }
  }
}
    ${UserNameEmailFragmentFragmentDoc}` as string &
  TypedDocumentNode<GetVendorQuestionnairesTableQuery, GetVendorQuestionnairesTableQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendorQuestionnairesTable: build.query<
      GetVendorQuestionnairesTableQuery,
      GetVendorQuestionnairesTableQueryVariables | void
    >({
      query: (variables) => ({ document: GetVendorQuestionnairesTableDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetVendorQuestionnairesTableQuery, useLazyGetVendorQuestionnairesTableQuery } =
  injectedRtkApi;
