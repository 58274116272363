/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.vendor.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateVendorQuestionnaireAnswerMutationVariables = Types.Exact<{
  answer: Types.Vendor_Questionnaire_Form_Answers_Insert_Input;
}>;

export type CreateVendorQuestionnaireAnswerMutation = {
  __typename?: 'mutation_root';
  insert_vendor_questionnaire_form_answers_one?: {
    __typename?: 'vendor_questionnaire_form_answers';
    id: string;
  };
};

export type UpdateVendorQuestionnaireAnswerMutationVariables = Types.Exact<{
  answerId: Types.Scalars['uuid']['input'];
  answer: Types.Vendor_Questionnaire_Form_Answers_Set_Input;
}>;

export type UpdateVendorQuestionnaireAnswerMutation = {
  __typename?: 'mutation_root';
  update_vendor_questionnaire_form_answers_by_pk?: {
    __typename?: 'vendor_questionnaire_form_answers';
    id: string;
  };
};

export type CreateVendorQuestionnaireAnswerFilesMutationVariables = Types.Exact<{
  files:
    | Array<Types.Vendor_Questionnaire_Form_Uploads_Insert_Input>
    | Types.Vendor_Questionnaire_Form_Uploads_Insert_Input;
}>;

export type CreateVendorQuestionnaireAnswerFilesMutation = {
  __typename?: 'mutation_root';
  insert_vendor_questionnaire_form_uploads?: {
    __typename?: 'vendor_questionnaire_form_uploads_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'vendor_questionnaire_form_uploads'; id: string }>;
  };
};

export type DeleteVendorQuestionnaireAnswerFileMutationVariables = Types.Exact<{
  uploadIds: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
}>;

export type DeleteVendorQuestionnaireAnswerFileMutation = {
  __typename?: 'mutation_root';
  delete_vendor_questionnaire_form_uploads?: {
    __typename?: 'vendor_questionnaire_form_uploads_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'vendor_questionnaire_form_uploads'; id: string }>;
  };
};

export const CreateVendorQuestionnaireAnswerDocument = `
    mutation CreateVendorQuestionnaireAnswer($answer: vendor_questionnaire_form_answers_insert_input!) {
  insert_vendor_questionnaire_form_answers_one(object: $answer) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    CreateVendorQuestionnaireAnswerMutation,
    CreateVendorQuestionnaireAnswerMutationVariables
  >;
export const UpdateVendorQuestionnaireAnswerDocument = `
    mutation UpdateVendorQuestionnaireAnswer($answerId: uuid!, $answer: vendor_questionnaire_form_answers_set_input!) {
  update_vendor_questionnaire_form_answers_by_pk(
    pk_columns: {id: $answerId}
    _set: $answer
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    UpdateVendorQuestionnaireAnswerMutation,
    UpdateVendorQuestionnaireAnswerMutationVariables
  >;
export const CreateVendorQuestionnaireAnswerFilesDocument = `
    mutation CreateVendorQuestionnaireAnswerFiles($files: [vendor_questionnaire_form_uploads_insert_input!]!) {
  insert_vendor_questionnaire_form_uploads(objects: $files) {
    affected_rows
    returning {
      id
    }
  }
}
    ` as string &
  TypedDocumentNode<
    CreateVendorQuestionnaireAnswerFilesMutation,
    CreateVendorQuestionnaireAnswerFilesMutationVariables
  >;
export const DeleteVendorQuestionnaireAnswerFileDocument = `
    mutation DeleteVendorQuestionnaireAnswerFile($uploadIds: [uuid!]!) {
  delete_vendor_questionnaire_form_uploads(where: {id: {_in: $uploadIds}}) {
    affected_rows
    returning {
      id
    }
  }
}
    ` as string &
  TypedDocumentNode<
    DeleteVendorQuestionnaireAnswerFileMutation,
    DeleteVendorQuestionnaireAnswerFileMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateVendorQuestionnaireAnswer: build.mutation<
      CreateVendorQuestionnaireAnswerMutation,
      CreateVendorQuestionnaireAnswerMutationVariables
    >({
      query: (variables) => ({ document: CreateVendorQuestionnaireAnswerDocument, variables }),
    }),
    UpdateVendorQuestionnaireAnswer: build.mutation<
      UpdateVendorQuestionnaireAnswerMutation,
      UpdateVendorQuestionnaireAnswerMutationVariables
    >({
      query: (variables) => ({ document: UpdateVendorQuestionnaireAnswerDocument, variables }),
    }),
    CreateVendorQuestionnaireAnswerFiles: build.mutation<
      CreateVendorQuestionnaireAnswerFilesMutation,
      CreateVendorQuestionnaireAnswerFilesMutationVariables
    >({
      query: (variables) => ({ document: CreateVendorQuestionnaireAnswerFilesDocument, variables }),
    }),
    DeleteVendorQuestionnaireAnswerFile: build.mutation<
      DeleteVendorQuestionnaireAnswerFileMutation,
      DeleteVendorQuestionnaireAnswerFileMutationVariables
    >({
      query: (variables) => ({ document: DeleteVendorQuestionnaireAnswerFileDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useCreateVendorQuestionnaireAnswerMutation,
  useUpdateVendorQuestionnaireAnswerMutation,
  useCreateVendorQuestionnaireAnswerFilesMutation,
  useDeleteVendorQuestionnaireAnswerFileMutation,
} = injectedRtkApi;
