import { Box, Tag, TagCloseButton, TagLabel, ThemeTypings } from '@chakra-ui/react';

import { Combobox, ComboboxProps, withMultiCombobox } from '../combobox';

export type TagOption = {
  value: string;
  label?: string;
  colorScheme?: ThemeTypings['colorSchemes'];
};

export const defaultProps = {
  getOptionValue: (option) => option.value,
  getOptionLabel: (option) => option.label || option.value,

  components: {
    Option: (props) => (
      <Tag minW="auto" maxW="none" colorScheme={props.colorScheme}>
        <TagLabel>{defaultProps.getOptionLabel(props)}</TagLabel>
        {props.removeProps && <Box as={TagCloseButton} {...props.removeProps} />}
      </Tag>
    ),
  },
} satisfies ComboboxProps<TagOption>;

export function EditableTag<TOption extends TagOption, IsMulti extends boolean = false>(
  props: Omit<ComboboxProps<TOption, IsMulti>, 'components'>,
) {
  return <Combobox {...defaultProps} {...withMultiCombobox(props)} />;
}

EditableTag.Option = defaultProps.components.Option;
