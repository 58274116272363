export interface LoadingDrawerConfig {
  open(entityId: string): Promise<void>;
}

export class LoadingDrawer {
  protected open = this.config.open;

  constructor(protected config: LoadingDrawerConfig) {}

  load(options: { entityId: string }) {
    return this.open(options.entityId);
  }
}
