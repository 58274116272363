import { Avatar, AvatarProps, Box, Tag, TagCloseButton, TagLabel, Tooltip } from '@chakra-ui/react';

import { getHashedAvatarColor } from '../../utils/colors';
import { Combobox, ComboboxProps, withMultiCombobox } from '../combobox';
import { AvatarTooltip } from './avatar-tooltip';

export type AvatarOption = {
  id: string;
  displayName: string;
  avatarUrl?: string;
};

export type AvatarOptionVariant = 'detailed' | 'short';

type AvatarOptionProps = AvatarOption & {
  variant?: AvatarOptionVariant;
  size?: AvatarProps['size'];
};

export const defaultProps = {
  getOptionValue: (option) => option.id,
  getOptionLabel: (option) => option.displayName,

  components: {
    Option: ({ id, displayName, avatarUrl, variant = 'detailed', size = 'xs', removeProps }) => (
      <Tag bg="none" minW="auto" maxW="none" px={0}>
        <Tooltip
          label={<AvatarTooltip values={[{ id, displayName }]} />}
          isDisabled={variant === 'detailed'}
          hasArrow
          gutter={16}
          openDelay={500}
          p={2}
        >
          <Avatar
            size={size}
            name={displayName}
            src={avatarUrl}
            {...getHashedAvatarColor(displayName)}
          />
        </Tooltip>
        {variant === 'detailed' && (
          <TagLabel ml={2} fontSize="xs" color="gray.500">
            {displayName}
          </TagLabel>
        )}
        {removeProps && <Box as={TagCloseButton} {...removeProps} />}
      </Tag>
    ),
  },
} satisfies ComboboxProps<AvatarOptionProps>;

export function EditableAvatar<TOption extends AvatarOption, IsMulti extends boolean = false>(
  props: Omit<ComboboxProps<TOption, IsMulti>, 'components'>,
) {
  return <Combobox {...defaultProps} {...withMultiCombobox(props)} />;
}

EditableAvatar.Option = defaultProps.components.Option;
