import { IconVariant, SecondaryAction } from './drawer-actions';

export function getIconVariantActions(secondaryActions: SecondaryAction[] | undefined) {
  if (!secondaryActions) {
    return [];
  }

  /* Safe to use type assertion, since we are filtering actions only with asserted type  */
  const actions = secondaryActions
    .filter((action) => action.variant === 'icon')
    .map(({ variant, ...action }) => action) as IconVariant[];

  return actions;
}
export function getOutlineVariantActions(secondaryActions: SecondaryAction[] | undefined) {
  if (!secondaryActions) {
    return [];
  }

  /* Safe to use type assertion, since we are filtering actions only with asserted type  */
  const actions = secondaryActions.filter((action) => action.variant === 'outline');

  return actions;
}
