import { ThemeTypings } from '@chakra-ui/react';
import { ParseKeys } from 'i18next';

export enum QuestionnaireType {
  File = 'file',
  Form = 'form',
}

export type QuestionnaireTypes = {
  [key in QuestionnaireType]: {
    value: ParseKeys;
    colorScheme: ThemeTypings['colorSchemes'];
  };
};

export const QUESTIONNAIRE_TYPES: QuestionnaireTypes = {
  [QuestionnaireType.File]: { value: 'questionnaires.type.file', colorScheme: 'purple' },
  [QuestionnaireType.Form]: { value: 'questionnaires.type.form', colorScheme: 'purple' },
};

export interface QuestionnaireTypeDetails {
  file_id?: string;
  file?: unknown;
  form_id?: string;
  form?: unknown;
}

export function getQuestionnaireType(
  questionnaire: QuestionnaireTypeDetails | undefined,
): QuestionnaireType {
  if (questionnaire?.form_id || questionnaire?.form) {
    return QuestionnaireType.Form;
  }

  // defaults to file type because a questionnaire can be of file type and not having a file uploaded
  return QuestionnaireType.File;
}
