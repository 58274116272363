import { useMenuState } from '@chakra-ui/react';
import { Column, Table } from '@tanstack/react-table';

import { Combobox } from '../../../combobox';

export function TableMenuColumnSelect<TData>({
  table,
  placeholder,
  shouldDisplayColumn,
  onSelect,
}: {
  table: Table<TData>;
  placeholder?: string;
  shouldDisplayColumn: (column: Column<TData, unknown>) => boolean;
  onSelect: (column: Column<TData, unknown>) => void;
}) {
  const { onClose } = useMenuState();

  const options = table
    .getAllColumns()
    .filter(shouldDisplayColumn)
    .map((column) => ({
      value: column.id,
      label: column.columnDef.meta?.name,
      column,
    }));

  return (
    <Combobox
      variant="popover"
      size="sm"
      menuIsOpen
      autoFocus
      placeholder={placeholder}
      options={options}
      onChange={(value) => {
        if (value) {
          onSelect(value.column);
          onClose();
        }
      }}
    />
  );
}
