import { Center, Spinner } from '@chakra-ui/react';
import { defaultAlertDialogContext, Page404 } from '@main/ui';
import { Route, Router } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { rootRoute } from './routes/__root';
import { vendorQuestionnairesRoute } from './routes/questionnaires';
import { vendorLayout, vendorMain } from './routes/vendorToken';

const route404 = new Route({
  getParentRoute: () => rootRoute,
  path: '*',
  component: Page404,
});

const routeTree = rootRoute.addChildren([
  vendorLayout.addChildren([vendorMain, vendorQuestionnairesRoute]),
  route404,
]);

export const router = new Router({
  routeTree,
  defaultPendingComponent: () => (
    <Center h="100vh">
      <Spinner size="xl" thickness="4px" color="blue.500" />
    </Center>
  ),
  context: {
    dialog: defaultAlertDialogContext,
  },
});

router.subscribe('onLoad', () => {
  posthog.capture('$pageview');
  router.options.context.dialog.closeDialog();
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
