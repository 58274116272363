import { FormControlProps } from '@chakra-ui/react';
import { createSorter } from '@main/shared/utils';
import {
  FormHTMLAttributes,
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
  RefAttributes,
  RefObject,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

import { DynamicField, DynamicFieldWrapper } from './dynamic-field';
import { AnyDynamicFormComponentRegistry, DynamicFormFieldConfig } from './field-registry';

export interface DynamicFormProps<
  TRegistry extends AnyDynamicFormComponentRegistry,
  TWrapperProps extends object = object,
> {
  componentRegistry: TRegistry;
  fields: readonly DynamicFormFieldConfig<TRegistry>[];
  onSubmit?: SubmitHandler<FieldValues>;
  form?: Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>;
  useForm?: UseFormProps;
  children?: ReactNode;
  fieldControl?: FormControlProps;
  fieldWrapper?: DynamicFieldWrapper<TRegistry, TWrapperProps>;
  fieldWrapperProps?: TWrapperProps;
  fieldSortDir?: 'asc' | 'desc';
}

export interface DynamicFormRef {
  form: UseFormReturn;
  formRef: RefObject<HTMLFormElement>;
}

function _DynamicForm<
  TRegistry extends AnyDynamicFormComponentRegistry,
  TWrapperProps extends object = object,
>(props: DynamicFormProps<TRegistry, TWrapperProps>, ref: ForwardedRef<DynamicFormRef>) {
  const form = useForm(props.useForm);
  const formRef = useRef<HTMLFormElement>(null);

  const fieldsSorter = useMemo(
    () => (props.fieldSortDir ? createSorter(props.fieldSortDir) : undefined),
    [props.fieldSortDir],
  );
  const fields = useMemo(
    () => (fieldsSorter ? [...props.fields].sort(fieldsSorter) : props.fields),
    [props.fields, fieldsSorter],
  );

  useImperativeHandle(ref, () => ({ form, formRef }), [form, formRef]);

  const onSubmit = props.onSubmit && form.handleSubmit(props.onSubmit);

  return (
    <FormProvider {...form}>
      <form {...props.form} onSubmit={onSubmit} ref={formRef}>
        {fields.map((field) => (
          <DynamicField
            key={field.name}
            config={field}
            wrapper={props.fieldWrapper}
            wrapperProps={props.fieldWrapperProps}
            formControl={props.fieldControl}
            componentRegistry={props.componentRegistry}
          />
        ))}
        {props.children}
      </form>
    </FormProvider>
  );
}

export const DynamicForm = memo(forwardRef(_DynamicForm)) as <
  TRegistry extends AnyDynamicFormComponentRegistry,
  TWrapperProps extends object = object,
>(
  props: DynamicFormProps<TRegistry, TWrapperProps> & RefAttributes<DynamicFormRef>,
) => ReactNode;
