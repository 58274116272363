import posthog from 'posthog-js';

import { APP_ENV } from './app-env';

export function initPostHog(apiKey: string, appEnv: APP_ENV) {
  const IS_APP_PROD = appEnv.includes('prod');

  posthog.init(apiKey, {
    api_host: 'https://e.complyance.com',
    autocapture: IS_APP_PROD,
    capture_pageview: IS_APP_PROD,
    capture_pageleave: IS_APP_PROD,
    capture_performance: IS_APP_PROD,
    persistence: 'memory', // don't need cookie banner
    disable_session_recording: !IS_APP_PROD,
    enable_recording_console_log: IS_APP_PROD,
    loaded: (ph) => {
      if (IS_APP_PROD) {
        ph.opt_in_capturing();
      } else {
        ph.opt_out_capturing();
      }
    },
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
      recordBody: true,
      recordHeaders: true,
    },
  });

  posthog.setPersonPropertiesForFlags({ appEnv });
}
