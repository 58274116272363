export function dedupeArrays<T>(arrays: T[][], hasher = (item: T): unknown => item): T[] {
  const seen = new Set<unknown>();
  const result: T[] = [];

  for (const array of arrays) {
    for (const item of array) {
      const hash = hasher(item);
      if (!seen.has(hash)) {
        seen.add(hash);
        result.push(item);
      }
    }
  }

  return result;
}
