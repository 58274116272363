import { RowData, Table, TableMeta } from '@tanstack/react-table';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    query?: {
      variables?: object;
    };
  }
}

/** @internal */
export interface InternalTableMeta<TData extends RowData> extends TableMeta<TData> {
  hasMultilineCol: boolean;
}

/** @internal */
export function getTableMeta<TData extends RowData>(
  table: Table<TData>,
): InternalTableMeta<TData> | undefined {
  return table.options.meta as InternalTableMeta<TData> | undefined;
}
