import { z } from 'zod';

export const organizationTabKeys = [
  'details',
  'programs',
  'users',
  'invitations',
  'sub_orgs',
  'integrations',
  'frameworks',
  'vendors',
  'policies',
  'risks',
] as const;
export const userTabKeys = ['details', 'organizations'] as const;

export type OrganizationTabKeys = (typeof organizationTabKeys)[number];
export type BackofficeUserTabKeys = (typeof userTabKeys)[number];

export const backofficeDrawerSchema = z.object({
  drawerEntity: z.union([z.literal('organization'), z.literal('user')]).optional(),
  activeTab: z.union([z.enum(organizationTabKeys), z.enum(userTabKeys)]).optional(),
  drawerEntityId: z.string().optional(),
});

export type BackofficeDrawerType = z.infer<typeof backofficeDrawerSchema>;
