import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import './datadog-logger';
import './posthog-config';
import './utils/i18n';

import { ChakraProvider } from '@chakra-ui/react';
import { AlertDialogContainer, theme, ToastContainer, useAlertDialog } from '@main/ui';
import { NhostProvider } from '@nhost/react';
import { RouterProvider } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { router } from './router';
import { store } from './store';
import { nhost } from './utils/nhostClient';

const AppRouter = () => {
  const dialog = useAlertDialog();

  return <RouterProvider router={router} context={{ dialog }} />;
};

const App = () => {
  return (
    <StrictMode>
      <PostHogProvider client={posthog}>
        <Provider store={store}>
          <NhostProvider nhost={nhost}>
            <ChakraProvider theme={theme} portalZIndex={40}>
              <AlertDialogContainer>
                <AppRouter />
              </AlertDialogContainer>
            </ChakraProvider>
            <ToastContainer />
          </NhostProvider>
        </Provider>
      </PostHogProvider>
    </StrictMode>
  );
};

const rootElement = document.getElementById('root') as HTMLElement;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
}
