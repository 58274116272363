/** Convert a date to a locale date string of the user agent */
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

export const DEFAULT_DATE_FORMAT = 'LLL';

export const formatDateTime = (date: dayjs.ConfigType) => dayjs(date).format('LL HH:mm');

export const formatDate = (date: dayjs.ConfigType) => dayjs(date).format('LL');
