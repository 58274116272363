import { css } from '@emotion/react';

import LexicalDefaultStyles from '../default/styles';

export default css`
  ${LexicalDefaultStyles}

  .MarkdownEditorTheme__quote {
    margin: 0;
    margin-left: 0;
    margin-bottom: var(--chakra-space-2);
    border-left-color: inherit;
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: var(--chakra-space-4);
    opacity: 0.7;
  }

  .MarkdownEditorTheme__h1 {
    font-size: 2em;
    margin: 0;
  }
  .MarkdownEditorTheme__h2 {
    font-size: 1.7em;
    margin: 0;
    text-transform: uppercase;
  }
  .MarkdownEditorTheme__h3 {
    font-size: 1.5em;
    margin: 0;
    text-transform: uppercase;
  }
  .MarkdownEditorTheme__h4 {
    font-size: 1.3em;
    margin: 0;
    text-transform: uppercase;
  }
  .MarkdownEditorTheme__h5 {
    font-size: 1.1em;
    margin: 0;
    text-transform: uppercase;
  }

  .MarkdownEditorTheme__textBold {
    font-weight: bold;
  }
  .MarkdownEditorTheme__textItalic {
    font-style: italic;
  }
  .MarkdownEditorTheme__textUnderline {
    text-decoration: underline;
  }
  .MarkdownEditorTheme__textStrikethrough {
    text-decoration: line-through;
  }
  .MarkdownEditorTheme__textUnderlineStrikethrough {
    text-decoration: underline line-through;
  }
  .MarkdownEditorTheme__textSubscript {
    font-size: 0.8em;
    vertical-align: sub !important;
  }
  .MarkdownEditorTheme__textSuperscript {
    font-size: 0.8em;
    vertical-align: super;
  }

  .MarkdownEditorTheme__code {
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: var(--chakra-space-2) var(--chakra-space-2) var(--chakra-space-2) var(--chakra-space-4);
    margin: 0;
    margin-top: var(--chakra-space-2);
    margin-bottom: var(--chakra-space-2);
    overflow-x: auto;
    position: relative;
    tab-size: 2;
  }

  .MarkdownEditorTheme__link {
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.8;
  }

  .MarkdownEditorTheme__ol1 {
    padding: 0;
    margin: 0;
    list-style-position: inside;
  }
  .MarkdownEditorTheme__ol2 {
    padding: 0;
    margin: 0;
    list-style-type: upper-alpha;
    list-style-position: inside;
  }
  .MarkdownEditorTheme__ol3 {
    padding: 0;
    margin: 0;
    list-style-type: lower-alpha;
    list-style-position: inside;
  }
  .MarkdownEditorTheme__ol4 {
    padding: 0;
    margin: 0;
    list-style-type: upper-roman;
    list-style-position: inside;
  }
  .MarkdownEditorTheme__ol5 {
    padding: 0;
    margin: 0;
    list-style-type: lower-roman;
    list-style-position: inside;
  }
  .MarkdownEditorTheme__ul {
    padding: 0;
    margin: 0;
    list-style-position: inside;
  }
  .MarkdownEditorTheme__listItem {
    padding: 0 var(--chakra-space-4);
  }
  .MarkdownEditorTheme__nestedListItem {
    list-style-type: none;
  }
  .MarkdownEditorTheme__nestedListItem:before,
  .MarkdownEditorTheme__nestedListItem:after {
    display: none;
  }
`;
