import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'container',
  'label',
]);

const sizes = {
  xs: definePartsStyle({
    container: {
      px: '2',
      height: '18px',
    },
    label: { fontSize: '10' },
  }),
  sm: definePartsStyle({}),
};

export const TagTheme = defineMultiStyleConfig({
  sizes,
  defaultProps: {
    size: 'sm',
  },
});
