import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { Cell } from '@tanstack/react-table';

import { whenNotSelecting } from '../../../utils/selection';
import { getTableMeta } from '../table-meta';

export interface CellContainerProps extends FlexProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: Cell<any, any>;
}

export const CellContainer = forwardRef<CellContainerProps, 'div'>(
  ({ cell, onClick, ...props }, ref) => {
    const hasMultilineCol = getTableMeta(cell.getContext().table)?.hasMultilineCol;

    return (
      <Flex
        ref={ref}
        minH={12}
        px={4}
        py={2}
        gap={2}
        align={hasMultilineCol ? 'flex-start' : 'center'}
        overflowX="hidden"
        cursor={onClick && 'pointer'}
        onClick={onClick && whenNotSelecting(onClick)}
        w="full"
        {...props}
      />
    );
  },
);
