import { FormControl, FormLabel, Stack, Tag, Text } from '@chakra-ui/react';
import { CalendarDaysIcon, UserIcon } from '@heroicons/react/24/outline';
import {
  formatDate,
  getQuestionnaireType,
  QUESTIONNAIRE_TYPES,
  QuestionnaireType,
} from '@main/shared/utils';
import {
  DrawerProperty,
  EditableAvatar,
  FileUpload,
  useDownloadStorageFile,
  useLazyFileUpload,
} from '@main/ui';
import { BarsIcon, TagIcon } from '@main/ui/icons';
import { useTranslation } from 'react-i18next';

import { useGetVendorQuestionnaireDrawerQuery } from './drawer.generated';

export interface VendorQuestionnaireDetailsTabProps {
  questionnaireId: string;
}

export function VendorQuestionnaireDetailsTab({
  questionnaireId,
}: VendorQuestionnaireDetailsTabProps) {
  const { t } = useTranslation();
  const downloadStorageFile = useDownloadStorageFile();
  const { data } = useGetVendorQuestionnaireDrawerQuery({ questionnaireId });

  const questionnaire = data?.vendor_questionnaires_by_pk;

  const questionnaireFileUpload = useLazyFileUpload({
    file: questionnaire?.questionnaire.file,
    onFileDownload: downloadStorageFile,
  });

  if (!questionnaire) {
    return null;
  }

  const type = getQuestionnaireType(questionnaire.questionnaire);
  const typeMeta = QUESTIONNAIRE_TYPES[type];

  return (
    <Stack spacing={2.5}>
      <DrawerProperty>
        <DrawerProperty.Label icon={BarsIcon}>
          {t('vendors.questionnaires.tabs.details.description')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <Text fontSize="xs" color="gray.600">
            {questionnaire.questionnaire.description}
          </Text>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={TagIcon}>
          {t('vendors.questionnaires.tabs.details.type')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <Tag colorScheme={typeMeta.colorScheme} w="fit-content" data-testid="questionnaire-type">
            {t(typeMeta.value)}
          </Tag>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={UserIcon}>
          {t('vendors.questionnaires.tabs.details.sentBy')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <EditableAvatar.Option {...questionnaire.sent_by_user}></EditableAvatar.Option>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={CalendarDaysIcon}>
          {t('vendors.questionnaires.tabs.details.dueDate')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <Text fontSize="xs" color="gray.600">
            {formatDate(questionnaire.due_date)}
          </Text>
        </DrawerProperty.Content>
      </DrawerProperty>

      {type === QuestionnaireType.File && (
        <FormControl minW="256px" isReadOnly>
          <FormLabel>{t('vendors.questionnaires.tabs.details.file')}</FormLabel>
          <FileUpload {...questionnaireFileUpload.props}>
            <FileUpload.Dropzone />
          </FileUpload>
        </FormControl>
      )}
    </Stack>
  );
}
