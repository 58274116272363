import { ColumnSort, Table } from '@tanstack/react-table';

export function shouldDisplaySort<TData>(table: Table<TData>): boolean {
  return table.getAllFlatColumns().some((column) => column.getCanSort());
}

export function isColumnSort(input: unknown): input is ColumnSort {
  return (
    typeof input === 'object' &&
    input != null &&
    'id' in input &&
    typeof input.id === 'string' &&
    'desc' in input &&
    typeof input.desc === 'boolean'
  );
}
