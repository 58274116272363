import { useMemo } from 'react';

export const useDrawerAnimation = () => {
  return useMemo(() => {
    return {
      motionProps: {
        initial: 'none',
        animate: 'none',
        exit: 'none',
      },
    };
  }, []);
};
