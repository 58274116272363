import {
  Box,
  HTMLChakraProps,
  Tag,
  TagLabel,
  ThemeTypings,
  useStyleConfig,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export type StatusTagProps = HTMLChakraProps<'span'> & {
  colorScheme?: ThemeTypings['colorSchemes'];
  size?: 'xs' | 'sm' | 'md' | 'lg';
  rightSlot?: ReactNode;
};

export function StatusTag({ colorScheme, size, rightSlot, children, ...props }: StatusTagProps) {
  const styles = useStyleConfig('StatusTag', { colorScheme, size });

  return (
    <Tag {...props} colorScheme={colorScheme} size={size}>
      <Box __css={styles} />
      <TagLabel>{children}</TagLabel>
      {rightSlot}
    </Tag>
  );
}
