import { Flex } from '@chakra-ui/react';

import { selectValueToArray } from '../../combobox';
import {
  AvatarOption,
  AvatarOptionVariant,
  defaultProps,
  EditableAvatar,
} from '../../editable/editable-avatar';
import {
  EditableMultiSelectAvatar,
  GroupedAvatars,
} from '../../editable/editable-multiselect-avatar';
import { createSelectHelper } from '../shared/select';

type AvatarExtraColumnDef = {
  variant?: AvatarOptionVariant;
  groupSize?: number;
};

export const avatar = createSelectHelper<AvatarOption, AvatarExtraColumnDef>({
  getOptionValue: defaultProps.getOptionValue,
  getOptionLabel: defaultProps.getOptionLabel,

  components: {
    Preview: ({ columnDef: { variant = 'short', groupSize = 2, isMulti }, value }) => {
      const values = selectValueToArray(value);
      return (
        <Flex py="2" px="4" flexGrow="1" height="full">
          {isMulti ? (
            <GroupedAvatars values={values} groupSize={groupSize} size="sm">
              {selectValueToArray(value).map((data) => (
                <EditableMultiSelectAvatar.Option key={data.id} size="sm" {...data} />
              ))}
            </GroupedAvatars>
          ) : (
            selectValueToArray(value).map((data) => (
              <EditableAvatar.Option key={data.id} variant={variant} size="sm" {...data} />
            ))
          )}
        </Flex>
      );
    },

    Edit: EditableAvatar,
  },
});
