import { api, RequiredGraphqlState, WithGraphqlReducer } from '@main/graphql/shared-api';
import { combineReducers, configureStore, createReducer } from '@reduxjs/toolkit';

import { vendorListenerMiddleware } from '../features/vendor-questionnaires/middleware';
import { vendorPageLoaded, vendorReducer } from '../features/vendor-questionnaires/slice';
import { nhost } from '../utils/nhostClient';

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  vendor: vendorReducer,
  gql: createReducer<RequiredGraphqlState>({ nhostClient: nhost, defaultRole: 'user' }, (builder) =>
    builder.addCase(vendorPageLoaded, (state) => {
      state.defaultRole = 'vendor';
    }),
  ),
} satisfies WithGraphqlReducer);

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['gql'],
      },
    }).concat([api.middleware, vendorListenerMiddleware.middleware]),
  devTools: import.meta.env.DEV,
});

export type VendorAppRootState = ReturnType<typeof store.getState>;
export type VendorAppDispatch = typeof store.dispatch;
