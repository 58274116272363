import { css } from '@emotion/react';

import LexicalDefaultStyles from '../default/styles';

export default css`
  ${LexicalDefaultStyles}

  .WysiwygEditorTheme {
    --cply-wysiwyg-editor-quote-color: var(--chakra-colors-gray-100);
    --cply-wysiwyg-editor-code-color: var(--chakra-colors-gray-100);
    --cply-wysiwyg-editor-code-gutter-color: var(--chakra-colors-gray-500);
    --cply-wysiwyg-editor-code-gutter-bg-color: var(--chakra-colors-gray-200);
    --cply-wysiwyg-editor-code-gutter-border-color: var(--chakra-colors-gray-300);
    --cply-wysiwyg-editor-table-header-bg-color: var(--chakra-colors-gray-100);
    --cply-wysiwyg-editor-table-border-color: var(--chakra-colors-gray-300);

    font-family: var(--chakra-fonts-body);
    font-size: 16px;
    width: auto;
    min-width: 100%;
  }

  .editor-mode-dark .WysiwygEditorTheme {
    --cply-wysiwyg-editor-quote-color: var(--chakra-colors-gray-700);
    --cply-wysiwyg-editor-code-color: var(--chakra-colors-gray-700);
    --cply-wysiwyg-editor-code-gutter-color: var(--chakra-colors-gray-400);
    --cply-wysiwyg-editor-code-gutter-bg-color: var(--chakra-colors-gray-600);
    --cply-wysiwyg-editor-code-gutter-border-color: var(--chakra-colors-gray-500);
    --cply-wysiwyg-editor-table-header-bg-color: var(--chakra-colors-gray-700);
    --cply-wysiwyg-editor-table-border-color: var(--chakra-colors-gray-600);
  }

  .WysiwygEditorTheme__quote {
    margin: 0;
    margin-left: 0;
    margin-bottom: var(--chakra-space-2);
    border-left-color: inherit;
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: var(--chakra-space-4);
    opacity: 0.7;
  }

  .WysiwygEditorTheme__h1 {
    font-size: 2em;
    margin: 0;
  }
  .WysiwygEditorTheme__h2 {
    font-size: 1.7em;
    margin: 0;
    text-transform: uppercase;
  }
  .WysiwygEditorTheme__h3 {
    font-size: 1.5em;
    margin: 0;
    text-transform: uppercase;
  }
  .WysiwygEditorTheme__h4 {
    font-size: 1.3em;
    margin: 0;
    text-transform: uppercase;
  }
  .WysiwygEditorTheme__h5 {
    font-size: 1.1em;
    margin: 0;
    text-transform: uppercase;
  }

  .WysiwygEditorTheme__textBold {
    font-weight: bold;
  }
  .WysiwygEditorTheme__textItalic {
    font-style: italic;
  }
  .WysiwygEditorTheme__textUnderline {
    text-decoration: underline;
  }
  .WysiwygEditorTheme__textStrikethrough {
    text-decoration: line-through;
  }
  .WysiwygEditorTheme__textUnderlineStrikethrough {
    text-decoration: underline line-through;
  }
  .WysiwygEditorTheme__textSubscript {
    font-size: 0.8em;
    vertical-align: sub !important;
  }
  .WysiwygEditorTheme__textSuperscript {
    font-size: 0.8em;
    vertical-align: super;
  }

  .WysiwygEditorTheme__textCode {
    font-family: Menlo, Consolas, Monaco, monospace;
    background: var(--cply-wysiwyg-editor-quote-color);
  }

  .WysiwygEditorTheme__link {
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.8;
  }

  .WysiwygEditorTheme__ol1 {
    padding: 0;
    margin: 0;
    list-style-position: inside;
  }
  .WysiwygEditorTheme__ol2 {
    padding: 0;
    margin: 0;
    list-style-type: upper-alpha;
    list-style-position: inside;
  }
  .WysiwygEditorTheme__ol3 {
    padding: 0;
    margin: 0;
    list-style-type: lower-alpha;
    list-style-position: inside;
  }
  .WysiwygEditorTheme__ol4 {
    padding: 0;
    margin: 0;
    list-style-type: upper-roman;
    list-style-position: inside;
  }
  .WysiwygEditorTheme__ol5 {
    padding: 0;
    margin: 0;
    list-style-type: lower-roman;
    list-style-position: inside;
  }
  .WysiwygEditorTheme__ul {
    padding: 0;
    margin: 0;
    list-style-position: inside;
  }
  .WysiwygEditorTheme__listItem {
    padding: 0 var(--chakra-space-4);
  }
  .WysiwygEditorTheme__nestedListItem {
    list-style-type: none;
  }
  .WysiwygEditorTheme__nestedListItem:before,
  .WysiwygEditorTheme__nestedListItem:after {
    display: none;
  }

  .WysiwygEditorTheme__code {
    font-family: 'Fira Code', Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: var(--chakra-space-2);
    padding-top: var(--chakra-space-4);
    padding-left: var(--chakra-space-12);
    margin: var(--chakra-space-2) 0;
    overflow-x: auto;
    position: relative;
    background: var(--cply-wysiwyg-editor-code-color);
  }
  .WysiwygEditorTheme__code:before {
    content: attr(data-gutter);
    position: absolute;
    left: 0;
    top: 0;
    padding: var(--chakra-space-2);
    padding-top: var(--chakra-space-4);
    white-space: pre-wrap;
    text-align: right;
    min-width: var(--chakra-space-6);
    color: var(--cply-wysiwyg-editor-code-gutter-color);
    background: var(--cply-wysiwyg-editor-code-gutter-bg-color);
    border-right: 1px solid var(--cply-wysiwyg-editor-code-gutter-border-color);
  }
  .WysiwygEditorTheme__code:after {
    content: attr(data-highlight-language);
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--chakra-space-1);
    font-size: 10px;
    text-transform: uppercase;
    color: var(--cply-wysiwyg-editor-code-gutter-color);
  }
  .WysiwygEditorTheme_code__tokenComment {
    color: slategray;
  }
  .WysiwygEditorTheme_code__tokenPunctuation {
    color: #999;
  }
  .WysiwygEditorTheme_code__tokenProperty {
    color: #905;
  }
  .WysiwygEditorTheme_code__tokenSelector {
    color: #690;
  }
  .WysiwygEditorTheme_code__tokenOperator {
    color: #9a6e3a;
  }
  .WysiwygEditorTheme_code__tokenAttr {
    color: #07a;
  }
  .WysiwygEditorTheme_code__tokenVariable {
    color: #e90;
  }
  .WysiwygEditorTheme_code__tokenFunction {
    color: #dd4a68;
  }

  /* Table styles */
  .WysiwygEditorTheme__table {
    border-collapse: collapse;
    border-spacing: 0;
    overflow-y: scroll;
    overflow-x: scroll;
    table-layout: fixed;
    width: max-content;
    margin: 0 0 var(--chakra-space-4) 0;
  }
  .WysiwygEditorTheme__tableSelection *::selection {
    background-color: transparent;
  }
  .WysiwygEditorTheme__tableSelected {
    outline: 2px solid var(--chakra-colors-blue-500);
  }
  .WysiwygEditorTheme__tableCell {
    border: 1px solid var(--cply-wysiwyg-editor-table-border-color);
    width: fit-content;
    min-width: 75px;
    vertical-align: top;
    text-align: start;
    padding: var(--chakra-space-1) var(--chakra-space-2);
    position: relative;
    outline: none;
  }
  .WysiwygEditorTheme__tableCellSortedIndicator {
    display: block;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--chakra-colors-gray-700);
  }
  .WysiwygEditorTheme__tableCellResizer {
    position: absolute;
    right: -4px;
    height: 100%;
    width: 8px;
    cursor: ew-resize;
    z-index: 10;
    top: 0;
  }
  .WysiwygEditorTheme__tableCellHeader {
    background-color: var(--cply-wysiwyg-editor-table-header-bg-color);
    text-align: start;
  }
  .WysiwygEditorTheme__tableCellSelected {
    background-color: #c9dbf0;
  }
  .WysiwygEditorTheme__tableCellPrimarySelected {
    border: 2px solid rgb(60, 132, 244);
    display: block;
    height: calc(100% - 2px);
    position: absolute;
    width: calc(100% - 2px);
    left: -1px;
    top: -1px;
    z-index: 2;
  }
  .WysiwygEditorTheme__tableCellEditing {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
  }
  .WysiwygEditorTheme__tableAddColumns {
    position: absolute;
    background-color: #eee;
    height: 100%;
    animation: table-controls 0.2s ease;
    border: 0;
    cursor: pointer;
  }
  .WysiwygEditorTheme__tableAddColumns:after {
    /* background-image: url(../images/icons/plus.svg); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }
  .WysiwygEditorTheme__tableAddColumns:hover,
  .WysiwygEditorTheme__tableAddRows:hover {
    background-color: #c9dbf0;
  }
  .WysiwygEditorTheme__tableAddRows {
    position: absolute;
    width: calc(100% - 25px);
    background-color: #eee;
    animation: table-controls 0.2s ease;
    border: 0;
    cursor: pointer;
  }
  .WysiwygEditorTheme__tableAddRows:after {
    /* background-image: url(../images/icons/plus.svg); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }
  @keyframes table-controls {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .WysiwygEditorTheme__tableCellResizeRuler {
    display: block;
    position: absolute;
    width: 1px;
    background-color: rgb(60, 132, 244);
    height: 100%;
    top: 0;
  }
  .WysiwygEditorTheme__tableCellActionButtonContainer {
    display: block;
    right: 5px;
    top: 6px;
    position: absolute;
    z-index: 4;
    width: 20px;
    height: 20px;
  }
  .WysiwygEditorTheme__tableCellActionButton {
    background-color: #eee;
    display: block;
    border: 0;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    color: #222;
    cursor: pointer;
  }
  .WysiwygEditorTheme__tableCellActionButton:hover {
    background-color: #ddd;
  }
`;
